// src/state/Reducer.js

export function Reducer (state, { type, payload }) {
    //console.log('>>>-Reducer-state->', state)
    //console.log('>>>-Reducer-type->', type)
    //console.log('>>>-Reducer-payload->', payload)
    switch (type) {
        case 'USER_SIGN_UP_UNCONFIRMED': 
        return { 
                ...state, 
                userDetails: payload, 
                stateState: 'sign up unconfirmed',
                userIsKnown: true,
                userIsAuthenticated: false,
                userIsSubcribed: false
            }
        case 'USER_CREATE_USER_AWS': 
        return { 
                ...state, 
                userDetails: payload,
                stateState: 'sign up confirmed'
            }
        case 'USER_CREATE_PREFERENCES_AWS': 
        return { 
                ...state, 
                userPreferences: payload
            }
        case 'USER_CREATE_TARGETS_AWS': 
        return { 
                ...state, 
                userTargets: payload
            }

        case 'USER_SIGN_IN_SESSION': 
        return { 
                ...state, 
                userSession: payload, 
                stateState: 'signed in',
                userIsKnown: true,
                userIsAuthenticated: true
            }
        case 'USER_SIGN_IN_DETAILS': 
        return { 
                ...state, 
                userDetails: payload, 
                stateState: 'signed in',
                userIsKnown: true,
                userIsAuthenticated: true
            }
        case 'USER_SIGN_IN_WITH_SUBSCRIPTIONS': 
        return { 
                ...state, 
                stateState: 'valid subscription',
                userSubscriptions: payload,
                userIsSubcribed: true
            }
        case 'USER_SIGN_IN_WITHOUT_SUBSCRIPTIONS': 
        return { 
                ...state, 
                stateState: 'invalid subscription',
                userSubscriptions: payload,
                userIsSubcribed: false
            }            
        case 'USER_SIGN_IN_PREFERENCES': 
        return { 
                ...state, 
                userPreferences: payload
            }
        case 'USER_SIGN_IN_TARGETS': 
        return { 
                ...state, 
                userTargets: payload
            }

        case 'USER_EDIT_DETAILS': 
        return { 
                ...state, 
                userDetails: payload,
                stateState: 'edit details'
            }
        case 'USER_EDIT_PREFERENCES': 
        return { 
                ...state, 
                userPreferences: payload,
                stateState: 'edit preferences'
            }
        case 'USER_EDIT_TARGETS': 
        return { 
                ...state, 
                userTargets: payload,
                stateState: 'edit targets'
            }

        case 'USER_SIGN_OUT': 
        return {
                ...state, 
                userSession: payload, 
                userDetails: null,
                userPreferences: null,
                userTargets: null,
                stateState: 'signed out',
                userIsKnown: false,
                userIsAuthenticated: false,
                userIsSubcribed: false
            }

        case 'USER_FORGOT_PASSWORD': 
        return { 
                ...state, 
                userSession: payload,
                stateState: 'forgot password',
                userForgotPassword: true,
                userIsAuthenticated: false
            }

        case 'USER_FORGOT_PASSWORD_CONFIRM': 
        return { 
                ...state, 
                userSession: payload,
                stateState: 'forgot password confirmed',
                userForgotPassword: false,
                userIsAuthenticated: true
            }

        case 'USER_CHANGE_EMAIL': 
        return { 
                ...state, 
                userSession: payload,
                stateState: 'change email',
                userChangeEmail: true
            }

        case 'USER_CHANGE_EMAIL_CONFIRM': 
        return { 
                ...state, 
                userSession: payload,
                stateState: 'change email confirmed',
                userChangeEmail: false,
                userIsAuthenticated: true
            }

        case 'DRAWER_IS_OPEN': 
        return { 
                ...state, 
                stateState: 'drawer state',
                drawerIsOpen: payload
            }
        
        case 'EDIT_HOME_READING': 
        return { 
                ...state, 
                stateState: 'edit home reading',
                editHomeReading: payload
            }

        case 'LOAD_CART_ITEMS': 
        return { 
                ...state, 
                stateState: 'cart items',
                userCart: payload
            }
        
        case 'NEW_SUBSCRIPTIONS': 
        return { 
                ...state, 
                stateState: 'valid subscription',
                userSubscriptions: [ payload ],
                userIsSubcribed: true
            }

        default: 
        return state
    }
}