// src/nav/Routes.js

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'

import VitalsHomeReadings from '../pages/VitalsHomeReadings/VitalsHomeReadings'
import VitalsHomeAnalyze from '../pages/VitalsHomeReadings/VitalsHomeAnalyze'
import VitalsHomeCommunicate from '../pages/VitalsHomeReadings/VitalsHomeCommunicate'
// import CalendarMonth from '../pages/calendar/CalendarMonth'
import Timeline from '../pages/timeline/Timeline'
import ProviderDetails from '../pages/providers/ProviderDetails'

import TestPage from '../pages/TestPage'

import Dashboard from '../pages/dashboard/Dashboard'
import AccountManagement from '../pages/account/AccountManagement'
import Todos from '../pages/Todos'
import Notes from '../pages/Notes'
import ChatPage from '../pages/agent/ChatPage'
// import Photos from './pages/Photos'
// import Pricing from './pages/Pricing'
// import VitalsHomeReadings from './pages/VitalsHomeReadings'

import Home from '../pages/home/Home'
import Services from '../pages/services/Services'
// import Services from './pages/Services'
import ContactUs from '../pages/ContactUs'
import About from '../pages/about/About'
import Blog from '../pages/blogs/Blog'
import SignIn from '../auth/SignIn'
import SignUp from '../auth/SignUp'

import NotFound from '../nav/NotFound'


const Routes = () => {

    return (
      <Switch>
        <PrivateRoute exact path='/vitals-home' component={VitalsHomeReadings} />
        <PrivateRoute exact path='/analyze' component={VitalsHomeAnalyze} />
        <PrivateRoute exact path='/communicate' component={VitalsHomeCommunicate} />
        {/* <PrivateRoute exact path='/calendar-month' component={CalendarMonth} /> */}
        <PrivateRoute exact path='/timeline' component={Timeline} />
        <PrivateRoute exact path='/provider-details' component={ProviderDetails} />

        <PrivateRoute exact path='/test-page' component={TestPage} />

        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/todos' component={Todos} />
        <PrivateRoute exact path='/notes' component={Notes} />
        <PrivateRoute exact path='/ch-chat' component={ChatPage} />
        <PrivateRoute exact path='/account-management' component={AccountManagement} />

        <Route exact path='/home' component={Home} />
        <Route exact path='/' component={Home} />
        <Route exact path='/services' component={Services} />
        {/* <Route exact path='/services' component={Services} /> */}
        <Route exact path='/about' component={About} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/contact' component={ContactUs} />
        <Route exact path='/signin' component={SignIn} />
        <Route exact path='/signup' component={SignUp} />

        <Route path='/not-found' component={NotFound} />
        <Route
            render={function () {
                return <h1>Not Found</h1>
            }}
        />
        <Route render={function () {
            return  <Redirect to='/' />
        }} />
      </Switch>
    )
}

export default Routes