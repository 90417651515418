// src/pages/VitalsHomeReadings/EnterHeight.js

import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../state/Context'
import { API, graphqlOperation } from 'aws-amplify'
import { format as formatFNS, getHours, getMinutes } from 'date-fns'

import { vitalsChoiceLists } from '../../utilities/choiceLists'
import { validations } from "../../utilities/validations.js";

import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBSelect,
    MDBDatePicker,
    MDBTimePicker
} from "mdbreact";

import { createHeight, updateHeight } from '../../graphql/mutations'


const EnterHeight = ({ homeReadingSelected }) => {
    const { state, dispatch } = useContext( Context )
    //console.log(">>>-EnterHeight-state.userPreferences->",state.userPreferences)
    //console.log(">>>-EnterHeight-state.editHomeReading->",state.editHomeReading)

    const [ height, setHeight ] = useState('')
    const [ unit, setUnit ] = useState(state.userPreferences.unitHeight)

    const [ pickedTime, setPickedTime ] = useState(new Date())
    const [ pickedDate, setPickedDate ] = useState(new Date())

    const [ shoes, setShoes ] = useState(null)
    const [ performedBy, setPerformedBy ] = useState(null)

    const [ isEditing, setIsEditing ] = useState(false)
    const [ isSubmitting, setIsSubmitting ] = useState(false)

    useEffect(() => {
        if(state.editHomeReading.name === 'Height') {
            setIsEditing(true)
            setHeight(state.editHomeReading.reading.height)
            setUnit(state.editHomeReading.reading.unit)
            setPickedTime(state.editHomeReading.reading.dateTimeStamp)
            setPickedDate(state.editHomeReading.reading.dateTimeStamp)
            setShoes(state.editHomeReading.reading.shoes)
            setPerformedBy(state.editHomeReading.reading.performedBy)
        } else {
            setIsEditing(false)
            return
        }
    }, [state.editHomeReading])

    const handleAddRow = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        // console.log(">>>-EnterGlucose-handleAddRow-pickedDate->",pickedDate)
        // console.log(">>>-EnterGlucose-handleAddRow-pickedTime->",pickedTime)
        const input = {
            cognitoId: state.userDetails.id,
            chId: 'chId-test-1',
            ownerAWS: state.userDetails.ownerAWS,
            height: height,
            unit: unit,
            dateTimeStamp: formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + (
                pickedTime.length === 5 ? (
                    pickedTime
                ) : (
                    getHours(pickedTime) + ':' + getMinutes(pickedTime)
                )
            ),
            // dateTimeStamp: formatFNS( pickedDate, 'YYYY-MM-DD' ) + 'T' + pickedTime,
            shoes: shoes,
            performedBy: performedBy
        }
        //console.log(">>>-EnterHeight-handleAddRow-input->", input)
        await API.graphql(graphqlOperation(createHeight,  { input }))
        dispatch({ type: 'EDIT_HOME_READING', payload: {name: '', reading: {} } })

        setHeight('')
        setUnit(state.userPreferences.unitHeight)
        setPickedDate(new Date())
        setPickedTime(new Date())
        setShoes(null)
        setPerformedBy(null)

        setIsEditing(false)
        setIsSubmitting(false)
    }

    const handleCancel = () => {
        dispatch({ type: 'EDIT_HOME_READING', payload: {name: '', reading: {} } })

        setHeight('')
        setUnit(state.userPreferences.unitHeight)
        setPickedDate(new Date())
        setPickedTime(new Date())
        setShoes(null)
        setPerformedBy(null)

        setIsEditing(false)
        setIsSubmitting(false)
    }

    const handleEditRow = async (event) => {
        event.preventDefault()
        setIsEditing(true)
        setIsSubmitting(true)

        const input = {
            id: state.editHomeReading.reading.id,
            expectedVersion: state.editHomeReading.reading.version,
            height: height,
            unit: unit,
            dateTimeStamp: formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + (
                pickedTime.length === 5 ? (
                    pickedTime
                ) : (
                    getHours(pickedTime) + ':' + getMinutes(pickedTime)
                )
            ),
            // dateTimeStamp: formatFNS( pickedDate, 'YYYY-MM-DD' ) + 'T' + pickedTime,
            shoes: shoes,
            performedBy: performedBy
        }
        //console.log(">>>-EnterHeight-handleEditRow-input->",input)
        await API.graphql(graphqlOperation(updateHeight,  { input }))

        dispatch({ type: 'EDIT_HOME_READING', payload: {name: '', reading: {} } })

        setHeight('')
        setUnit(state.userPreferences.unitHeight)
        setPickedDate(new Date())
        setPickedTime(new Date())
        setShoes(null)
        setPerformedBy(null)

        setIsEditing(false)
        setIsSubmitting(false)
    }

    // console.log('>>>-EnterHeight-isEditing->',isEditing)
    // console.log('>>>-EnterHeight-height->',height)
    // console.log('>>>-EnterHeight-unit->',unit)
    // console.log('>>>-EnterHeight-pickedDate->',pickedDate)
    // console.log('>>>-EnterHeight-pickedTime->',pickedTime)
    // console.log('>>>-EnterHeight-shoes->',shoes)
    // console.log('>>>-EnterHeight-performedBy->',performedBy)
    return (
        <MDBContainer>
        <form onSubmit={isEditing ? handleEditRow : handleAddRow} >

            <MDBRow center style={{height: 30}}>
                <MDBCol>
                    <h4>Enter Data for {homeReadingSelected}</h4>
                </MDBCol>
            </MDBRow>   
            <MDBRow center style={{height: 80}}>
                <div className="w-30 p-0">
                    <MDBInput
                        label="Height"
                        type="text"
                        outline
                        required
                        value={height}
                        onChange={e => setHeight(e.target.value)}
                        className={height && height.match(validations.HEIGHTINS)  
                        ? "is-valid" : "is-invalid"}
                    >
                        <div className="valid-feedback">ok</div>
                        <div className="invalid-feedback">Between 0 & 120 ins.</div>
                    </MDBInput>
                </div>
                <div className="w-30 p-0">  
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Unit</label>
                    <MDBSelect
                        options={vitalsChoiceLists.unitsHeight.options}
                        outline
                        color="primary"
                        value={unit}
                        selected={unit}
                        getValue={e => setUnit(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
            </MDBRow>
            <MDBRow center style={{height: 30}}>
                <div className="w-25 p-0">
                    <MDBDatePicker 
                        cancelLabel='Cancel'
                        clearable={true}
                        clearLabel='Clear'
                        animateYearScrolling={true}
                        // autoOk={true}
                        disableFuture={true}
                        disableOpenOnEnter={true}
                        format='YYYY-MM-DD'
                        showTodayButton 
                        valueDefault={ formatFNS( pickedDate, 'YYYY-MM-DD' ) }
                        getValue={setPickedDate} 
                        value={pickedDate}
                        onChange={e => setPickedDate(e.target.value)}
                    />
                </div>
                <div className="w-25 p-0">
                    <MDBTimePicker 
                        id="timePicker"
                        label="24 hr"
                        // cancelable 
                        // cancelText="Cancel"
                        closeOnCancel
                        clearable
                        doneText="OK" 
                        hoursFormat={24}
                        placeholder={'24 hr format'}
                        // value={pickedTime}
                        // hours={ pickedTime.length === 5 ? pickedTime.slice(0,2) : getHours( pickedTime ) }
                        // minutes={ pickedTime.length === 5 ? pickedTime.slice(3,5) : getMinutes( pickedTime ) }
                        hours={ getHours( pickedTime ) }
                        minutes={ getMinutes( pickedTime ) }
                        getValue={ value => setPickedTime( value ) } 
                    />
                </div>
            </MDBRow>

                <br />
                <hr />    
                <p style={{height: 5}}>Circumstances (optional / recommended)</p>
            <MDBRow center style={{height: 70}}>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Shoes</label>
                    <MDBSelect
                        options={vitalsChoiceLists.yesNo.options}
                        outline
                        color="primary"
                        selected={shoes}
                        value={shoes}
                        getValue={e => setShoes(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-26 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Performed By</label>
                    <MDBSelect
                        options={vitalsChoiceLists.performedBy.options}
                        outline
                        color="primary"
                        value={performedBy}
                        selected={performedBy}
                        getValue={e => setPerformedBy(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
            </MDBRow>
            <MDBRow center style={{height: 40}}>
                <MDBBtn
                    color='primary'
                    className="mb-2"
                    size='sm'
                    type="submit"
                    disabled={ !height || !unit || !pickedDate || !pickedTime }
                >
                {isEditing ? (
                    isSubmitting ? 'Saving Edit...' : 'Edit'
                ) : (
                    isSubmitting ? 'Adding...' : 'Add'
                )}
                </MDBBtn>
                <MDBBtn
                    color='secondary'
                    className="mb-2"
                    size='sm'
                    // type="submit"
                    onClick={handleCancel}
                >
                Cancel
                </MDBBtn>
            </MDBRow>
    </form>

    </MDBContainer>
    )
}

export default EnterHeight