// src/pages/about/Chapter4.js

import React from 'react'

import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBModalBody, 
    MDBModalHeader, 
    MDBModalFooter } from 'mdbreact';


const Chapter4 = ({isOpen,toggle}) => {

    return (
    <MDBContainer>
    
        <MDBModal
            className="modal-notify modal-info text-white" 
            isOpen={isOpen} 
            toggle={toggle}
        >
            <MDBModalHeader 
                toggle={toggle}
            >
                Chapter 4
            </MDBModalHeader>
            <MDBModalBody>
<p>
We're working on it...
</p>
            </MDBModalBody>
            <MDBModalFooter className="justify-content-center">
            <MDBBtn 
                color="primary" 
                onClick={toggle}
            >Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    </MDBContainer>
    );
}

export default Chapter4;