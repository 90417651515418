// src/pages/Accountmanagement.js

import React, { useState, useContext } from 'react'
import { Context } from '../../state/Context'

import { 
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardBody,
    MDBCollapse,
    MDBCollapseHeader
} from "mdbreact";

import AccountDetails from './AccountDetails'
import Subscription from './Subscription'
import PreferencesAndTargets from './PreferencesAndTargets'


const AccountManagement = () => {
    const { state } = useContext( Context )
    const [ collapseID, setCollapseID ] = useState('subcriptionsAndStatus')

    const toggleCollapse = collapseID => () => setCollapseID(
        prevState => (prevState.collapseID !== collapseID ? collapseID : "")
    );


    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>
            <MDBContainer>
            <div className="header pt-3" style={{background: "#baffff"}}>
                <MDBRow className="d-flex justify-content-start">
                        <h1 className="deep-grey-text mt-3 mb-4 pb-1 mx-5">
                        Account Management
                        </h1>
                </MDBRow>
            </div>
            <MDBContainer className="accordion md-accordion accordion-4">
                <MDBCard>
                    <MDBCollapseHeader
                    onClick={toggleCollapse("accountDetails")}
                    className="z-depth-1 teal lighten-4"
                    tagClassName="black-text text-center font-weight-bold text-uppercase"
                    tag="h4"
                    >
                    Your Account Details
                    </MDBCollapseHeader>
                    <MDBCollapse id="accountDetails" isOpen={collapseID}>
                    <MDBCardBody >

                        <AccountDetails />

                    </MDBCardBody>
                    </MDBCollapse>
                </MDBCard>

                <MDBCard>
                    <MDBCollapseHeader
                    onClick={toggleCollapse("preferencesAndTargets")}
                    className="z-depth-1 teal lighten-3"
                    tagClassName="black-text text-center font-weight-bold text-uppercase"
                    tag="h4"
                    >
                    Your Preferences and Targets
                    </MDBCollapseHeader>
                    <MDBCollapse id="preferencesAndTargets" isOpen={collapseID}>
                    <MDBCardBody >

                        <PreferencesAndTargets />

                    </MDBCardBody>
                    </MDBCollapse>
                </MDBCard>

                <MDBCard>
                    <MDBCollapseHeader
                    onClick={toggleCollapse("contactsAndCommunications")}
                    className="z-depth-1 teal lighten-2"
                    tagClassName="black-text text-center font-weight-bold text-uppercase"
                    tag="h4"
                    >
                    Your Contacts and Communications
                    </MDBCollapseHeader>
                    <MDBCollapse id="contactsAndCommunications" isOpen={collapseID}>
                    <MDBCardBody className="rgba-teal-strong white-text">
                        <p>
                        Coming soon…
                        </p>
                    </MDBCardBody>
                    </MDBCollapse>
                </MDBCard>

                <MDBCard>
                    <MDBCollapseHeader
                    onClick={toggleCollapse("subcriptionsAndStatus")}
                    className="z-depth-1 teal lighten-1"
                    tagClassName="black-text text-center font-weight-bold text-uppercase"
                    tag="h4"
                    >
                    Your Subscriptions and Status
                    </MDBCollapseHeader>
                    <MDBCollapse id="subcriptionsAndStatus" isOpen={collapseID}>
                    <MDBCardBody className="rgba-teal-strong white-text">

                        { state.userIsSubcribed ?
                            <Subscription /> :
                            <h2 className="h1-responsive font-weight-bold text-center my-5">
                            You don't have a valid subscription at this time.  
                            Please select a service plan and subscribe today.
                            </h2>
                        }
                        
                    </MDBCardBody>
                    </MDBCollapse>
                </MDBCard>

               
                </MDBContainer>
            </MDBContainer>
        </main>
    )
}

export default AccountManagement