// src/pages/Home.js

import React, { useState } from "react";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBView,
  MDBBtn,
} from "mdbreact";

import image_12 from "../../pictures/home/image-12.PNG";
import image_14 from "../../pictures/home/image-14.PNG";
import image_15 from "../../pictures/home/image-15.PNG";

import HealthData from "./HealthData";
import HealthEvents from "./HealthEvents";
import HealthSupplies from "./HealthSupplies";
import HomeData from "./HomeData";
import PrescriptionSupplies from "./PrescriptionSupplies";
import ProviderData from "./ProviderData";
import SideEffects from "./SideEffects";
import Symptoms from "./Symptoms";
import TreatmentSupplies from "./TreatmentSupplies";

const Home = () => {
  const [isOpenHealthData, setIsOpenHealthData] = useState(false);
  const [isOpenHomeData, setIsOpenHomeData] = useState(false);
  const [isOpenProviderData, setIsOpenProviderData] = useState(false);
  const [isOpenHealthSupplies, setIsOpenHealthSupplies] = useState(false);
  const [isOpenPrescriptionSupplies, setIsOpenPrescriptionSupplies] = useState(
    false
  );
  const [isOpenTreatmentSupplies, setIsOpenTreatmentSupplies] = useState(false);
  const [isOpenHealthEvents, setIsOpenHealthEvents] = useState(false);
  const [isOpenSideEffects, setIsOpenSideEffects] = useState(false);
  const [isOpenSymptoms, setIsOpenSymptoms] = useState(false);

  const newsStyle = {
    borderBottom: "1px solid #e0e0e0",
    marginBottom: "1.5rem",
  };

  return (
    <main style={{ margin: "0 1%", paddingTop: "2rem" }}>
      <MDBCard
        className="my-5 px-5 mx-auto teal lighten-5 text-center"
        style={{ fontWeight: 300, maxWidth: "98%" }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <h2 className="h1-responsive font-weight-bold my-5 text-center">
            Welcome to Christy Health.
          </h2>
          <h4 className="dark-grey-text mx-auto mb-2 w-75 text-center">
            More than an App. It's a way of live. We offer you a way to collect,
            manage, and analyze your health data.
          </h4>
          <h4 className="dark-grey-text mx-auto mb-5 w-75 text-center">
            Start today - no charge!
          </h4>
          {/* health data picture         */}
          <MDBRow>
            <MDBCol md="12" lg="4" className="mb-lg-0 mb-5">
              <MDBView hover rounded className="z-depth-1-half mb-4">
                <img className="img-fluid" src={image_15} alt={"image_15"} />
                <a href="#!">
                  <MDBMask overlay="white-slight" className="waves-light" />
                </a>
              </MDBView>
              {/* HealthData          */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenHealthData(!isOpenHealthData);
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h5 className="font-weight-bold" style={{ color: "blue" }}>
                    Manage all of your health data
                  </h5>
                </div>
                <HealthData
                  isOpen={isOpenHealthData}
                  toggle={() => {
                    setIsOpenHealthData(!isOpenHealthData);
                  }}
                />
              </MDBRow>
              {/* HomeData                 */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenHomeData(!isOpenHomeData);
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h6 style={{ color: "blue" }}>Home generated data...</h6>
                </div>
                <HomeData
                  isOpen={isOpenHomeData}
                  toggle={() => {
                    setIsOpenHomeData(!isOpenHomeData);
                  }}
                />
              </MDBRow>
              {/* ProviderData */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenProviderData(!isOpenProviderData);
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h6 style={{ color: "blue" }}>
                    Healthcare provider generated data...
                  </h6>
                </div>
                <ProviderData
                  isOpen={isOpenProviderData}
                  toggle={() => {
                    setIsOpenProviderData(!isOpenProviderData);
                  }}
                />
              </MDBRow>
            </MDBCol>

            {/* manage supplies picture */}
            <MDBCol md="12" lg="4" className="mb-lg-0 mb-5">
              <MDBView hover rounded className="z-depth-1-half mb-4">
                <img className="img-fluid" src={image_12} alt={"image_12"} />
                <a href="#!">
                  <MDBMask overlay="white-slight" className="waves-light" />
                </a>
              </MDBView>
              {/* HealthSupplies */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenHealthSupplies(!isOpenHealthSupplies);
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h5 className="font-weight-bold" style={{ color: "blue" }}>
                    Manage your medical supplies
                  </h5>
                </div>
                <HealthSupplies
                  isOpen={isOpenHealthSupplies}
                  toggle={() => {
                    setIsOpenHealthSupplies(!isOpenHealthSupplies);
                  }}
                />
              </MDBRow>
              {/* PrescriptionSupplies */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenPrescriptionSupplies(
                        !isOpenPrescriptionSupplies
                      );
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h6 style={{ color: "blue" }}>
                    Prescription supplies and reorder status.
                  </h6>
                </div>
                <PrescriptionSupplies
                  isOpen={isOpenPrescriptionSupplies}
                  toggle={() => {
                    setIsOpenPrescriptionSupplies(!isOpenPrescriptionSupplies);
                  }}
                />
              </MDBRow>
              {/* TreatmentSupplies */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenTreatmentSupplies(!isOpenTreatmentSupplies);
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h6 style={{ color: "blue" }}>
                    Treatment materials and days on hand.
                  </h6>
                </div>
                <TreatmentSupplies
                  isOpen={isOpenTreatmentSupplies}
                  toggle={() => {
                    setIsOpenTreatmentSupplies(!isOpenTreatmentSupplies);
                  }}
                />
              </MDBRow>
            </MDBCol>
            {/* health events picture */}
            <MDBCol md="12" lg="4" className="mb-lg-0 mb-5">
              <MDBView hover rounded className="z-depth-1-half mb-4">
                <img className="img-fluid" src={image_14} alt={"image_14"} />
                <a href="#!">
                  <MDBMask overlay="white-slight" className="waves-light" />
                </a>
              </MDBView>
              {/* HealthEvents */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenHealthEvents(!isOpenHealthEvents);
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h5 className="font-weight-bold" style={{ color: "blue" }}>
                    Manage your health events
                  </h5>
                </div>
                <HealthEvents
                  isOpen={isOpenHealthEvents}
                  toggle={() => {
                    setIsOpenHealthEvents(!isOpenHealthEvents);
                  }}
                />
              </MDBRow>
              {/* SideEffects */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenSideEffects(!isOpenSideEffects);
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h6 style={{ color: "blue" }}>
                    Note the timing of health events and side effects.
                  </h6>
                </div>
                <SideEffects
                  isOpen={isOpenSideEffects}
                  toggle={() => {
                    setIsOpenSideEffects(!isOpenSideEffects);
                  }}
                />
              </MDBRow>
              {/* Symptoms */}
              <MDBRow>
                <div
                  className="d-flex justify-content-between"
                  style={newsStyle}
                >
                  <MDBBtn
                    rounded
                    size="sm"
                    color="info"
                    onClick={() => {
                      setIsOpenSymptoms(!isOpenSymptoms);
                    }}
                  >
                    <MDBIcon icon="angle-double-right" size="2x" />
                  </MDBBtn>
                  <h6 style={{ color: "blue" }}>
                    Document the frequency of symptoms and indications.
                  </h6>
                </div>
                <Symptoms
                  isOpen={isOpenSymptoms}
                  toggle={() => {
                    setIsOpenSymptoms(!isOpenSymptoms);
                  }}
                />
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <h4 className="dark-grey-text mx-auto mb-3 w-75 text-center">
            Take control of what you learn and how you communicate your health
            information.
          </h4>
          <p className="dark-grey-text mx-auto mb-3 w-75 text-center">
            Our start and end point is the individual. People with serious
            health conditions, see multiple specialists, have records at various
            hospitals, labs, imaging centers, insurers, and on and on. Do any of
            these organizations consolidate your data? That’s what we do!
          </p>
          <p className="dark-grey-text mx-auto mb-3 w-75 text-center">
            All of your health data in your App. Build an accurate health
            history, make your home collected data immediately available to your
            health team, distinguish cause and effect relationships. If you
            think it’s your health, your data, and your responsibility, then
            this is your tool.
          </p>
        </MDBCardBody>
      </MDBCard>
    </main>
  );
};

export default Home;
