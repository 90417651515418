// src/pages/VitalsHomeReadings/EnterWeight.js

import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../state/Context'
import { API, graphqlOperation } from 'aws-amplify'
import { format as formatFNS, getHours, getMinutes } from 'date-fns'

import { vitalsChoiceLists } from '../../utilities/choiceLists'
import { validations } from "../../utilities/validations.js";

import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBSelect,
    MDBDatePicker,
    MDBTimePicker
} from "mdbreact";

import { createWeight, updateWeight } from '../../graphql/mutations'

//----------------------------------------------------------------
// 
// set currentWeight in global state
//      1- on sign-in get the most recent reading
//      2- on every use of EnterWeight (new/edit/delete) test for most recent, update if needed
// 
//----------------------------------------------------------------

const EnterWeight = ({ homeReadingSelected }) => {
    const { state, dispatch } = useContext( Context )
    //console.log(">>>-EnterWeight-state.userPreferences->",state.userPreferences)
    console.log(">>>-EnterWeight-state.editHomeReading->",state.editHomeReading)

    const [ weight, setWeight ] = useState('')
    const [ targetWeight, setTargetWeight ] = useState('')
    const [ unit, setUnit ] = useState('')

    const [ pickedTime, setPickedTime ] = useState(new Date())
    const [ pickedDate, setPickedDate ] = useState(new Date())

    const [ when, setWhen ] = useState(null)
    const [ recentEvent, setRecentEvent ] = useState(null)
    const [ clothed, setClothed ] = useState(null)
    const [ performedBy, setPerformedBy ] = useState(null)

    const [ isEditing, setIsEditing ] = useState(false)
    const [ isSubmitting, setIsSubmitting ] = useState(false)

    useEffect(() => {
        if(state.editHomeReading.name === 'Weight') {
            setIsEditing(true)
            setWeight(state.editHomeReading.reading.weight)
            setTargetWeight(state.editHomeReading.reading.targetWeight)
            setUnit(state.editHomeReading.reading.unit)
            setPickedTime(state.editHomeReading.reading.dateTimeStamp)
            setPickedDate(state.editHomeReading.reading.dateTimeStamp)
            setWhen(state.editHomeReading.reading.whenEvent)
            setRecentEvent(state.editHomeReading.reading.eventWhen)
            setClothed(state.editHomeReading.reading.clothed)
            setPerformedBy(state.editHomeReading.reading.performedBy)
        } else {
            setIsEditing(false)
            return
        }
    }, [state.editHomeReading])

    const handleAddRow = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        // console.log(">>>-EnterWeight-handleAddRow-pickedDate->",pickedDate)
        // console.log(">>>-EnterWeight-handleAddRow-pickedTime->",pickedTime)
        const input = {
            cognitoId: state.userDetails.id,
            chId: state.userDetails.id,
            ownerAWS: state.userDetails.ownerAWS,
            weight: weight,
            targetWeight: targetWeight,
            unit: unit,
            dateTimeStamp: formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + (
                pickedTime.length === 5 ? (
                    pickedTime
                ) : (
                    getHours(pickedTime) + ':' + getMinutes(pickedTime)
                )
            ),
            whenEvent: when,
            eventWhen: recentEvent,
            clothed: clothed,
            performedBy: performedBy
        }
        //console.log(">>>-EnterWeight-handleAddRow-input->", input)
        await API.graphql(graphqlOperation(createWeight,  { input }))

        dispatch({ type: 'EDIT_HOME_READING', payload: {name: '', reading: {} } })

        setWeight('')
        setTargetWeight('')
        setUnit(null)
        setPickedDate(new Date())
        setPickedTime(new Date())
        setWhen(null)
        setRecentEvent(null)
        setClothed(null)
        setPerformedBy(null)

        setIsEditing(false)
        setIsSubmitting(false)
    }

    const handleCancel = () => {
        dispatch({ type: 'EDIT_HOME_READING', payload: {name: '', reading: {} } })

        setWeight('')
        setTargetWeight('')
        setUnit(null)
        setPickedDate(new Date())
        setPickedTime(new Date())
        setWhen(null)
        setRecentEvent(null)
        setClothed(null)
        setPerformedBy(null)

        setIsEditing(false)
        setIsSubmitting(false)
    }

    const handleEditRow = async (event) => {
        event.preventDefault()
        setIsEditing(true)
        setIsSubmitting(true)

        console.log(">>>-EnterWeight-handleEditRow-state.editHomeReading.reading.id->",state.editHomeReading.reading.id)
        console.log(">>>-EnterWeight-handleEditRow-targetWeight->",targetWeight)

        try {
            const input = {
                id: state.editHomeReading.reading.id,
                expectedVersion: state.editHomeReading.reading.version,
                weight: weight,
                targetWeight: targetWeight,
                unit: unit,
                dateTimeStamp: formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + (
                    pickedTime.length === 5 ? (
                        pickedTime
                    ) : (
                        getHours(pickedTime) + ':' + getMinutes(pickedTime)
                    )
                ),
                whenEvent: when,
                eventWhen: recentEvent,
                clothed: clothed,
                performedBy: performedBy
            }
            console.log(">>>-EnterWeight-handleEditRow-input->",input)
            await API.graphql(graphqlOperation(updateWeight,  { input }))
    
            dispatch({ type: 'EDIT_HOME_READING', payload: {name: '', reading: {} } })
    
            setWeight('')
            setTargetWeight('')
            setUnit(null)
            setPickedDate(new Date())
            setPickedTime(new Date())
            setWhen(null)
            setRecentEvent(null)
            setClothed(null)
            setPerformedBy(null)

        } catch(err) {
            console.log(">>>-ERROR AT-EnterWeight-handleEditRow->",err)
        }
           
        setIsEditing(false)
        setIsSubmitting(false)
    }

    return (
        <MDBContainer>
        <form onSubmit={isEditing ? handleEditRow : handleAddRow} >

            <MDBRow center style={{height: 30}}>
                <MDBCol>
                    <h4>Enter Data for {homeReadingSelected}</h4>
                </MDBCol>
            </MDBRow>   
            <MDBRow center style={{height: 80}}>
                <div className="w-25 p-0">
                    <MDBInput
                        label="Weight"
                        type="text"
                        outline
                        required
                        value={weight}
                        onChange={e => setWeight(e.target.value)}
                        className={weight && weight.match(validations.WEIGHTLBS)  
                        ? "is-valid" : "is-invalid"}
                    >
                        <div className="valid-feedback">ok</div>
                        <div className="invalid-feedback">Between 0 & 500 lbs</div>
                    </MDBInput>
                </div>
                <div className="w-25 p-0">
                    <MDBInput
                        label="Target Weight"
                        type="text"
                        outline
                        required
                        value={targetWeight}
                        onChange={e => setTargetWeight(e.target.value)}
                        className={targetWeight && targetWeight.match(validations.WEIGHTLBS)  
                        ? "is-valid" : "is-invalid"}
                    >
                        <div className="valid-feedback">ok</div>
                        <div className="invalid-feedback">Between 0 & 500 lbs</div>
                    </MDBInput>
                </div>
                <div className="w-25 p-0">  
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Unit</label>
                    <MDBSelect
                        options={vitalsChoiceLists.unitsWeight.options}
                        outline
                        color="primary"
                        selected={unit}
                        getValue={e => setUnit(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
            </MDBRow>
            <MDBRow center style={{height: 30}}>
                <div className="w-25 p-0">
                    <MDBDatePicker 
                        cancelLabel='Cancel'
                        clearable={true}
                        clearLabel='Clear'
                        animateYearScrolling={true}
                        // autoOk={true}
                        disableFuture={true}
                        disableOpenOnEnter={true}
                        format='YYYY-MM-DD'
                        showTodayButton 
                        valueDefault={ formatFNS( pickedDate, 'YYYY-MM-DD' ) }
                        getValue={setPickedDate} 
                        value={pickedDate}
                        onChange={e => setPickedDate(e.target.value)}
                    />
                </div>
                <div className="w-25 p-0">
                    <MDBTimePicker 
                        id="timePicker"
                        label="24 hr"
                        // cancelable 
                        // cancelText="Cancel"
                        closeOnCancel
                        clearable
                        doneText="OK" 
                        hoursFormat={24}
                        placeholder={'24 hr format'}
                        // value={pickedTime}
                        // hours={ pickedTime.length === 5 ? pickedTime.slice(0,2) : getHours( pickedTime ) }
                        // minutes={ pickedTime.length === 5 ? pickedTime.slice(3,5) : getMinutes( pickedTime ) }
                        hours={ getHours( pickedTime ) }
                        minutes={ getMinutes( pickedTime ) }
                        getValue={ value => setPickedTime( value ) } 
                    />
                </div>
            </MDBRow>

                <br />
                <hr />    
                <p style={{height: 5}}>Circumstances (optional / recommended)</p>
            <MDBRow center style={{height: 70}}>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>When</label>
                    <MDBSelect
                        options={vitalsChoiceLists.when.options}
                        outline
                        color="primary"
                        selected={when}
                        getValue={e => setWhen(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Event</label>
                    <MDBSelect
                        options={vitalsChoiceLists.recentEvent.options}
                        outline
                        color="primary"
                        selected={recentEvent}
                        getValue={e => setRecentEvent(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Clothed</label>
                    <MDBSelect
                        options={vitalsChoiceLists.yesNo.options}
                        outline
                        color="primary"
                        selected={clothed}
                        getValue={e => setClothed(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Performed By</label>
                    <MDBSelect
                        options={vitalsChoiceLists.performedBy.options}
                        outline
                        color="primary"
                        selected={performedBy}
                        getValue={e => setPerformedBy(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
            </MDBRow>
            <MDBRow center style={{height: 40}}>
                <MDBBtn
                    color='primary'
                    className="mb-2"
                    size='sm'
                    type="submit"
                    disabled={ !weight || !targetWeight || !unit || !pickedDate || !pickedTime }
                >
                {isEditing ? (
                    isSubmitting ? 'Saving Edit...' : 'Edit'
                ) : (
                    isSubmitting ? 'Adding...' : 'Add'
                )}
                </MDBBtn>
                <MDBBtn
                    color='secondary'
                    className="mb-2"
                    size='sm'
                    // type="submit"
                    onClick={handleCancel}
                >
                Cancel
                </MDBBtn>
            </MDBRow>
    </form>

    </MDBContainer>
    )
}

export default EnterWeight