// src/pages/Notes.js

import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../state/Context'
import { API, graphqlOperation } from 'aws-amplify'
import { format as formatFNS, getHours, getMinutes } from 'date-fns'

import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBDataTable,
    MDBCard,
    MDBCardBody,
    MDBDatePicker,
    MDBTimePicker,
    MDBIcon
} from "mdbreact";

import { listNotes } from '../graphql/queries'
import { createNote, deleteNote, updateNote } from '../graphql/mutations'
import { onCreateNote, onUpdateNote, onDeleteNote } from '../graphql/subscriptions'
import { notesManager } from '../utilities/datatableLists'


function Notes () {
    const { state } = useContext( Context )
    // console.log('>>>-Notes-state->',state)
    // const [ user, setUser ] = useState(state.session)

    // const [ asyncId, setAsyncId ] = useState('')
    const [ newNoteValue, setNewNoteValue ] = useState('')
    const [ newSubject, setNewSubject ] = useState('')
    const [ newCategory, setNewCategory ] = useState('')
    const [ addNote, setAddNote ] = useState({})
    const [ noteToEdit, setNoteToEdit ] = useState({})
    const [ fullNotes, setFullNotes ] = useState([])

    const [ pickedTime, setPickedTime ] = useState(new Date())
    const [ pickedDate, setPickedDate ] = useState(new Date())
    const [ pickedDateTime, setPickedDateTime ] = useState(new Date())

    const [ dataTableColumns, setDataTableColumns ] = useState([])
    // const [ dataTableRows, setDataTableRows ] = useState([])

    const [ isDeleting, setIsDeleting ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false)
    const [ isSubmitting, setIsSubmitting ] = useState(false)

// useEffects
    useEffect( () => { getNotes() }, [addNote])
    useEffect( () => { setDataTableColumns(makeColHeaders(notesManager)) }, [addNote])
    //useEffect( () => { makeRowData() }, [addNote])
    useEffect( () => {
        // console.log('>>>-START-useEffect->')
        // getNotes()
        // console.log('>>>-Notes-useEffect-fullNotes->',fullNotes)
        const input = addNote
        // console.log('>>>-Notes-useEffect-input->',input)
        const createNoteListener = API.graphql(graphqlOperation(onCreateNote, { input })).subscribe({
            next: noteData => {
                setAddNote(noteData.value.data.onCreateNote) 
                // console.log('>>>-START-useEffect-createNotelistener-BEFORE-setFullNotes-fullNotes>',fullNotes)
                setFullNotes(prevNotes => {
                    // console.log('>>>-START-useEffect-createNotelistener-IN-setFullNotes->')
                    const oldNotes = prevNotes.filter(note => note.id !== addNote.id)
                    const updatedNotes = [...oldNotes, addNote]
                    // console.log(">>>-Notes-useEffect-createNotelistener-updatedNotes->",updatedNotes)
                    return updatedNotes
                })
            }
        })
        const deleteNoteListener = API.graphql(graphqlOperation(onDeleteNote)).subscribe({
            next: noteData => {
                const deletedNote = noteData.value.data.onDeleteNote
                // console.log(">>>-App-componentDidMount-deleteNotelistener-deletedNote->",deletedNote)
                // console.log('>>>-START-useEffect-deleteNotelistener-BEFORE-setFullNotes-fullNotes>',fullNotes)
                setFullNotes(prevNotes => {
                    // console.log('>>>-START-useEffect-deleteNotelistener-IN-setFullNotes->')
                    const updatedNotes = prevNotes.filter(note => note.id !== deletedNote.id)
                    makeRowData(updatedNotes)
                    // console.log(">>>-Notes-useEffect-deleteNotelistener-updatedNotes->",updatedNotes)
                    return updatedNotes
                })
            }
        })
        const updateNoteListener = API.graphql(graphqlOperation(onUpdateNote)).subscribe({
            next: noteData => {
                // console.log(">>>-Notes-useEffect-updateNotelistener-noteData->",noteData)
                const updatedNote = noteData.value.data.onUpdateNote
                // console.log('>>>-START-useEffect-updateNotelistener-BEFORE-setFullNotes-fullNotes>',fullNotes)
                setFullNotes(prevNotes => {
                    // console.log('>>>-START-useEffect-updateNotelistener-IN-setFullNotes->')
                    const index = prevNotes.findIndex(note => note.id === updatedNote.id)
                    const updatedNotes = [
                        ...prevNotes.slice(0, index),
                        updatedNote,
                        ...prevNotes.slice(index + 1)
                    ]
                    // console.log(">>>-Notes-useEffect-updateNotelistener-updatedNotes->",updatedNotes)                    
                    return updatedNotes
                })
            }
        })
   
        // console.log('>>>-END-useEffect->')
        return () => { 
            createNoteListener.unsubscribe() 
            deleteNoteListener.unsubscribe()
            updateNoteListener.unsubscribe()
        }
    }, [addNote])
// graphql getNotes
    const getNotes = async () => {
        try {
            const result = await API.graphql(graphqlOperation( listNotes, {limit:100000} ))
            // console.log(">>>-Notes-getNotes-result.data.listNotes.items->", result.data.listNotes.items)
            setFullNotes(result.data.listNotes.items)
        } catch (err) {
            console.log('error fetching listNotes...', err)
        }
    }

// make the datatable column headers 
    const makeColHeaders = (notesManager) => {
        const colHeaders = notesManager.cols.filter(function(col) {
            return (
                col.display === 'user' || 
                col.display === 'code'
            )
        })
        return colHeaders
    }

// make the datatable data rows
    function makeRowData(fullNotes) {
        // console.log(">>>-Notes-makeRowData-BEFORE if-fullNotes->",fullNotes)
        if (!fullNotes) return

        const rowData = fullNotes.map(row => ({
            actions: (
                <>
                <MDBBtn
                    tag="a"
                    color="red"
                    size='sm'
                    id={row.id}
                    disabled={isDeleting || isEditing}
                    onClick={e => handleDeleteRow(e.target.id)}
                    style={{ spacing: 1, margin: 1, padding: 1 }}
                >
                    Delete
                    {/* <MDBIcon size='lg' icon="trash-alt" /> */}
                </MDBBtn>
                <MDBBtn
                    color="blue"
                    size='sm'
                    id={row.id}
                    onClick={e => liftEditRow(e.target.id)}
                    disabled={isDeleting || isEditing}
                    style={{ spacing: 1, margin: 1, padding: 1 }}
                >
                    Edit
                    {/* <MDBIcon size="lg" icon="edit" /> */}
                </MDBBtn>
                </>
            ),           
            subject: row.subject,
            category: row.category,
            note: row.note,
            // owner: row.owner,
            dateTimeStamp: row.dateTimeStamp
            // createdAt: formatFNS(new Date(row.createdAt),'MM/DD/YYYY HH:MM'),
            // updatedAt: formatFNS(new Date(row.createdAt),'MM/DD/YYYY HH:MM'),
            // id: row.id,
            // cognitoId: row.cognitoId,
            // chId: row.chId,
        }))

        return rowData
    }

// handles...
    const handleAddRow = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        // console.log(">>>-Notes-handleAddNote-pickedTime.length===5->", pickedTime.length === 5 )
        // console.log(">>>-Notes-handleAddNote-pickedTime->", pickedTime )
        // console.log(">>>-Notes-handleAddNote-getHours(pickedTime)->", getHours(pickedTime) )
        // console.log(">>>-Notes-handleAddNote-getMinutes(pickedTime)->", getMinutes(pickedTime) )
        // console.log(">>>-Notes-handleAddNote-pickedDate->", pickedDate )
        // console.log(">>>-Notes-handleAddNote-pickedDate+pickedTime->", formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + pickedTime )
        
        const input = {
            cognitoId: state.userDetails.id,
            chId: 'chId-test-1',
            ownerAWS: state.userDetails.owner,
            subject: newSubject,
            category: newCategory,
            note: newNoteValue,
            status: 'stub!',
            dateTimeStamp: formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + (
                pickedTime.length === 5 ? (
                    pickedTime
                ) : (
                    getHours(pickedTime) + ':' + getMinutes(pickedTime)
                )
            )
            // dateTimeStamp: formatFNS( pickedDate, 'YYYY-MM-DD' ) + 'T' + pickedTime
        }
        // console.log(">>>-Notes-handleAddNote-input->",input)
        await API.graphql(graphqlOperation(createNote,  { input }))

        setPickedDate(new Date())
        setPickedTime(new Date())
        setPickedDateTime(new Date())
        setNewSubject('')
        setNewCategory('')
        setNewNoteValue('')
        setIsSubmitting(false)
    }

    const liftEditRow = (id) => {
        setIsDeleting(true);
        setIsEditing(true);
        // console.log(">>>-Notes-liftEditRow-id->", id);
        // setAsyncId(id)
        // console.log(">>>-Notes-liftEditRow-fullNotes->", fullNotes);
        const noteToEdit = fullNotes.find(note => note.id === id)
        setNoteToEdit(fullNotes.find(note => note.id === id))
        // console.log(">>>-Notes-liftEditRow-noteToEdit->", noteToEdit)
        // console.log(">>>-Notes-liftEditRow-noteToEdit.pickedDate->",noteToEdit.dateStamp)
        // console.log(">>>-Notes-liftEditRow-noteToEdit.pickedTime->",noteToEdit.timeStamp)
        // console.log(">>>-Notes-liftEditRow-noteToEdit.pickedTime.length->",noteToEdit.timeStamp.length)
        // console.log(">>>-Notes-liftEditRow-noteToEdit.pickedTime.slice(2)->",noteToEdit.timeStamp.slice(0,2))
        // console.log(">>>-Notes-liftEditRow-noteToEdit.pickedTime.slice(3,5)->",noteToEdit.timeStamp.slice(3,5))
        setNewSubject(noteToEdit.subject)
        setNewCategory(noteToEdit.category)
        setNewNoteValue(noteToEdit.note)
        setPickedDate(noteToEdit.dateTimeStamp)
        setPickedTime(noteToEdit.dateTimeStamp)
      }

    const handleEditRow = async (event) => {
        event.preventDefault()
        // console.log(">>>-Notes-handleEditRow-noteToEdit.pickedDate->",pickedDate)
        const input = {
            id: noteToEdit.id,
            expectedVersion: noteToEdit.version,
            subject: newSubject,
            category: newCategory,
            note: newNoteValue,
            status: 'stubbed at edit',
            dateTimeStamp: formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + (
                pickedTime.length === 5 ? (
                    pickedTime
                ) : (
                    getHours(pickedTime) + ':' + getMinutes(pickedTime)
                )
            )
        }
        await API.graphql(graphqlOperation(updateNote,  { input }))
        setPickedDate(new Date())
        setPickedTime(new Date())
        setNewSubject('')
        setNewCategory('')
        setNewNoteValue('')
        setIsDeleting(false)
        setIsEditing(false)
        setIsSubmitting(false)
    }

    const handleDeleteRow = async (id) => {
        setIsEditing(true);
        // console.log(">>>-Notes-handleDeleteRow-id->", id);
        const noteToDelete = fullNotes.find(note => note.id === id)
        // console.log(">>>-Notes-handleDeleteRow-noteToDelete->", noteToDelete);   
        // console.log(">>>-Notes-handleDeleteRow-noteToDelete.id->",  noteToDelete.id);
        // console.log(">>>-Notes-handleDeleteRow-noteToDelete.version->", noteToDelete.version);    
        const input = {
            id: noteToDelete.id,
            expectedVersion: noteToDelete.version
        }
        // console.log(">>>-Notes-handleDeleteRow-input->", input); 
        await API.graphql(graphqlOperation(deleteNote,  { input }))
        setPickedDate(new Date())
        setPickedTime(new Date())
        setNewSubject('')
        setNewCategory('')
        setNewNoteValue('')
        setIsDeleting(false)
        setIsEditing(false)
        setIsSubmitting(false)
    };

    const handleCancel = () => {
        setPickedDate(new Date())
        setPickedTime(new Date())
        setNewSubject('')
        setNewCategory('')
        setNewNoteValue('')
        setIsDeleting(false)
        setIsEditing(false)
        setIsSubmitting(false)
    }
   
// return
    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>
            <MDBContainer>
            <MDBRow>
            <MDBCol md="8">

            <MDBCard>
                <div className="header pt-3" style={{background: "#baffff"}}>
                <MDBRow className="d-flex justify-content-start">
                    <h1 className="deep-grey-text mt-3 mb-1 pb-1 mx-5">
                    Your Notes
                    </h1>
                </MDBRow>
                    <h5 className="deep-grey-text mt-1 mb-4 pb-1 mx-4">
                    Make, edit or delete notes about your health events, side effects, or treatments.
                    </h5>
                </div>

                <MDBCardBody className="mx-4 mt-4">
                <form  onSubmit={!isEditing ? handleAddRow : handleEditRow} >
                <MDBRow>
                    <MDBCol >
                        <MDBInput
                            label="Subject"
                            type="text"
                            outline
                            icon="tag"
                            value={newSubject}
                            onChange={e => setNewSubject(e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol >
                        <MDBInput
                            label="Category"
                            type="text"
                            outline
                            icon="list-ul"
                            value={newCategory}
                            onChange={e => setNewCategory(e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="1" md="1">
                        <MDBIcon icon="calendar-alt" size="2x"/>
                    </MDBCol>
                    <MDBCol >
                        <MDBDatePicker 
                            cancelLabel='Cancel'
                            clearable={true}
                            clearLabel='Clear'
                            animateYearScrolling={true}
                            // autoOk={true}
                            disableFuture={true}
                            disableOpenOnEnter={true}
                            format='YYYY-MM-DD'
                            showTodayButton 
                            valueDefault={ formatFNS( new Date(), 'YYYY-MM-DD' ) }
                            getValue={setPickedDate} 
                            value={pickedDate}
                            onChange={e => setPickedDate(e.target.value)}
                        />
                    </MDBCol>
                   
                    <MDBCol size="1" md="1">
                        <MDBIcon icon="clock" size="2x"/>
                    </MDBCol>
                    <MDBCol >
                        <MDBTimePicker 
                            id="timePicker"
                            label="24 hr format"
                            // cancelable 
                            // cancelText="Cancel"
                            closeOnCancel
                            clearable
                            doneText="OK" 
                            hoursFormat={24}
                            placeholder={'24 hr format'}
                            // value={pickedTime}
                            // hours={ pickedTime.length === 5 ? pickedTime.slice(0,2) : getHours( pickedTime ) }
                            // minutes={ pickedTime.length === 5 ? pickedTime.slice(3,5) : getMinutes( pickedTime ) }
                            hours={ getHours( pickedDateTime ) }
                            minutes={ getMinutes( pickedDateTime ) }
                            getValue={ value => setPickedTime( value ) } 
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol >
                        <MDBInput
                            label="Type or dictate your note here…"
                            type="textarea"
                            outline
                            rows="3"
                            icon="pencil-alt"
                            value={newNoteValue}
                            onChange={e => setNewNoteValue(e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                
                <MDBRow >
                    <MDBCol >
                        <MDBBtn
                            color='primary'
                            className="mb-2"
                            type="submit"
                            disabled={ !newNoteValue || !newSubject || !newNoteValue }
                        >
                        {isEditing ? (
                            isSubmitting ? 'Saving Edit...' : 'Edit Note'
                        ) : (
                            isSubmitting ? 'Adding Note...' : 'Add Note'
                        )}
                        </MDBBtn>
                    </MDBCol>
                    <MDBCol >
                        <MDBBtn
                            color='secondary'
                            className="mb-2"
                            // type="submit"
                            onClick={handleCancel}
                        >
                        Cancel
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
         
                </form>
                </MDBCardBody>
            </MDBCard>
            </MDBCol>
            </MDBRow>
{/* note datatable */}
                <MDBCard narrow>
                    <MDBCardBody cascade>
                        <MDBDataTable
                            striped
                            bordered
                            small
                            responsive
                            // scrollY
                            scrollX
                            data={{
                                columns: dataTableColumns,
                                rows: makeRowData(fullNotes)
                            }}
                        />
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        </main>
    )

    
}

export default Notes
