// src/components/PayYouTube.js
// components
// https://youtu.be/ZKd5ACq9z9o
// https://youtu.be/VrG_cD7Miv0

import React from 'react'

export const PlayYouTube = ({ youtubeId }) => {
    return (
        <div
          className="video"
          style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 25,
            height: 0
          }}
        >
          <iframe
            title={youtubeId}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
            src={`https://www.youtube.com/embed/${youtubeId}`}
            frameBorder="0"
          />
        </div>
      );
}
