// src/pages/VitalsHomeReadings/StudyData.js

import React from 'react'
// import React, { useState, useContext, useEffect } from 'react'
// import { Context } from '../../state/Context'
// import { API, graphqlOperation } from 'aws-amplify'
// import { format as formatFNS, getHours, getMinutes } from 'date-fns'

// import { 
//     MDBContainer,
//     MDBRow,
//     MDBCol,
//     MDBBtn,
//     MDBInput,
//     MDBDataTable,
//     MDBCard,
//     MDBCardBody,
//     MDBDatePicker,
//     MDBTimePicker,
//     MDBIcon
// } from "mdbreact";


const StudyData = ({ homeReadingSelected }) => {

    return (
        <div>
        <h4>Study Data for {homeReadingSelected}</h4>
        <p>Most recent: x: (days, weeks, months, quarter, all)</p>
        <p>From: (startDate) To: (endDate)</p>
        <p>Show only: (multi-select any/all measures)</p>
        <p>For BPP: (MAP, Scatter, WLA)</p>
        </div>
    )
}

export default StudyData