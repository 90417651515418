// src/pages/VitalsHomeReadings/SummaryTables.js

import React from 'react'
// import React, { useState, useContext, useEffect } from 'react'
// import { Context } from '../../state/Context'
// import { API, graphqlOperation } from 'aws-amplify'
// import { format as formatFNS, getHours, getMinutes } from 'date-fns'

// import { 
//     MDBContainer,
//     MDBRow,
//     MDBCol,
//     MDBBtn,
//     MDBInput,
//     MDBDataTable,
//     MDBCard,
//     MDBCardBody,
//     MDBDatePicker,
//     MDBTimePicker,
//     MDBIcon
// } from "mdbreact";


const SummaryTables = ({ homeReadingSelected }) => {

    return (
        <h4>Summary Tables for {homeReadingSelected}</h4>
    )
}

export default SummaryTables