// src/App.js

import React, { useState, useReducer } from 'react';
// import { Auth } from 'aws-amplify'

import { ContextProvider, initialState } from './state/Context'
import { Reducer } from './state/Reducer'

import DoubleNav from "./nav/DoubleNav";
import Routes from "./nav/Routes";
import FooterPage from './pages/footer/FooterPage'

import './App.css';


function App() {  
    const [ state, dispatch ] = useReducer( Reducer, initialState )
    // console.log('>>>-App-state->', state)
    // console.log('>>>-App-dispatch->', dispatch)

    
    const [ collapseID ] = useState("")

    const overlay = (
        <div
            id="sidenav-overlay"
            style={{ backgroundColor: "transparent" }}
            // onClick={toggleCollapse("mainNavbarCollapse")}
        />
    );

    return (
        
        <ContextProvider value={{ state, dispatch }}>
            <div className="flyout">

                <DoubleNav />
                {collapseID && overlay}
                <Routes />

                <FooterPage />

            </div>
        </ContextProvider>

    );
}

export default App;

