// src/pages/services/PayButton.js

import React, { useContext } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Context } from '../../state/Context'
import StripeCheckout from 'react-stripe-checkout'
import { format as formatFNS } from 'date-fns'

import { createSubscriptions } from '../../graphql/mutations'

const stripeConfig = {
    currency: "USD",
    publishableAPIKey: "pk_test_zSYvufXGddHIsuW5E4v3r9v0"
}


const PayButton = ({ service, serviceName, serviceLevel, serviceTerm, paymentPeriod }) => {
    const { state, dispatch } = useContext( Context )
    //console.log('>>>-PayButton-state->', state)
    //console.log('>>>-PayButton-serviceLevel->',serviceLevel)

    const handleCharge = async (token) => {
        //console.log('>>>-PayButton-handleCharge-token->', token)
        const orderDateTime = new Date();
        //console.log('>>>-PayButton-handleCharge-orderDateTime->', orderDateTime)

        try {
// process NOT FREE subscription payment via Stripe
            const result = await API.post('orderlambda', '/charge', {
                body: {
                    token,
                    charge: {
                        currency: stripeConfig.currency,
                        amount: service.amount,
                        description: service.description
                    },
                    chOrderDetails: {
                        userFirstName: state.userDetails.firstName,
                        userEmail: state.userDetails.email,
                        shipped: false,
                        orderDateTime: orderDateTime
                    }
                }
            })
            // console.log('>>>-PayButton-handleCharge-result->', result)
            if (result.charge.status === "succeeded") {
                if (service.shipped) {
                    console.log('>>>-PayButton-handleCharge-gql(createShippingAddress)->')
                }

                // console.log('>>>-PayButton-handleCharge-save confirmed purchase to GQL->')
                // console.log('>>>-PayButton-handleCharge-result->',result)
                
                const input = {
                    cognitoId: state.userDetails.cognitoId,
                    chId: state.userDetails.chId,
                    usernameAWS: state.userDetails.usernameAWS,
                    ownerAWS: state.userDetails.ownerAWS,

                    serviceName: serviceName,
                    servicePlan: serviceLevel,
                    serviceStartDate: formatFNS(new Date(), 'YYYY-MM-DD'),
                    serviceTerm: serviceTerm,
                    paymentPeriod: paymentPeriod,
                    servicePrice: (parseInt(result.charge.amount) / 100).toString(),
                    stripeId: result.charge.id,
                    stripeCreated: result.charge.created,
                    stripeStatus: result.charge.status,
                    stripeMessageId: result.data.MessageId
                }
                // console.log('>>>-PayButton-handleCharge-input->',input)
                dispatch({ type: 'NEW_SUBSCRIPTIONS', payload: input})
                await API.graphql(graphqlOperation(createSubscriptions,  { input }))
            }

        } catch (err) {
            console.error(err);
        }
    }

    return (
        <StripeCheckout 
            token={handleCharge}
            email={state.userDetails.email}
            name={service.name}
            currency={stripeConfig.currency}
            amount={parseInt(service.amount)}
            stripeKey={stripeConfig.publishableAPIKey}
            locale="auto"
            allowRememberMe={false}
        />
    )
}

export default PayButton