// src/pages/VitalsHomeReadings/DatatableHeight.js

import React, { useState, useEffect, useContext } from "react";
import { Context } from '../../state/Context'
import { API, graphqlOperation } from 'aws-amplify'

import { subDays, addDays } from 'date-fns'

import { 
    MDBBtn,
    MDBDataTable } from "mdbreact";

import { listHeights } from '../../graphql/queries'
import { onCreateHeight, onDeleteHeight, onUpdateHeight } from '../../graphql/subscriptions'
import { deleteHeight } from "../../graphql/mutations";

import { heightManager } from '../../utilities/datatableLists'


function DatatableHeight ({ homeReadingSelected }) {
    const { dispatch } = useContext( Context )
    //console.log(">>>-DatatableHeight-state->",state)

    const [ fullDataset, setFullDatset ] = useState([])
    const [ addHomeReading, setAddHomeReading ] = useState({})
    const [ editHomeReading, setEditHomeReading ] = useState({})

    const [ dataTableColumns, setDataTableColumns ] = useState([])

    const [ isDeleting, setIsDeleting ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false)

// useEffects
    useEffect( () => { getHeight() }, [addHomeReading])
    useEffect( () => { setDataTableColumns(makeColHeaders(heightManager)) }, [addHomeReading])
    //useEffect( () => { makeRowData() }, [addHomeReading])
    useEffect(() => { 
        const input = addHomeReading
        const createReadingListener = API.graphql(graphqlOperation(onCreateHeight, { input })).subscribe({
            next: data => {
                setAddHomeReading(data.value.data.onCreateHeight) 
                // console.log('>>>-START-useEffect-createNotelistener-BEFORE-setFullNotes-fullNotes>',fullNotes)
                setFullDatset(prevData => {
                    // console.log('>>>-START-useEffect-createNotelistener-IN-setFullNotes->')
                    const oldData = prevData.filter(row => row.id !== addHomeReading.id)
                    const updatedData = [...oldData, addHomeReading]
                    // console.log(">>>-Notes-useEffect-createNotelistener-updatedNotes->",updatedNotes)
                    return updatedData
                })
            }
        })
        const deleteReadingListener = API.graphql(graphqlOperation(onDeleteHeight)).subscribe({
            next: data => {
                const deletedData = data.value.data.onDeleteHeight
                // console.log(">>>-App-componentDidMount-deleteNotelistener-deletedNote->",deletedNote)
                // console.log('>>>-START-useEffect-deleteNotelistener-BEFORE-setFullNotes-fullNotes>',fullNotes)
                setFullDatset(prevData => {
                    // console.log('>>>-START-useEffect-deleteNotelistener-IN-setFullNotes->')
                    const updatedData = prevData.filter(row => row.id !== deletedData.id)
                    makeRowData(updatedData)
                    // console.log(">>>-Notes-useEffect-deleteNotelistener-updatedNotes->",updatedNotes)
                    return updatedData
                })
            }
        })
        const updateReadingListener = API.graphql(graphqlOperation(onUpdateHeight)).subscribe({
            next: data => {
                // console.log(">>>-Notes-useEffect-updateNotelistener-noteData->",noteData)
                const updatedRow = data.value.data.onUpdateHeight
                // console.log('>>>-START-useEffect-updateNotelistener-BEFORE-setFullNotes-fullNotes>',fullNotes)
                setFullDatset(prevData => {
                    // console.log('>>>-START-useEffect-updateNotelistener-IN-setFullNotes->')
                    const index = prevData.findIndex(row => row.id === updatedRow.id)
                    const updatedData = [
                        ...prevData.slice(0, index),
                        updatedRow,
                        ...prevData.slice(index + 1)
                    ]
                    // console.log(">>>-Notes-useEffect-updateNotelistener-updatedNotes->",updatedNotes)                    
                    return updatedData
                })
            }
        })
        
        return () => {
            createReadingListener.unsubscribe()
            deleteReadingListener.unsubscribe() 
            updateReadingListener.unsubscribe()
            }
        }, [addHomeReading, editHomeReading])

    const getHeight = async () => {
        const startDate = subDays( new Date(), 181 * 4 )
        //console.log('>>>-DatatableBloodPressurePulse-getBloodPressurePulse-startDate->', startDate)
        const endDate = addDays( new Date(), 1 )

        try {
            const result = await API.graphql(graphqlOperation( 
                listHeights, {
                    limit:1000,
                    filter: { dateTimeStamp: { between: [startDate, endDate]} }
                    } 
                ))
            // console.log(">>>-Datatable-listBloodPressurePulses->", result.data.listBloodPressurePulses.items)
            setFullDatset(result.data.listHeights.items)
        } catch (err) {
            console.log('error fetching listHeights...', err)
        }
    }

// make the datatable column headers 
    const makeColHeaders = (heightManager) => {
        const colHeaders = heightManager.cols.filter(function(col) {
            return (
                col.display === 'user' || 
                col.display === 'code'
            )
        })
        return colHeaders
    }

// make the datatable data rows
    function makeRowData(fullDatset) {
        // console.log(">>>-Notes-makeRowData-BEFORE if-fullNotes->",fullNotes)
        if (!fullDatset) return

        const rowData = fullDatset.map(row => ({
            actions: (
                <>
                <MDBBtn
                    tag="a"
                    color="red"
                    size='sm'
                    id={row.id}
                    disabled={isDeleting || isEditing}
                    onClick={e => handleDeleteRow(e.target.id)}
                    style={{ spacing: 1, margin: 1, padding: 1 }}
                >
                    Delete
                    {/* <MDBIcon size='lg' icon="trash-alt" /> */}
                </MDBBtn>
                <MDBBtn
                    color="blue"
                    size='sm'
                    id={row.id}
                    onClick={e => liftEditRow(e.target.id)}
                    disabled={isDeleting || isEditing}
                    style={{ spacing: 1, margin: 1, padding: 1 }}
                >
                    Edit
                    {/* <MDBIcon size="lg" icon="edit" /> */}
                </MDBBtn>
                </>
            ),           
            dateTimeStamp: row.dateTimeStamp,
            height: row.height,
            unit: row.unit,
            shoes: row.shoes,
            performedBy: row.performedBy
        }))

        return rowData
    }


// handles...
    const handleDeleteRow = async (id) => {
        setIsEditing(true);
        //console.log(">>>-height-handleDeleteRow-id->", id);

        const heightToDelete = fullDataset.find(row => row.id === id)
        // console.log(">>>-Notes-handleDeleteRow-noteToDelete->", noteToDelete);   
        // console.log(">>>-Notes-handleDeleteRow-noteToDelete.id->",  noteToDelete.id);
        // console.log(">>>-Notes-handleDeleteRow-noteToDelete.version->", noteToDelete.version);    
        const input = {
            id: heightToDelete.id,
            expectedVersion: heightToDelete.version
        }
        // console.log(">>>-Notes-handleDeleteRow-input->", input); 
        await API.graphql(graphqlOperation(deleteHeight,  { input }))
        // setPickedDate(new Date())
        // setPickedTime(new Date())
        setIsEditing(false)
    }

    const liftEditRow = (id) => {
        setIsDeleting(true);
        setIsEditing(true);
        //console.log(">>>-Height-liftEditRow-id->", id);
        setEditHomeReading( () => {
            const reading = fullDataset.find(row => row.id === id)
            dispatch({ type: 'EDIT_HOME_READING', payload: {name: 'Height', reading: reading} })
        })
        // remove these
        setIsDeleting(false);
        setIsEditing(false);
    }

    return (
        <div>
        <MDBDataTable
            striped
            bordered
            small
            responsive
            exportToCSV
            //scrollY
            scrollX
            data={{
                columns: dataTableColumns,
                rows: makeRowData(fullDataset)
            }}
        />
        </div>
    )
}

export default DatatableHeight