// src/pages/FooterPage.js

import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const FooterPage = () => {
    return (
      <MDBFooter className="font-small pt-4 mt-5">
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow
            style={{backgroundColor: '#4bcbcc'}}
          >
            <MDBCol md="6">
              <h5 className="title">Use</h5>
              <ul>
                <li className="list-unstyled">
                  <a href="#!">Privacy Policy</a>
                </li>
                <li className="list-unstyled">
                  <a href="#!">Terms of Use</a>
                </li>
                <li className="list-unstyled">
                  <a href="#!">Legal</a>
                </li>
              </ul>
            </MDBCol>
            <MDBCol md="3">
              <h5 className="title">Services & Plans</h5>
              <ul>
                <li className="list-unstyled">
                  <a href="#!">Services</a>
                </li>
                <li className="list-unstyled">
                  <a href="#!">Plans</a>
                </li>
              </ul>
            </MDBCol>
            <MDBCol md="3">
              <h5 className="title">Why it matters...</h5>
              <ul>
                <li className="list-unstyled">
                  <a href="#!">About us</a>
                </li>
                <li className="list-unstyled">
                  <a href="#!">Blog</a>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow
              style={{backgroundColor: '#0026ca'}}
            >
            <MDBCol md="12">
              <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                  &copy; {new Date().getFullYear()} Copyright: <a href="https://www.christyhealth.org"> christyhealth.org </a>
                </MDBContainer>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBFooter>
    );
  }

export default FooterPage