// src/pages/home/HealthSupplies.js

import React from 'react';


import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBCol, 
    MDBCard, 
    MDBCardBody, 
    MDBModalBody,
    MDBRow} from 'mdbreact';


const HealthSupplies = ({ isOpen, toggle }) => {

    return (
        <MDBContainer>
            <MDBModal 
                size="medium" 
                centered
                isOpen={isOpen} 
                toggle={toggle}
            >
            <MDBModalBody className="d-flex">
                <MDBCol >
                    <h3 className="h2-responsive product-name">
                    <strong>Manage your medical supplies.</strong>
                    </h3>
                    <div className="my-4">
                    <MDBCard>
                        <MDBCardBody>
                        <p>
                        Enter your data with any device from anywhere at your convenience.  
                        Type it in, text it, speak it at the time of the reading or later, when you able.
                        </p>
                        </MDBCardBody>
                    </MDBCard>     
                    </div>
                    <MDBRow className="justify-content-center mb-3">
                        <MDBBtn 
                            color="primary" 
                            className="ml-4" 
                            onClick={toggle} 
                        >
                        Close
                        </MDBBtn>
                    </MDBRow>
                    </MDBCol>
                </MDBModalBody>
            </MDBModal>
            </MDBContainer>
    );
}

export default HealthSupplies;