// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:50008501-4bff-4b0a-bad5-dfd4069876b1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_V9Gx1WHNH",
    "aws_user_pools_web_client_id": "4t6eli7ol7flk8urhub53gnth2",
    "oauth": {},
    "aws_user_files_s3_bucket": "chorg31bb9e80a07f45ba948f43ab8c7c078d-master",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "orderlambda",
            "endpoint": "https://n3spxhvz0d.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://wxecmmwzyzhw3ezeuuxwzowofu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
