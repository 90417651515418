// src/pages/TestPage.js

import React from 'react'

import {
    MDBContainer,
    MDBRow,
    MDBCol
  } from "mdbreact";

const col1 = {
    padding: '1rem',
    backgroundColor: '#33b5e5',
    border: '2px solid #fff',
    color: '#fff',
    textAlign: 'left',
    fontWeight: '200',
    fontSize: '1.0rem'
    }
const col2 = {
    padding: '1rem',
    backgroundColor: 'rgb(37, 47, 190)',
    border: '2px solid #fff',
    color: '#fff',
    textAlign: 'right',
    fontWeight: '200',
    fontSize: '.75rem'
    }
const divStyle1 = {
    height: '100px',
    lineHeight: '50px', 
    background: 'rgb(37, 47, 190)',
    color: 'white',
    margin: '.1rem .1rem .1rem .1rem',
    textAlign: 'left',
    fontWeight: '200',
    fontSize: '1.5rem',
    flex: '1 0 auto'
    }
const divStyle2 = {
    height: '100px',
    lineHeight: '150px', 
    background: '#9B1B30',
    color: 'lightgray',
    margin: '.2rem .2rem .2rem .2rem',
    textAlign: 'right',
    fontWeight: '400',
    fontSize: '2.5rem',
    flex: '1 0 auto'
    }



const TestPage = () => {

    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>
        <h1>Test page...</h1>
            <MDBContainer fluid>
                <h3>Grid</h3>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol style={col1} size="automatic">.col-12 .col-sm-6 .col-lg-8</MDBCol>
                        <MDBCol style={col1} size="automatic">.col-6 .col-lg-4</MDBCol>

                        <MDBCol style={col2} size="automatic">.col-6 .col-sm-4</MDBCol>
                        <MDBCol style={col1} size="automatic">.col-6 .col-sm-4</MDBCol>
                        <MDBCol style={col2} size="automatic">.col-6 .col-sm-4</MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol style={col1} md="8">
                            .col-md-8
                            <MDBRow >
                                <MDBCol md="6">.col-md-6</MDBCol>
                                <MDBCol style={col2} md="6">.col-md-6</MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol style={col2} md="4">.col-md-4</MDBCol>
                    </MDBRow>
                </MDBContainer>
                <h3>Masonry</h3>
                <MDBContainer>
                    <MDBRow className="masonry-with-columns-2">
                        <div style={divStyle1} >
                        1
                        </div>
                        <div style={divStyle2}>
                        2
                        </div>
                        <div style={divStyle1}>
                        3
                        </div>
                        <div style={divStyle2}>
                        4
                        </div>
                        <div style={divStyle1}>
                        5
                        </div>
                        <div style={divStyle2}>
                        6
                        </div>
                        <div style={divStyle1}>
                        7
                        </div>
                        <div style={divStyle2}>
                        8
                        </div>
                        <div style={divStyle1}>
                        9
                        </div>
                        <div style={divStyle2}>
                        10
                        </div>
                        <div style={divStyle1}>
                        11
                        </div>
                        <div style={divStyle2}>
                        12
                        </div>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        </main>
    )
}

export default TestPage
