// src/pages/services/ServiceOfferings.js

import React, { useState } from "react";
//import ServicePlans from './ServicePlans'
import ServiceCatalogCapture from './ServiceCatalogCapture'
import ServiceCatalogManage from './ServiceCatalogManage'
import ServiceCatalogAnalyze from './ServiceCatalogAnalyze'
import ServiceCatalogCommunicate from './ServiceCatalogCommunicate'

import { MDBRow, MDBCol, MDBIcon, MDBBtn } from "mdbreact";


const ServiceOfferings = () => {
    const [ isCaptureOpen, setIsCaptureOpen ] = useState(false)
    const [ isManageOpen, setIsManageOpen ] = useState(false)
    const [ isAnalyzeOpen, setIsAnalyzeOpen ] = useState(false)
    const [ isCommunicateOpen, setIsCommunicateOpen ] = useState(false)

    return (
        <section className="my-2">
            <h2 className="h1-responsive font-weight-bold text-center my-2">
            Service Offerings
            </h2>
            <h5 className="lead black-text w-responsive text-center mx-auto mb-4">
            We offer APP’s for capturing, managing, analyzing, and communicating your healthcare data.  
            The APP’s are modular.  
            They work individually or combined – sort of like Legos. Select a couple or select them all, they connect and work together automatically.
            </h5>

            <MDBRow>
            
            <MDBCol md="3" className="md-0 mb-2">
                <MDBRow>
                <MDBCol lg="2" md="3" size="2">
                    <MDBIcon icon="table" size="2x" className="pink-text" />
                </MDBCol>
                <MDBCol lg="10" md="9" size="10">
                    <h4 className="font-weight-bold">Capture</h4>
                    <p className="darkgrey-text">
                    Capture your data manually on any device or automatically if your healthcare providers and equipment makers offer this service.
                    </p>
                    <MDBBtn 
                        onClick={() => {setIsCaptureOpen(!isCaptureOpen)}}
                        color="pink" size="sm"
                    >
                    Learn more
                    </MDBBtn>
                    <ServiceCatalogCapture 
                        offeringName={'capture'}
                        isOpen={isCaptureOpen} 
                        toggle={() => {setIsCaptureOpen(!isCaptureOpen)}}
                    />
                </MDBCol>
                </MDBRow>
            </MDBCol>

            <MDBCol md="3" className="md-0 mb-2">
                <MDBRow>
                <MDBCol lg="2" md="3" size="2">
                    <MDBIcon icon="atlas" size="2x" className="green-text" />
                </MDBCol>
                <MDBCol lg="10" md="9" size="10">
                    <h4 className="font-weight-bold">Manage</h4>
                    <p className="darkgrey-text">
                    Manage your data on any device.  
                    You have access to correct any data errors.  
                    We never delete your data so you can always review corrections.
                    </p>
                    <MDBBtn 
                        onClick={() => {setIsManageOpen(!isManageOpen)}}
                        color="green" size="sm"
                    >
                    Learn more
                    </MDBBtn>
                    <ServiceCatalogManage
                        offeringName={'manage'}
                        isOpen={isManageOpen} 
                        toggle={() => {setIsManageOpen(!isManageOpen)}}
                    />
                </MDBCol>
                </MDBRow>
            </MDBCol>

            <MDBCol md="3" className="md-0 mb-2">
                <MDBRow>
                <MDBCol lg="2" md="3" size="2">
                    <MDBIcon icon="chart-line" size="2x" className="purple-text" />
                </MDBCol>
                <MDBCol lg="10" md="9" size="10">
                    <h4 className="font-weight-bold">Analyze</h4>
                    <p className="darkgrey-text">
                    Analyze the time history of individual readings or study relationships between vitals, blood lab results, prescription dose levels, treatments, and health events.
                    </p>
                    <MDBBtn 
                        onClick={() => {setIsAnalyzeOpen(!isAnalyzeOpen)}}
                        color="purple" size="sm"
                    >
                    Learn more
                    </MDBBtn>
                    <ServiceCatalogAnalyze
                        offeringName={'analyze'}
                        isOpen={isAnalyzeOpen} 
                        toggle={() => {setIsAnalyzeOpen(!isAnalyzeOpen)}}
                    />
                </MDBCol>
                </MDBRow>
            </MDBCol>

            <MDBCol md="3" className="md-0 mb-2">
                <MDBRow>
                <MDBCol lg="2" md="3" size="2">
                    <MDBIcon icon="bullhorn" size="2x" className="blue-text" />
                </MDBCol>
                <MDBCol lg="10" md="9" size="10">
                    <h4 className="font-weight-bold">Communicate</h4>
                    <p className="darkgrey-text">
                    Send a picture or summary data table.  Send the data itself.  You can attach to email, send by text, or just show your results on any device.
                    </p>
                    <MDBBtn 
                        onClick={() => {setIsCommunicateOpen(!isCommunicateOpen)}}
                        color="primary" size="sm"
                    >
                    Learn more
                    </MDBBtn>
                    <ServiceCatalogCommunicate 
                        offeringName={'communicate'}
                        isOpen={isCommunicateOpen} 
                        toggle={() => {setIsCommunicateOpen(!isCommunicateOpen)}}
                    />
                </MDBCol>
                </MDBRow>
            </MDBCol>

            </MDBRow>

        </section>

    );
}

export default ServiceOfferings;