// src/pages/services/ServiceCatalogCapture.js

import React, { useState } from 'react';
//import { Context } from '../../state/Context'

import CaptureBBP1 from '../../pictures/services/capture/CaptureBBP1.PNG'
import CaptureBBP2 from '../../pictures/services/capture/CaptureBBP2.PNG'
import CaptureBBP3 from '../../pictures/services/capture/CaptureBBP3.PNG'

import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBCol, 
    MDBCarouselInner, 
    MDBCard, 
    MDBCardBody, 
    MDBCarousel, 
    MDBModalBody,
    MDBCollapseHeader, 
    MDBCarouselItem, 
    MDBRow, 
    MDBCollapse} from 'mdbreact';


const ServiceCatalogCapture = ({ offeringName, isOpen, toggle }) => {
    //const { state } = useContext( Context )
    //console.log(">>>-ServiceCatalog-state->",state)
    const [ collapseID, setCollapseID ] = useState('discription')

    const toggleCollapse = collapseID => () => setCollapseID(
        prevState => (prevState.collapseID !== collapseID ? collapseID : "")
    )

  return (
        <MDBContainer>
        
        <MDBModal 
            size="fluid" 
            isOpen={isOpen} 
            toggle={toggle}
        >
        <MDBModalBody className="d-flex">
            <MDBCol size="5" lg="5">
                <MDBCarousel 
                    activeItem={1} 
                    length={3} 
                    showControls={true} 
                    showIndicators={true} 
                    thumbnails 
                    className="z-depth-1"
                >
                    <MDBCarouselInner>
                    <MDBCarouselItem itemId="1">
                        <img className="d-block w-100" 
                            src={CaptureBBP1}
                            alt={"CaptureBBP1"} 
                        />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="2">
                        <img className="d-block w-100" 
                            src={CaptureBBP2}
                            alt={"CaptureBBP2"}
                        />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="3">
                        <img className="d-block w-100" 
                            src={CaptureBBP3}
                            alt={"CaptureBBP3"} 
                        />
                        </MDBCarouselItem>
                    </MDBCarouselInner>
                </MDBCarousel>
            </MDBCol>

            <MDBCol size="7" lg="7">
                <h2 className="h2-responsive product-name">
                <strong>APP: {offeringName} vitals and home readings</strong>
                </h2>
                <div className="my-4">

                <MDBCard>
                    <MDBCollapseHeader 
                        onClick={toggleCollapse("discription")}
                        className="z-depth-1 teal lighten-4"
                        tagClassName="black-text text-center font-weight-bold text-uppercase"
                        tag="h4"
                    >
                    Description
                    
                        </MDBCollapseHeader>
                        <MDBCollapse 
                            id="discription" 
                            isOpen={collapseID}
                        >
                            <MDBCardBody>
The vitals and home readings module lets you capture readings, in the units of your preference, with circumstances at the time of the reading.  The following can be captured:
<p>-	Blood pressure and pulse</p>
<p>-	Glucose</p>
<p>-	Height</p>
<p>-	Oxygenation</p>
<p>-	Respiration</p>
<p>-	Temperature</p>
<p>-	Weight</p>
                            </MDBCardBody>
                        </MDBCollapse>
                    </MDBCard>

                    <MDBCard>
                        <MDBCollapseHeader 
                            onClick={toggleCollapse("details")}
                            className="z-depth-1 teal lighten-4"
                            tagClassName="black-text text-center font-weight-bold text-uppercase"
                            tag="h4"
                        >
                        Details
                            
                        </MDBCollapseHeader>
                        <MDBCollapse 
                            id="details" 
                            isOpen={collapseID}
                        >
                            <MDBCardBody>
<p>
Enter your readings with the units of your preference: pounds or kilograms, fahrenheit or celsius, liters or quarts and so on.  
We do the conversions automatically so your healthcare provider’s data can be integrated seamlessly and they can see the readings with the units they prefer.
</p>
<p>
Readings are easily effected by external circumstances.  
How cool or warm is the room?  
Did you have coffee in the last three hours?  
Are you using your equipment or are you in the doctor’s office using their equipment?  
Age is a determinate in respiration and pulse rates.
Even your altitude (feet above sea level) impacts your oxygenation level.
</p> 
<p>
We offer you the optional (but recommended) ability to capture external circumstances that can, and frequently do, impact the reading measurement process.  This is used by the analysis modules to normalize for these variables.  
This helps you track down your cause and effect suspicions with greater confidence.
</p>
                            </MDBCardBody>
                        </MDBCollapse>
                    </MDBCard>
                    
                    </div>
                    
                    <MDBRow className="justify-content-center mb-3">
                        <MDBBtn color="secondary" className="ml-4" onClick={toggle} >Close</MDBBtn>
                    </MDBRow>

                </MDBCol>
        

            </MDBModalBody>
        </MDBModal>
        </MDBContainer>
    );
}

export default ServiceCatalogCapture;