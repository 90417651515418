// src/pages/ServicePlans.js

import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { format as formatFNS } from 'date-fns'

import { Context } from '../../state/Context'

// import Cart from './Cart'
import PayButton from './PayButton'

import { 
  MDBRow, 
  MDBCol, 
  MDBCard, 
  MDBCardBody, 
  MDBIcon, 
  MDBBtn,
  MDBModal, 
  MDBModalBody, 
  MDBModalHeader, 
  MDBModalFooter,
  MDBTable,
  MDBTableHead,
  TableBody,
  MDBFormInline,
  MDBInput } from "mdbreact";

import { createSubscriptions } from '../../graphql/mutations'


const ServicePlans = (props) => {
  const { state, dispatch } = useContext( Context )
  // console.log('>>>-ServicePlans-state-{userIsKnown,userIsAuthenticated}->',state.userIsAuthenticated)
  // console.log('>>>-ServicePlans-state-{userIsKnown,userIsAuthenticated}->',Boolean(state.userIsAuthenticated))
  const [ serviceLevel, setServiceLevel ] = useState('')
  const [ isShoppingCartOpen, setIsShoppingCartOpen ] = useState(false)
  const [ isFreeSubscriptionOpen, setIsFreeSubscriptionOpen ] = useState(false)
  const [ paymentPeriod, setPaymentPeriod ] = useState('month')

  const toggle = () => {
    setIsShoppingCartOpen( !isShoppingCartOpen && isFreeSubscriptionOpen === false )
    setIsFreeSubscriptionOpen( !isFreeSubscriptionOpen && isShoppingCartOpen === false )
  }

  const handleGoToSignIn = () => {
    setIsShoppingCartOpen(false)
    return props.history.push('/signin')
  }

  const handleGoToSignUp = () => {
      setIsShoppingCartOpen(false)
      return props.history.push('/signup')
  }

  const handleFreeSubscription = async () => {
    console.log('>>>-ServicePlans-handleFreeSubscription->')

    const input = {
      cognitoId: state.userDetails.cognitoId,
      chId: state.userDetails.chId,
      usernameAWS: state.userDetails.usernameAWS,
      ownerAWS: state.userDetails.ownerAWS,

      serviceName: "all modules",
      servicePlan: serviceLevel,
      serviceStartDate: formatFNS(new Date(), 'YYYY-MM-DD'),
      serviceTerm: '1 year',
      paymentPeriod: paymentPeriod,
      servicePrice: "0.00",
      stripeId: 'none',
      stripeCreated: 'none',
      stripeStatus: 'none',
      stripeMessageId: 'none'
  }
  // console.log('>>>-PayButton-handleCharge-input->',input)
  dispatch({ type: 'NEW_SUBSCRIPTIONS', payload: input})
  await API.graphql(graphqlOperation(createSubscriptions,  { input }))
  setIsFreeSubscriptionOpen(false)
  }
  
  function payAmount() {
    if (serviceLevel === 'Basic') {
        return 0.00
    } else if (serviceLevel === 'Connected') {
        return 10.00
    } else if (serviceLevel === 'Tracker') {
        return 20.00
    } else if (serviceLevel === 'Family') {
        return 50.00
    } else {
        return 0.00
    }
}

function payMultipule(pp) {
    if (pp === 'month') {
        return 1
    } else if (pp === 'quarter') {
        return 3
    } else if (pp === 'year') {
        return 12
    } else {
        return 1
    }
}

  return (
    <section className="text-center">
      <h2 className="h1-responsive font-weight-bold text-center my-2">
        Service Plans
      </h2>
      <h5 className="lead black-text w-responsive text-center mx-auto mb-4">
        We offer subscription service plans payable monthly, quarterly or annually.  
        Contact us about group plans larger than 5 people.
      </h5>
      <MDBRow>

{/* Basic */}

        <MDBCol lg="3" md="12" className="mb-lg-0 mb-2">
          <MDBCard pricing>
            <div className="price header white-text blue lighten-1 rounded-top">
              <h2 className="number">0</h2>
              <div className="version">
                <h5 className="mb-0">
                Basic
                </h5>
              </div>
            </div>
            <MDBCardBody className="striped mb-1">
            {
                Boolean(state.userIsAuthenticated) ? (
                  <MDBBtn 
                    color="blue" 
                    onClick={() => {
                      setIsFreeSubscriptionOpen(!isFreeSubscriptionOpen)
                      setServiceLevel('Basic')
                    }}
                  >Subscribe now</MDBBtn>
                ) : (
                  <Link to="/signin">
                    <MDBBtn color="blue">Sign in</MDBBtn>
                  </Link>
                )
              }
              <ul>
                <li>
                  <p className="mt-2">
                    <MDBIcon icon="check" className="green-text pr-2" />
                    1 individual
                  </p>
                </li>
                <li>
                  <p className="mt-2">
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Up to 1 GB Storage
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Manual data capture
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Sepsis  risk indicator
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="times" className="red-text pr-2" />
                    Automatic upload (participating providers)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="times" className="red-text pr-2" />
                    Connector (to caregivers, nurses and doctors)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="times" className="red-text pr-2" />
                    Tracker (vitals, labs, dosages, treatments, health events)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="times" className="red-text pr-2" />
                    Medical supply and prescription order and inventory tracker
                  </p>
                </li>
              </ul>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

{/* Connected */}

        <MDBCol lg="3" md="12" className="mb-lg-0 mb-4">
          <MDBCard pricing>
            <div className="price header white-text blue darken-1 rounded-top">
              <h2 className="number">10</h2>
              <div className="version">
                <h5 className="mb-0">
                    Connected
                </h5>
              </div>
            </div>
            <MDBCardBody className="striped mb-1">
            {
                Boolean(state.userIsAuthenticated) ? (
                  <MDBBtn 
                    color="blue"
                    onClick={() => {
                      setIsShoppingCartOpen(!isShoppingCartOpen)
                      setServiceLevel('Connected')
                    }}
                  >Subscribe now</MDBBtn>
                ) : (
                  <Link to="/signin">
                    <MDBBtn color="blue">Sign in</MDBBtn>
                  </Link>
                )
              }
              <ul>
                <li>
                  <p className="mt-2">
                    <MDBIcon icon="check" className="green-text pr-2" />
                    1 individual
                  </p>
                </li>
                <li>
                  <p className="mt-2">
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Up to 5 GB Storage
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Manual data capture
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Sepsis risk indicator
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Automatic upload (participating providers)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Connector (to caregivers, nurses and doctors)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="times" className="red-text pr-2" />
                    Tracker (vitals, labs, dosages, treatments, health events)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="times" className="red-text pr-2" />
                    Medical supply and prescription order and inventory tracker
                  </p>
                </li>
              </ul>              
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

{/* Tracker */}

        <MDBCol lg="3" md="12" className="mb-lg-0 mb-4">
          <MDBCard pricing>
            <div className="price header white-text blue darken-3 rounded-top">
              <h2 className="number">20</h2>
              <div className="version">
                <h5 className="mb-0">
                Tracker
                </h5>
              </div>
            </div>
            <MDBCardBody className="striped mb-1">
            {
                Boolean(state.userIsAuthenticated) ? (
                  <MDBBtn 
                    color="blue"
                    onClick={() => {
                      setIsShoppingCartOpen(!isShoppingCartOpen)
                      setServiceLevel('Tracker')
                    }}
                  >Subscribe now</MDBBtn>
                ) : (
                  <Link to="/signin">
                    <MDBBtn color="blue">Sign in</MDBBtn>
                  </Link>
                )
              }
              <ul>
                <li>
                  <p className="mt-2">
                    <MDBIcon icon="check" className="green-text pr-2" />
                    1 individual
                  </p>
                </li>
                <li>
                  <p className="mt-2">
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Up to 10 GB Storage
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Manual data capture
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Sepsis risk indicator
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Automatic upload (participating providers)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Connector (to caregivers, nurses and doctors)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Tracker (vitals, labs, dosages, treatments, health events)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Medical supply and prescription order and inventory tracker
                  </p>
                </li>

              </ul>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

{/* Family */}

        <MDBCol lg="3" md="12" className="mb-lg-0 mb-4">
          <MDBCard pricing>
            <div className="price header white-text blue darken-4 rounded-top">
              <h2 className="number">50</h2>
              <div className="version">
                <h5 className="mb-0">
                Family
                </h5>
              </div>
            </div>
            <MDBCardBody className="striped mb-1">
            {
                Boolean(state.userIsAuthenticated) ? (
                  <MDBBtn 
                    color="blue"
                    onClick={() => {
                      setIsShoppingCartOpen(!isShoppingCartOpen)
                      setServiceLevel('Family')
                    }}
                  >Subscribe now</MDBBtn>
                ) : (
                  <Link to="/signin">
                    <MDBBtn color="blue">Sign in</MDBBtn>
                  </Link>
                )
              }
              <ul>
                <li>
                  <p className="mt-2" style={{background: 'lightgreen', fontWeight: 'bold'}}>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Up to 5 individuals
                  </p>
                </li>
                <li>
                  <p className="mt-2">
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Up to 50 GB Storage
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Manual data capture
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Sepsis risk indicator
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Automatic upload (participating providers)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Connector (to caregivers, nurses and doctors)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Tracker (vitals, labs, dosages, treatments, health events)
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon icon="check" className="green-text pr-2" />
                    Medical supply and prescription order and inventory tracker
                  </p>
                </li>

              </ul>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

      </MDBRow>

{/* cart or free modal */}

      {
          isShoppingCartOpen ? (
            <MDBModal 
                isOpen={isShoppingCartOpen} 
                toggle={toggle}
            >
                <MDBModalHeader toggle={toggle}>
                Your cart
                </MDBModalHeader>
                <MDBModalBody>

                <MDBCardBody>
                  <MDBCol size="16">
                      <h5>Charge every:</h5>
                      <MDBFormInline> 
                          <MDBInput 
                              onClick={e => setPaymentPeriod(e.target.id)} 
                              checked={paymentPeriod === 'month' ? true : false} 
                              label="Month" 
                              type="radio"
                              id="month"
                          />
                          <MDBInput 
                              onClick={e => setPaymentPeriod(e.target.id)} 
                              checked={paymentPeriod === 'quarter' ? true : false} 
                              label="Quarter" 
                              type="radio"
                              id="quarter"
                          />
                          <MDBInput 
                              onClick={e => setPaymentPeriod(e.target.id)} 
                              checked={paymentPeriod === 'year' ? true : false} 
                              label="Year" 
                              type="radio"
                              id="year"
                          />
                      </MDBFormInline> 
                      <hr/>
                      <h5>Your subscription includes:</h5>
                      <MDBTable >
                          <MDBTableHead>
                          <tr>
                              <th>Service name</th>
                              <th>Service level</th>
                          </tr>
                          </MDBTableHead>
                          <TableBody>

                              <tr>
                                  <td>Data capture modules</td>
                                  <td>{serviceLevel}</td>
                              </tr>
                              <tr>
                                  <td>Data management modules</td>
                                  <td>{serviceLevel}</td>
                              </tr>
                              <tr>
                                  <td>Data analysis modules</td>
                                  <td>{serviceLevel}</td>
                              </tr>
                              <tr>
                                  <td>Communication modules</td>
                                  <td>{serviceLevel}</td>
                              </tr>
                              <tr>
                                  <td><h6><strong>
                                  Every {paymentPeriod} Christy Health will charge:
                                  </strong></h6></td>
                                  <td><h6><strong>${
                                      payAmount() * payMultipule(paymentPeriod)
                                  }</strong></h6></td>
                              </tr>
                          
                          </TableBody>
                      </MDBTable>
                  </MDBCol>
                  </MDBCardBody>

                </MDBModalBody>
                <MDBModalFooter className="justify-content-end">
                <MDBBtn 
                  color="primary" 
                  outline 
                  onClick={toggle}
                >
                Close
                </MDBBtn>
                {state.userIsAuthenticated ? 
                    ( serviceLevel !== 'Basic' ? <PayButton 
                        service={
                            {
                                name: `Service level ${serviceLevel} per ${paymentPeriod}`,
                                amount: (payAmount() * payMultipule(paymentPeriod) * 100).toString(),
                                description: `Christy Health service level ${serviceLevel} every ${paymentPeriod}`,
                                shipped: false
                            }
                        }                   
                        serviceName={"all modules"}
                        serviceLevel={serviceLevel}
                        serviceTerm={"1 year"}
                        paymentPeriod={paymentPeriod}
                    /> :
                    <MDBBtn 
                      color="primary"  
                      onClick={handleFreeSubscription}
                    >
                    Free Subscription
                    </MDBBtn>
                    )
                    : 
                    <>
                    <MDBBtn color="primary" onClick={handleGoToSignIn}>Sign In</MDBBtn>
                    <MDBBtn color="primary" onClick={handleGoToSignUp}>Sign Up</MDBBtn>
                    </>
                    }
                
                
                </MDBModalFooter>
            </MDBModal>
          ) : (
           
            <MDBModal 
                isOpen={isFreeSubscriptionOpen} 
                toggle={toggle}
            >
                <MDBModalHeader toggle={toggle}>
                Your cart
                </MDBModalHeader>
                <MDBModalBody>

                <MDBCardBody>
                  <MDBCol size="16">
                      
                      <h5>Your subscription includes:</h5>
                      <MDBTable >
                          <MDBTableHead>
                          <tr>
                              <th>Service name</th>
                              <th>Service level</th>
                          </tr>
                          </MDBTableHead>
                          <TableBody>

                              <tr>
                                  <td>Data capture modules</td>
                                  <td>{serviceLevel}</td>
                              </tr>
                              <tr>
                                  <td>Data management modules</td>
                                  <td>{serviceLevel}</td>
                              </tr>
                              <tr>
                                  <td>Data analysis modules</td>
                                  <td>{serviceLevel}</td>
                              </tr>
                              <tr>
                                  <td>Communication modules</td>
                                  <td>{serviceLevel}</td>
                              </tr>
                              <tr>
                                  <td><h6><strong>
                                  No charge:
                                  </strong></h6></td>
                                  <td><h6><strong>$0.00</strong></h6></td>
                              </tr>
                          
                          </TableBody>
                      </MDBTable>
                  </MDBCol>
                  </MDBCardBody>

                </MDBModalBody>
                <MDBModalFooter className="justify-content-end">
                <MDBBtn 
                  color="primary" 
                  outline 
                  onClick={toggle}
                >
                Close
                </MDBBtn>
                {state.userIsAuthenticated ? 
                    (
                      <MDBBtn 
                        color="primary"  
                        onClick={handleFreeSubscription}
                      >
                      Free Subscription
                      </MDBBtn>
                    )
                    : 
                    <>
                    <MDBBtn color="primary" onClick={handleGoToSignIn}>Sign In</MDBBtn>
                    <MDBBtn color="primary" onClick={handleGoToSignUp}>Sign Up</MDBBtn>
                    </>
                    }
                
                
                </MDBModalFooter>
            </MDBModal>
          )
      }
      
    </section>
  );
}

export default ServicePlans;