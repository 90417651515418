// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom';

import './index.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import App from './App';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports'
Amplify.configure(aws_exports);

export const history = createBrowserHistory()


ReactDOM.render( 
    <Router history={history}>
        <App />
    </Router>, 
    document.getElementById('root')
);
