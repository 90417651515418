// src/pages/home/HealthEvents.js

import React from 'react';


import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBCol, 
    MDBCard, 
    MDBCardBody, 
    MDBModalBody,
    MDBRow} from 'mdbreact';


const HealthEvents = ({ isOpen, toggle }) => {

    return (
        <MDBContainer>
            <MDBModal 
                size="medium" 
                centered
                isOpen={isOpen} 
                toggle={toggle}
            >
            <MDBModalBody className="d-flex">
                <MDBCol >
                    <h3 className="h2-responsive product-name">
                    <strong>Manage your health events</strong>
                    </h3>
                    <div className="my-4">
                    <MDBCard>
                        <MDBCardBody>
                        <p>
                        Some health events tend to be regular, others can be well managed.  
                        Many health events are neither regular nor well managed even when their duration is short lived.  
                        By recording these events you might be able gain insights about triggering events.
                        </p>
                        </MDBCardBody>
                    </MDBCard>     
                    </div>
                    <MDBRow className="justify-content-center mb-3">
                        <MDBBtn 
                            color="primary" 
                            className="ml-4" 
                            onClick={toggle} 
                        >
                        Close
                        </MDBBtn>
                    </MDBRow>
                    </MDBCol>
                </MDBModalBody>
            </MDBModal>
            </MDBContainer>
    );
}

export default HealthEvents;