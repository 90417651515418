// src/pages/about/Chapter1.js

import React from 'react'

import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBModalBody, 
    MDBModalHeader, 
    MDBModalFooter } from 'mdbreact';


const Chapter1 = ({isOpen,toggle}) => {
    //   console.log('>>>-Chapter_1-toggle->',toggle)

    return (
    <MDBContainer>
    
        <MDBModal
            className="modal-notify modal-info text-white" 
            isOpen={isOpen} 
            toggle={toggle}
        >
            <MDBModalHeader 
                toggle={toggle}
            >Chapter 1</MDBModalHeader>
            <MDBModalBody>
<p>
Christy was diagnosed with auto immune hemolytic anemia at 17.  
Two months later she had her spleen and gallbladder removed.  
For several years she had a relatively normal life completing college and taking jobs at various companies.  
At 25, one morning in the office she started having extreme back pains.  
Later that afternoon after going to the ER, she was told she had congestive heart failure.  
It was the auto immune condition attacking her heart in the form of lupus. 
</p>
<p>
They stabilized her, gave her aggressive chemotherapy for 3 months and heart medication.  
They told her she would not be able to exercise more than a slow walk ever again.  
At 28 Christy moved to New York City, joined a gym and started to exercising vigorously.
</p>
<p>
At 34, after routine lab work, her Rheumatologist noticed elevated liver function and sent her to a Gastroenterologist.  
Christy had stones in the bile ducts of her liver.  
After visiting with several doctors they all concluded she would never get a transplant because of the lupus.  
This was not acceptable to Christy!
</p>
<p>
Finally, after consulting with both the heart and liver teams at Cedars-Sinai Medical Center the liver team attempted to remove the stones. 
The surgery proved unsuccessful and Christy was placed on the heart and liver transplant waiting list.
</p>
<p>
By 36, Christy was admitted to ICU as her health was deteriorating precipitously.  
After 11 weeks in ICU, her heart and liver continuing to fail, her kidneys also began failing.  
She was literally nearing death, weighing only 80 pounds, when on October 24th 1998 Christy became the first heart and liver transplant in the western United States.  
Her kidneys regained most of their function quickly after her transplant.  
She actually walked out of the hospital, feeling better and stronger than she had for many years.
</p>
            </MDBModalBody>
            <MDBModalFooter className="justify-content-center">
            <MDBBtn 
                color="primary" 
                onClick={toggle}
            >Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    </MDBContainer>
    );
}

export default Chapter1;