// src/pages/Dashboard.js

import React from "react";

import { 
    MDBRow,
    MDBCol, 
    MDBCard, 
    MDBCardImage, 
    MDBCardBody, 
    MDBCardText, 
    MDBCardFooter,  
    MDBBtn,
    NavLink } from "mdbreact";

const CurrentReadingCard = ({image, readingName }) => {
    const bpW = 72 * ( 2 + 8/8 );
    const w = 8;
    const h = 11;

    return (
        <MDBCol lg="3" md="6" className="mb-lg-0">
            <MDBCard wide>
            <MDBCardImage
                className="img-fluid"
                cascade
                src={image}
                top
                alt={readingName}
                width={bpW}
                height={bpW * (h / w)}
            />
            <MDBCardBody cascade className="text-center">
                <a href="#!" className="text-muted">
                <h6>{readingName}</h6>
                </a>
                <MDBCardText>
                    <a href="#!">Why it's important.</a>
                </MDBCardText>
                <MDBCardFooter>
                <MDBRow center>
                    <MDBBtn
                        color='primary'
                        size='sm'
                    >
                    Share
                    </MDBBtn>
                    <NavLink to="/vitals-home">
                        <MDBBtn
                            color='primary'
                            size='sm'
                        >
                        Add/Edit
                        </MDBBtn>
                    </NavLink>
                </MDBRow>
                </MDBCardFooter>
            </MDBCardBody>
            </MDBCard>
        </MDBCol>
    )
}

export default CurrentReadingCard