// src/auth/SignUp.js

import React, { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Context } from "../state/Context";
import { Auth } from "aws-amplify";

import { validations } from "../utilities/validations.js";

import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModalFooter,
} from "mdbreact";

import {
  createUser,
  createTarget,
  createPreferences,
} from "../graphql/mutations";

const SignUp = props => {
  // console.log('>>>-SignUp-props->', props)
  const { state, dispatch } = useContext(Context);
  // console.log('>>>-SignUp-state->', state)

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [tandCs, setTandCs] = useState(false);
  const [signupConfirm, setSignupConfirm] = useState("");
  const [isSubmitting, setIsSubmitting] = useState("");
  const [formErrors, setFormErrors] = useState(null);

  // ============================================
  // handleSignUp
  const handleSignUp = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const user = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
        },
      });
      //console.log('>>>-SignUp-handleSignUp-user->',user)
      const userDetails = {
        userSub: user.userSub,
        chId: "ch-Stub",
        firstName: firstName,
        lastName: lastName,
        email: email,
        tandCs: tandCs,
        userStatus: "signing up",
      };
      dispatch({ type: "USER_SIGN_UP_UNCONFIRMED", payload: userDetails });

      setIsSubmitting(false);
    } catch (err) {
      console.log("errors signing in! :", err);
      alert(err.message);
      setFormErrors(err);
      console.log(">>>-SignUp-handleSignUp-formErrors->", formErrors);
      setFormErrors(null);
      setIsSubmitting(false);
    }
    // console.log('>>>-SignUp-handleSignUp-{firstName,lastName,email,tandCs}->', {firstName,lastName,email,tandCs})
  };

  // ============================================
  // handleSignUpConfirm
  const handleSignUpConfirm = async event => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      await Auth.confirmSignUp(email, signupConfirm);
      const user = await Auth.signIn(email, password);
      // create useDetails state
      // dispatch({ type: 'USER_SIGN_IN_SESSION', payload: user })
      // console.log('>>>-SignUp-handleSignUpConfirm-user->', user)

      // prep userDetails
      const inputUserDetails = {
        id: user.signInUserSession.idToken.payload.sub,
        cognitoId: user.signInUserSession.idToken.payload.sub,
        chId: user.signInUserSession.idToken.payload.sub,
        usernameAWS: user.signInUserSession.idToken.payload.email,
        enabled: true,
        userStatus: "sign up confirmed",
        ownerAWS: user.signInUserSession.idToken.payload.email,
        firstName: firstName,
        lastName: lastName,
        email: email,
        registered: true,
        tandCs: tandCs,
        // EDIT in AccountDetails
        nickname: true,
        phone: true,
        street: true,
        street2: true,
        city: true,
        state: true,
        code: true,
        country: true,
        birthdate: true,
        geoLat: true,
        geoLng: true,
      };
      // console.log(">>>-SignUp-handleSignUpConfirm-inputUserDetails->",inputUserDetails)

      // create useDetails state
      dispatch({ type: "USER_CREATE_USER_AWS", payload: inputUserDetails });
      // mutation createUser
      await API.graphql(
        graphqlOperation(createUser, { input: inputUserDetails })
      );
      // update userPreferences state
      const inputUserPreferences = {
        id: user.signInUserSession.idToken.payload.sub,
        cognitoId: user.signInUserSession.idToken.payload.sub,
        chId: user.signInUserSession.idToken.payload.sub,
        usernameAWS: user.signInUserSession.idToken.payload.email,
        // EDIT in AccountDetails
        unitWeight: true,
        unitHeight: true,
        unitFluid: true,
        unitAltitude: true,
        unitTemperature: true,
      };
      // console.log(">>>-SignUp-handleSignUpConfirm-inputUserPreferences->",inputUserPreferences)

      // create usePreferences state
      dispatch({
        type: "USER_CREATE_PREFERENCES_AWS",
        payload: inputUserPreferences,
      });
      // mutation createPreferences
      await API.graphql(
        graphqlOperation(createPreferences, { input: inputUserPreferences })
      );

      // create useTargets state
      const inputUserTargets = {
        id: user.signInUserSession.idToken.payload.sub,
        cognitoId: user.signInUserSession.idToken.payload.sub,
        chId: user.signInUserSession.idToken.payload.sub,
        usernameAWS: user.signInUserSession.idToken.payload.email,
        ownerAWS: user.signInUserSession.idToken.payload.email,
        // EDIT in AccountDetails
        targetWeight: true,
        targetSystolic: true,
        targetDiastolic: true,
        targetPulse: true,
        targetHydration: true,
        targetGlucose: true,
      };
      // console.log(">>>-SignUp-handleSignUpConfirm-inputUserTargets->",inputUserTargets)

      // create useTargets state
      dispatch({ type: "USER_CREATE_TARGETS_AWS", payload: inputUserTargets });
      // mutation createTargets
      await API.graphql(
        graphqlOperation(createTarget, { input: inputUserTargets })
      );

      // reset component state
      setFormErrors(null);
      setIsSubmitting(false);
      props.history.push("/dashboard");
    } catch (err) {
      console.log("errors signing in! :", err);
      alert(err.message);
      setFormErrors(err);
      console.log(">>>-SignUp-handleSignUpConfirm-formErrors->", formErrors);
      setFormErrors(null);
      setIsSubmitting(false);
    }
  };
  // ============================================
  // handleResesndSignUpConfirm
  // const handleResesndSignUpConfirm = async (event) => {
  // event.preventDefault()
  // console.log('>>>-useSubmit-handleResesndSignUpConfirm->')
  // Auth.resendSignUp()
  // }

  const SignUpForm = () => {
    return (
      <main style={{ margin: "0 1%", paddingTop: "2rem" }}>
        <MDBCard
          className="my-2 px-2 mx-auto teal lighten-5"
          style={{ fontWeight: 300, maxWidth: "400px" }}
        >
          <MDBCardBody style={{ paddingTop: 0 }}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form onSubmit={handleSignUp}>
                    <h2 className="h1-responsive font-weight-bold text-center my-5">
                      Sign up
                    </h2>

                    <div className="grey-text">
                      <MDBInput
                        label="First name"
                        name="firstName"
                        type="text"
                        required={false}
                        value={firstName}
                        onChange={event => setFirstName(event.target.value)}
                      ></MDBInput>
                      <MDBInput
                        label="Last name"
                        name="lastName"
                        type="text"
                        required={false}
                        value={lastName}
                        onChange={event => setLastName(event.target.value)}
                      ></MDBInput>
                      <MDBInput
                        label="Your email"
                        name="email"
                        type="email"
                        required
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        className={
                          email && email.match(validations.EMAIL)
                            ? "is-valid"
                            : "is-invalid"
                        }
                      >
                        <div className="valid-feedback">Valid email.</div>
                        <div className="invalid-feedback">
                          Enter a valid email.
                        </div>
                      </MDBInput>
                      <MDBInput
                        label="Confirm your email"
                        name="emailConfirm"
                        type="email"
                        required
                        value={emailConfirm}
                        onChange={event => setEmailConfirm(event.target.value)}
                        className={
                          emailConfirm && email === emailConfirm
                            ? "is-valid"
                            : "is-invalid"
                        }
                      >
                        <div className="valid-feedback">Email matchs.</div>
                        <div className="invalid-feedback">
                          Email does not match.
                        </div>
                      </MDBInput>
                      <MDBInput
                        label="Your password"
                        name="password"
                        type="password"
                        required
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        className={
                          password && password.match(validations.PASSWORD)
                            ? "is-valid"
                            : "is-invalid"
                        }
                      >
                        <div className="valid-feedback">
                          Enter a valid password.
                        </div>
                        <div className="invalid-feedback">
                          Enter a valid password. Minimum 8 characters,
                          <p>
                            one capital letter, one number, and one of
                            !,@,#,$,%,^,&,*,(,).
                          </p>
                        </div>
                      </MDBInput>
                      <MDBInput
                        label="Confirm your password"
                        name="passwordConfirm"
                        type="password"
                        required
                        value={passwordConfirm}
                        onChange={event =>
                          setPasswordConfirm(event.target.value)
                        }
                        className={
                          password && password === passwordConfirm
                            ? "is-valid"
                            : "is-invalid"
                        }
                      >
                        <div className="valid-feedback">Password matches.</div>
                        <div className="invalid-feedback">
                          Password does not match.
                        </div>
                      </MDBInput>
                    </div>

                    <div className="form-check my-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck12"
                        value={tandCs}
                        onChange={e =>
                          setTandCs(e.target.value === true ? false : true)
                        }
                      />
                      <label htmlFor="defaultCheck12" className="grey-text">
                        Accept the
                        <a href="#!" className="blue-text">
                          Terms and Conditions
                        </a>
                      </label>
                    </div>

                    <div className="text-center">
                      <MDBBtn
                        color="primary"
                        type="submit"
                        disabled={
                          !email ||
                          !emailConfirm ||
                          !password ||
                          !passwordConfirm ||
                          (email !== emailConfirm ||
                            password !== passwordConfirm)
                        }
                      >
                        Sign Up
                      </MDBBtn>
                    </div>
                    <MDBModalFooter>
                      <p className="font-weight-light">
                        Have an account?
                        <a href="/signin" className="blue-text ml-1">
                          Sign in!
                        </a>
                      </p>
                    </MDBModalFooter>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCardBody>
        </MDBCard>
      </main>
    );
  };

  const SignUpConfirmForm = () => {
    return (
      <main style={{ margin: "0 2%", paddingTop: "5rem" }}>
        <MDBCard
          className="my-5 px-5 mx-auto teal lighten-5"
          style={{ fontWeight: 300, maxWidth: "50%" }}
        >
          <MDBCardBody style={{ paddingTop: 0 }}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form onSubmit={handleSignUpConfirm}>
                    <h2 className="h1-responsive font-weight-bold text-center my-5">
                      Confirm Sign up
                    </h2>
                    <div className="grey-text">
                      <MDBInput
                        label="Sign Up Confirmation Code"
                        name="signupConfirm"
                        icon="envelope"
                        type="float"
                        outline
                        required
                        value={signupConfirm}
                        onChange={event => setSignupConfirm(event.target.value)}
                      ></MDBInput>
                    </div>
                    <div className="text-center">
                      <MDBBtn
                        type="submit"
                        color="primary"
                        disabled={signupConfirm.length !== 6}
                      >
                        {isSubmitting ? (
                          <MDBIcon icon="spinner" pulse size="1x" fixed />
                        ) : (
                          "Confirm"
                        )}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCardBody>
        </MDBCard>
      </main>
    );
  };

  return (
    <div className="ForgotPassword">
      {state.userIsKnown ? SignUpConfirmForm() : SignUpForm()}
    </div>
  );
};

export default SignUp;

// (state.userIsknown && !state.userIsAuthenticated) ? SignUpConfirmForm() : SignUpForm()
