// src/nav/DoubleNav.js

import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify';

import { Context } from '../state/Context'

import {
  Navbar,
  NavbarNav,
  NavItem,
  NavLink,
  Fa,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdownMenu,
  SideNavCat,
  SideNavNav,
  SideNav,
  MDBIcon,
  MDBBadge,
  MDBNavbarBrand
} from 'mdbreact'


const DoubleNav = (props) => {
    // console.log('>>>-DoubleNav-props->', props)
    const { state, dispatch } = useContext( Context )
    // console.log('>>>-DoubleNav-state->', state)
    
    const [ toggleStateA, setToggleStateA ] = useState(false)
    const [ windowWidth, setWindowWidth ] = useState(0)
    const breakWidth = 5200

    const navStyle = {
        paddingLeft: windowWidth > breakWidth ? '210px' : '16px',
        backgroundColor: '#0026ca'
    }
    const specialCaseNavbarStyles = {
        WebkitBoxOrient: 'horizontal',
        flexDirection: 'row'
    }

    useEffect( () => {
        //console.log(">>>-DoubleNavigationPage-useEffect-window.innerWidth->",window.innerWidth)
        // handleResize()
        const handleResize = setWindowWidth(window.innerWidth)
        //console.log(">>>-DoubleNavigationPage-handleResize->",handleResize)
        window.removeEventListener('resize', handleResize)
        //console.log(">>>-DoubleNavigationPage-useEffect-window.innerWidth->",window.innerWidth)
    }, [toggleStateA,windowWidth])

    const handleToggleClickA = () => {
        //console.log(">>>-DoubleNavigationPage-handleToggleClickA-toggleStateA->",toggleStateA)
        setToggleStateA(!toggleStateA)
        //console.log(">>>-DoubleNavigationPage-handleToggleClickA-toggleStateA->",toggleStateA)
    }

    const handleSignOut = async () => {
        await Auth.signOut()
        dispatch({ type: 'USER_SIGN_OUT', payload: null })
        return (<Redirect to='/' />)
    }

    const handleChoice = (event) => {
        event.preventDefault()
        //console.log(">>>-DoubleNavigationPage-handleChoice->")
        setToggleStateA(!toggleStateA)
        setWindowWidth(0)
    }

    return (
        <div className='fixed-sn light-blue-skin'>
            {
                state.userIsAuthenticated && (
                    <React.Fragment>
                    <SideNav
                    logo='favicon-32x32.png'
                    triggerOpening={toggleStateA}
                    breakWidth={breakWidth}
                    bg='images-9.jpg'
                    mask='strong'
                    >
                    {/* <Input
                        type='text'
                        label='Search'
                        default='Search'
                        style={{
                        padding: '8px 10px 8px 30px',
                        boxSizing: 'border-box',
                        color: 'white'
                        }}
                    /> */}
                        <SideNavNav>
                            <SideNavCat name='Vitals & Home Readings' id='vitals-cat' icon='heartbeat'>
                                <NavLink onSelect={handleChoice} to='/vitals-home' >
                                    Capture & Manage
                                </NavLink>
                                <NavLink to='/analyze' onSelect={handleChoice}>
                                    Analyze
                                </NavLink>
                                <NavLink to='/communicate'>
                                    Communicate
                                </NavLink>
                            </SideNavCat>
                            <SideNavCat name='Labs' id='labs-cat' icon='flask'>
                                <NavLink to='/blood-labs' onSelect={handleChoice}>
                                    Capture & Manage
                                </NavLink>
                                <NavLink to='/study-labs' onSelect={handleChoice}>
                                    Analyze
                                </NavLink>
                                <NavLink to='/communicate-labs'>
                                    Communicate
                                </NavLink>
                            </SideNavCat>
                            <SideNavCat name='Medical Supplies' id='medications-cat' icon='eye'>
                                <NavLink to='/prescriptions'>
                                    Prescriptions
                                </NavLink>
                                <NavLink to='/treatment-supplies'>
                                    Treatment Supplies
                                </NavLink>
                                <NavLink to='/other-supplies'>
                                    Other Supplies
                                </NavLink>
                                <NavLink to='/inventory'>
                                    Inventory & History
                                </NavLink>
                            </SideNavCat>
                            <SideNavCat name='My Providers' id='providers' icon='user-md'>
                                <NavLink to='/provider-details'>
                                    Provider Details
                                </NavLink>
                            </SideNavCat>
                            <SideNavCat name='Timelines' id='timeline' icon='calendar-week'>
                                <NavLink to='/timeline'>
                                    Provider Vists
                                </NavLink>
                                <NavLink to='/timeline'>
                                    Treatments
                                </NavLink>
                                <NavLink to='/timeline'>
                                    Health Events
                                </NavLink>
                                <NavLink to='/timeline'>
                                    Side Effects
                                </NavLink>
                                <NavLink to='/timeline'>
                                    Other
                                </NavLink>
                            </SideNavCat>
                        </SideNavNav>
                    </SideNav>
                    </React.Fragment>
                )
            }

            <Navbar style={navStyle} double expand='md' fixed='top' scrolling>
            <NavbarNav left>
                <MDBNavbarBrand>
                    <img src="favicon-16x16.png" alt="thumbnail" className="img-thumbnail" />
                </MDBNavbarBrand>
                <NavItem>
                <div
                    onClick={handleToggleClickA}
                    key='sideNavToggleA'
                    style={{
                    lineHeight: '32px',
                    marginRight: '1em',
                    verticalAlign: 'middle'
                    }}
                >
                    <Fa icon='bars' color='white' size='2x' />
                </div>
                </NavItem>
               
           
            </NavbarNav>
            <NavbarNav right style={specialCaseNavbarStyles}>

                {
                    state.userIsAuthenticated && (
                    <React.Fragment>

                    <NavLink to='/dashboard'>
                        <MDBIcon icon="tachometer-alt" size="lg" />{' '}
                            <div className='d-none d-md-inline'>Dashboard</div>
                    </NavLink>

                    {/* <NavLink to='/todos'>
                        <MDBIcon icon="list-alt" size="lg" />{' '}
                            <div className='d-none d-md-inline'>To-dos</div>
                    </NavLink> */}

                    <NavLink to='/notes'>
                        <MDBIcon icon="notes-medical" size="lg" />{' '}
                            <div className='d-none d-md-inline'>Notes</div>
                    </NavLink>

                    {/* <NavLink to='/ch-chat'>
                        <MDBIcon far icon="comments" size="lg" />{' '}
                            <div className='d-none d-md-inline'><em>ch</em> Chat</div>
                    </NavLink> */}
                    
                    <NavItem active>
                        <NavLink to='/services'>
                        <MDBIcon icon="shopping-cart" />{' '}
                            <div className='d-none d-md-inline'>Services</div>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <MDBDropdown onChange={handleChoice}>
                            <MDBDropdownToggle nav caret>
                            <div className='d-none d-md-inline'>Account</div>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu right>
                                <MDBDropdownItem>
                                    <NavLink to='/dashboard'>
                                        <MDBIcon icon="comment-alt" size="2x" />{' '}
                                        Messages 
                                        <MDBBadge color="info" className="ml-2">{4}</MDBBadge>
                                    </NavLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <NavLink to='/dashboard'>
                                        <MDBIcon icon="clipboard-check" size="2x" />{' '}
                                        Reminders
                                        <MDBBadge color="info" className="ml-2">{2}</MDBBadge>
                                    </NavLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <NavLink to='/dashboard'>
                                        <MDBIcon icon="calendar-check" size="2x" />{' '}
                                        Calendar
                                        <MDBBadge color="info" className="ml-2">{3}</MDBBadge>
                                    </NavLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <NavLink to='/account-management'>
                                        <MDBIcon icon="user-circle" size="2x" />{' '}
                                        Account Management
                                        <MDBBadge color="danger" className="ml-2">{29}{' '}days</MDBBadge>
                                        <MDBBadge color="warning" className="ml-2">{0}{' '}items</MDBBadge>
                                    </NavLink>
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </NavItem>
                    <NavItem active>
                        <NavLink to='/' onClick={handleSignOut}>
                        <MDBIcon icon="sign-out-alt" size="lg" />{' '}
                            <div className='d-none d-md-inline'>Sign Out</div>
                        </NavLink>
                    </NavItem>
                    </React.Fragment>
                    )
                }

                {
                    !state.userIsAuthenticated && (
                        <React.Fragment>
                        <NavItem active>
                            <NavLink to='/home'>
                            <MDBIcon icon="home" />{' '}
                                <div className='d-none d-md-inline'>Home</div>
                            </NavLink>
                        </NavItem>
                        <NavItem active>
                            <NavLink to='/services'>
                            <MDBIcon icon="shopping-cart" />{' '}
                                <div className='d-none d-md-inline'>Services</div>
                            </NavLink>
                        </NavItem>
                        <NavItem active>
                            <NavLink to='/about'>
                            <MDBIcon icon="question-circle" />{' '}
                                <div className='d-none d-md-inline'>About</div>
                            </NavLink>
                        </NavItem>
                        <NavItem active>
                            <NavLink to='/blog'>
                            <MDBIcon icon="blog" />{' '}
                                <div className='d-none d-md-inline'>Blog</div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='/contact'>
                                <Fa icon='envelope' className='d-inline-inline' size="lg" />{' '}
                                <div className='d-none d-md-inline'>Contact</div>
                            </NavLink>
                        </NavItem>
                        <NavItem active>
                            <NavLink to='/signin'>
                            <MDBIcon icon="sign-in-alt" size="lg" />{' '}
                                <div className='d-none d-md-inline'>Sign In</div>
                            </NavLink>
                        </NavItem>
                        <NavItem active>
                            <NavLink to='/signup'>
                            <MDBIcon icon="user-plus" />{' '}
                                <div className='d-none d-md-inline'>Sign Up</div>
                            </NavLink>
                        </NavItem>

                        </React.Fragment>
                    )
                }

            </NavbarNav>
            </Navbar>
        </div>
    )
}

export default DoubleNav
