import React from 'react'

import Preferences from './Preferences'
import Targets from './Targets'

import { 
    MDBContainer,
    MDBRow,
    MDBCol
} from "mdbreact";

const PreferencesAndTargets = () => {
    return (
        <MDBContainer>
            <MDBRow >
                <MDBCol md="6">
                    <Preferences />
                </MDBCol>
                <MDBCol md="6">
                    <Targets />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default PreferencesAndTargets