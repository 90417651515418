// src/pages/about/Chapter3.js

import React from 'react'

import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBModalBody, 
    MDBModalHeader, 
    MDBModalFooter } from 'mdbreact';


const Chapter3 = ({isOpen,toggle}) => {
    //   console.log('>>>-Chapter_1-toggle->',toggle)

    return (
    <MDBContainer>
    
        <MDBModal
            className="modal-notify modal-info text-white" 
            isOpen={isOpen} 
            toggle={toggle}
        >
            <MDBModalHeader 
                toggle={toggle}
            >
                Chapter 3
            </MDBModalHeader>
            <MDBModalBody>
<p>
Christy and her husband did home hemodialysis 5 days a week for nearly two years.   
Her veins were too small and fragile for a fistula and an arteriovenous graft meant foreign material in her body increasing chances of infection due to her suppressed immune system.  
She managed a central venous catheter (CVC) in her upper chest and neck area for those two years.
The dialysis helped stabilized her symptoms but there were side effects; it was no match for working kidneys.
</p>
<p>
More to come…    
</p>
            </MDBModalBody>
            <MDBModalFooter className="justify-content-center">
            <MDBBtn 
                color="primary" 
                onClick={toggle}
            >Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    </MDBContainer>
    );
}

export default Chapter3;