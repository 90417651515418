// src/pages/services/ServiceCatalogManage.js

import React, { useState } from 'react';
//import { Context } from '../../state/Context'

import CaptureTEMP1 from '../../pictures/services/manage/CaptureTEMP1.PNG'
import CaptureTEMP2 from '../../pictures/services/manage/CaptureTEMP2.PNG'
import CaptureTEMP3 from '../../pictures/services/manage/CaptureTEMP3.PNG'
import CaptureTEMP4 from '../../pictures/services/manage/CaptureTEMP4.PNG'

import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBCol, 
    MDBCarouselInner, 
    MDBCard, 
    MDBCardBody, 
    MDBCarousel, 
    MDBModalBody,
    MDBCollapseHeader, 
    MDBCarouselItem, 
    MDBRow, 
    MDBCollapse} from 'mdbreact';


const ServiceCatalogManage = ({ offeringName, isOpen, toggle }) => {
    //const { state } = useContext( Context )
    //console.log(">>>-ServiceCatalog-state->",state)
    const [ collapseID, setCollapseID ] = useState('discription')

    const toggleCollapse = collapseID => () => setCollapseID(
        prevState => (prevState.collapseID !== collapseID ? collapseID : "")
    )

  return (
        <MDBContainer>
        
        <MDBModal 
            size="fluid" 
            isOpen={isOpen} 
            toggle={toggle}
        >
        <MDBModalBody className="d-flex">
            <MDBCol size="5" lg="5">
                <MDBCarousel 
                    activeItem={1} 
                    length={4} 
                    showControls={true} 
                    showIndicators={true} 
                    thumbnails 
                    className="z-depth-1"
                >
                    <MDBCarouselInner>
                    <MDBCarouselItem itemId="1">
                        <img className="d-block w-100" 
                            src={CaptureTEMP1}
                            alt={"CaptureTEMP1"} 
                        />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="2">
                        <img className="d-block w-100" 
                            src={CaptureTEMP2}
                            alt={"CaptureTEMP2"}
                        />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="3">
                        <img className="d-block w-100" 
                            src={CaptureTEMP3}
                            alt={"CaptureTEMP3"} 
                        />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="4">
                        <img className="d-block w-100" 
                            src={CaptureTEMP4}
                            alt={"CaptureTEMP4"} 
                        />
                        </MDBCarouselItem>
                    </MDBCarouselInner>
                </MDBCarousel>
            </MDBCol>

            <MDBCol size="7" lg="7">
                <h2 className="h2-responsive product-name">
                <strong>APP: {offeringName} update, edit, or delete.</strong>
                </h2>
                <div className="my-4">

                <MDBCard>
                    <MDBCollapseHeader 
                        onClick={toggleCollapse("discription")}
                        className="z-depth-1 teal lighten-4"
                        tagClassName="black-text text-center font-weight-bold text-uppercase"
                        tag="h4"
                    >
                    Description
                    
                        </MDBCollapseHeader>
                        <MDBCollapse 
                            id="discription" 
                            isOpen={collapseID}
                        >
                            <MDBCardBody>
Manage all your data.  Correct mistakes, delete and reenter, or enter the minimum required data and come back to the optional data later.  The following can be captured:
<p>-	Blood pressure and pulse</p>
<p>-	Glucose</p>
<p>-	Height</p>
<p>-	Oxygenation</p>
<p>-	Respiration</p>
<p>-	Temperature</p>
<p>-	Weight</p>
                            </MDBCardBody>
                        </MDBCollapse>
                    </MDBCard>

                    <MDBCard>
                        <MDBCollapseHeader 
                            onClick={toggleCollapse("details")}
                            className="z-depth-1 teal lighten-4"
                            tagClassName="black-text text-center font-weight-bold text-uppercase"
                            tag="h4"
                        >
                        Details
                            
                        </MDBCollapseHeader>
                        <MDBCollapse 
                            id="details" 
                            isOpen={collapseID}
                        >
                            <MDBCardBody>
<p>
There are minimal required data fields when entering your data.  
You can complete the optional data fields later or never.  
Any mistaken data entry values can be corrected or even deleted.  
You are in total control of managing the quality of your data.
</p>
<p>
High quality and complete data has the added advantage of helping you distinguish between important and unimportant, meaningful and trivial.
</p> 
                            </MDBCardBody>
                        </MDBCollapse>
                    </MDBCard>
                    
                    </div>
                    
                    <MDBRow className="justify-content-center mb-3">
                        <MDBBtn color="secondary" className="ml-4" onClick={toggle} >Close</MDBBtn>
                    </MDBRow>

                </MDBCol>
        

            </MDBModalBody>
        </MDBModal>
        </MDBContainer>
    );
}

export default ServiceCatalogManage;