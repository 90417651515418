// src/pages/VitalsHomeReadings/EnterData.js

import React from 'react'
// import { Context } from '../../state/Context'
// import { API, graphqlOperation } from 'aws-amplify'
// import { format as formatFNS, getHours, getMinutes } from 'date-fns'

import EnterBloodPressurePulse from './EnterBloodPressurePulse'
import EnterGlucose from './EnterGlucose'
import EnterOxygenation from './EnterOxygenation'
import EnterRespiration from './EnterRespiration'
import EnterTemperature from './EnterTemperature'
import EnterWeight from './EnterWeight'
import EnterHeight from './EnterHeight'


const EnterData = ({ homeReadingSelected }) => {
    //console.log(">>>-EnterData-homeReadingSelected->",homeReadingSelected)

    return (
        <div>
            {(homeReadingSelected === 'Blood Pressure Pulse') ? <EnterBloodPressurePulse homeReadingSelected={homeReadingSelected} /> : null}
            {(homeReadingSelected === 'Glucose') ? <EnterGlucose homeReadingSelected={homeReadingSelected} /> : null}
            {(homeReadingSelected === 'Oxygenation') ? <EnterOxygenation homeReadingSelected={homeReadingSelected} /> : null}
            {(homeReadingSelected === 'Respiration') ? <EnterRespiration homeReadingSelected={homeReadingSelected} /> : null}
            {(homeReadingSelected === 'Temperature') ? <EnterTemperature homeReadingSelected={homeReadingSelected} /> : null}
            {(homeReadingSelected === 'Weight') ? <EnterWeight homeReadingSelected={homeReadingSelected} /> : null}
            {(homeReadingSelected === 'Height') ? <EnterHeight homeReadingSelected={homeReadingSelected} /> : null}
        </div>
    )
}

export default EnterData