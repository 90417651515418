// src/auth/SignIn.js

import React, { useState, useContext } from 'react'
import { Context } from '../state/Context'
import { Auth, API, graphqlOperation } from 'aws-amplify'
// import { Link } from 'react-router-dom'

import { getUser, getPreferences } from '../graphql/queries'
import { listCartedServices, listSubscriptionss } from '../graphql/queries'

import { validations } from "../utilities/validations.js";

import {
    MDBCard, 
    MDBCardBody, 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBModalFooter,
    MDBBtn,
    MDBInput,
    MDBIcon
} from "mdbreact";

const SignIn = (props) => {
    // console.log('>>>-SignIn-props->', props)
    const { dispatch } = useContext( Context )
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ isSubmitting, setIsSubmitting ] = useState('')
    const [ formErrors, setFormErrors ] = useState(null)

// get user details from Async
    const getUserAsync = async (id) => {
        try {
            const input = { id: id }
            const result = await API.graphql(graphqlOperation( getUser, input ))
            // console.log(">>>-SignIn-getUserAsync-result.data.getUser->", result.data.getUser) 

            dispatch({ type: 'USER_SIGN_IN_DETAILS', payload: result.data.getUser })
            // return result.data.getUser
            return
        } catch (err) {
            console.log('error fetching user details...', err)
        }
    }

// get user subscriptions from Async
    const getUserSubscriptionsAsync = async () => {
        try {
            const result = await API.graphql(graphqlOperation( listSubscriptionss, {limit:100000} ))
            // console.log(">>>-SignIn-result.data.listSubscriptionss.items->", result.data.listSubscriptionss.items) 

            if ( result.data.listSubscriptionss.items.length > 0 ) {
                dispatch({ type: 'USER_SIGN_IN_WITH_SUBSCRIPTIONS', payload: result.data.listSubscriptionss.items })
            } else {
                dispatch({ type: 'USER_SIGN_IN_WITHOUT_SUBSCRIPTIONS', payload: result.data.listSubscriptionss.items })
            }
            
            // return result.data.getUser
            return
        } catch (err) {
            console.log('error fetching subscriptions...', err)
        }
    }

// get user cart from Async
    const getUserCartAsync = async () => {
        try {
            const result = await API.graphql(graphqlOperation( listCartedServices, {limit:100000} ))
            // console.log(">>>-SignIn-getUserCartAsync-result.data.getUser->", result.data.listCartedServices.items) 

            dispatch({ type: 'LOAD_CART_ITEMS', payload: result.data.listCartedServices.items })
            // return result.data.getUser
            return
        } catch (err) {
            console.log('error fetching listNotes...', err)
        }
    }    

// get user preferences from Async
    const getPreferencesAsync = async (id) => {
        try {
            const input = { id: id }
            const result = await API.graphql(graphqlOperation( getPreferences, input ))
            // console.log(">>>-Preferences-getPreferencesAsync-getPreferences->", result.data.getPreferences)

            dispatch({ type: 'USER_SIGN_IN_PREFERENCES', payload: result.data.getPreferences })
            // return result.data.getUser
            return
        } catch (err) {
            console.log('error fetching getUser...', err)
        }
    }

// get user currentReadings from Async    
//----------------------------------------------------------------
// 
// set currentWeight in global state
//      1- on sign-in get the most recent reading
//      2- on every use of EnterWeight (new/edit/delete) test for most recent, update if needed
// 
//----------------------------------------------------------------

    const handleSignIn = async (event) => {
        event.preventDefault()
        setIsSubmitting( true )
        // console.log('>>>-SignIn-handleSignIn-{email,password}->',{email,password})
        
        try {
            const user = await Auth.signIn(
                email, 
                password
            )
            dispatch({ type: 'USER_SIGN_IN_SESSION', payload: user.signInUserSession })
// Async query - getUserAsync
            // console.log('>>>-SignIn-handleSignIn-user->',user)
            // console.log('>>>-SignIn-handleSignIn-user.attributes.sub->',user.attributes.sub)
            getUserAsync(user.attributes.sub)
            //const getUserData = getUserAsync(user.attributes.sub)
            // console.log('>>>-SignIn-getUserData->',getUserData)

            getUserSubscriptionsAsync()
            //const getUserSubscriptions = getUserSubscriptionsAsync()
            // console.log('>>>-SignIn-getUserSubscriptionsAsync->',getUserSubscriptions)

            getUserCartAsync()
            //const getUserCart = getUserCartAsync()
            // console.log('>>>-SignIn-getUserCartAsync->',getUserCart)

            getPreferencesAsync(user.attributes.sub)
            //const getUserPreferences = getPreferencesAsync(user.attributes.sub)
            // console.log('>>>-SignIn-getUserPreferences->',getUserPreferences)
            
            // dispatch({ type: 'USER_SIGN_IN_DETAILS', payload: getUserData })
            dispatch({ type: 'EDIT_HOME_READING', payload: {} })

            setFormErrors( null )
            setIsSubmitting( false )
            return props.history.push('/dashboard')
        } 
        catch (err) {
            alert(err.message)
            setFormErrors(err)
            console.log('>>>-SignIn-formErrors->',formErrors)
            setIsSubmitting( false )
        } 
    }

    return (
        <main style={{margin: "0 1%", paddingTop: "2rem"}}>
        <MDBCard 
            className="my-2 px-2 mx-auto teal lighten-5"
            style={{ fontWeight: 300, maxWidth: "400px" }}
        >
            <MDBCardBody style={{ paddingTop: 0 }}>

        <MDBContainer>
        <MDBRow>
            <MDBCol md="12">
            <form  onSubmit={handleSignIn}>
            <h2 className="h1-responsive font-weight-bold text-center my-5">
            Sign In
            </h2>
                
                <div className="grey-text">
                    <MDBInput
                        label="Your email"
                        name='email' 
                        type="email"
                        required
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        className={email && email.match(validations.EMAIL)  
                        ? "is-valid" : "is-invalid"}
                    >
                        <div className="valid-feedback">Valid email.</div>
                        <div className="invalid-feedback">Enter a valid email.</div>
                    </MDBInput>
                    <MDBInput
                        label="Your password"
                        name='password'
                        type="password"
                        required
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        className={password && password.match(validations.PASSWORD)  
                        ? "is-valid" : "is-invalid"}
                    >
                        <div className="valid-feedback">Enter a valid password.</div>
                        <div className="invalid-feedback">
                            Enter a valid password.  Minimum 8 characters,
                            <p>
                            one capital letter,
                            one of !,@,#,$,%,^,&,*,(,),
                            one number.
                            </p>
                        </div>
                    </MDBInput>
                </div>

                <div className="text-center">
                <MDBBtn 
                    type="submit" 
                    color="primary"
                    disabled={ !email || !password }
                >
                    {isSubmitting 
                    ? (<MDBIcon icon="spinner" pulse size="1x" fixed />) 
                    : ('Sign In')}
                </MDBBtn>
                </div>

            </form>
            <MDBModalFooter>
                    <div className="font-weight-light">
                        <p>Not a member?
                            <a href="/signup" className="blue-text ml-1">
                                Sign up!
                            </a>
                        </p>
                        <p>
                            <a href="/forgot-password" className="blue-text ml-1">
                                Forgot Password?
                            </a>
                        </p>
                    </div>
                </MDBModalFooter>
            </MDBCol>
        </MDBRow>
        </MDBContainer>
            </MDBCardBody>
        </MDBCard>
        </main>
    );
};

export default SignIn;