import React, { useState, useContext, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Context } from '../../state/Context'

import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBIcon
} from "mdbreact";

import { getPreferences } from '../../graphql/queries'
import { updatePreferences } from '../../graphql/mutations';


const Preferences = () => {
    const { state, dispatch } = useContext( Context )
    //console.log('>>>-Preferences-state->',state)
    const [ preferencesAsync, setPreferencesAsync ] = useState({})

    const [ unitWeight, setUnitWeight ] = useState('')
    const [ unitHeight, setUnitHeight ] = useState('')
    const [ unitTemperature, setUnitTemperature ] = useState('')
    const [ unitAltitude, setUnitAltitude ] = useState('')
    const [ unitFluid, setUnitFluid ] = useState('')

    const [ isSubmitting, setIsSubmitting ] = useState('')

    useEffect( () => { getPreferencesAsync(state.userDetails.id) }, [state.userDetails] )

// get user preferences from Async
    const getPreferencesAsync = async (id) => {
        try {
            const result = await API.graphql(graphqlOperation( getPreferences, {id} ))
            //console.log(">>>-Preferences-getPreferencesAsync-getPreferences->", result)
            if (result.data.getPreferences) {
                setPreferencesAsync(result.data.getPreferences)

                setUnitWeight(result.data.getPreferences.unitWeight)
                setUnitHeight(result.data.getPreferences.unitHeight)
                setUnitTemperature(result.data.getPreferences.unitTemperature)
                setUnitAltitude(result.data.getPreferences.unitAltitude)
                setUnitFluid(result.data.getPreferences.unitFluid)
            } 
        } catch (err) {
            console.log('error fetching getPreferences...', err)
        }
    }

    const handlePreferences = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        //console.log(">>>-Preferences-handlePreferences-preferencesAsync->",preferencesAsync)
        const input = {
// DO NOT EDIT in the form             
            id: state.userDetails.id,
            expectedVersion: preferencesAsync.version,
            cognitoId: preferencesAsync.cognitoId,
            chId: preferencesAsync.chId,
            usernameAWS: preferencesAsync.usernameAWS,
            ownerAWS: state.userDetails.ownerAWS,
// EDIT in the form             
            unitWeight: unitWeight,
            unitHeight: unitHeight,
            unitFluid: unitFluid,
            unitAltitude: unitAltitude,
            unitTemperature: unitTemperature
        }
        //console.log(">>>-Preferences-handlePreferences-input->",input)
        dispatch({ type: 'USER_EDIT_PREFERENCES', payload: input })
        await API.graphql(graphqlOperation(updatePreferences,  { input }))

        setIsSubmitting(false)
    }

    const handleClearAll = (event) => {
        event.preventDefault()
        getPreferencesAsync(state.userDetails.id)
    }

    return (          
        <MDBContainer className="mt-5">
        <form onSubmit={handlePreferences}>
        <MDBRow>
        <MDBCol>
            <h3>Measurement Unit Preferences</h3>
            <hr />
            <h6>Weight</h6>
            <MDBRow>
                <MDBInput 
                    onClick={e => setUnitWeight(e.target.id)} 
                    checked={unitWeight === 'Kilograms' ? true : false} 
                    label="Kilograms" 
                    type="radio"
                    id="Kilograms" 
                />
                <MDBInput 
                    onClick={e => setUnitWeight(e.target.id)} 
                    checked={unitWeight === 'Pounds' ? true : false} 
                    label="Pounds" 
                    type="radio"
                    id="Pounds" 
                />
            </MDBRow>
            <hr />
            <h6>Height</h6>
            <MDBRow>
                <MDBInput 
                    onClick={e => setUnitHeight(e.target.id)} 
                    checked={unitHeight === 'Centimeters' ? true : false} 
                    label="Centimeters" 
                    type="radio"
                    id="Centimeters" 
                />
                <MDBInput 
                    onClick={e => setUnitHeight(e.target.id)} 
                    checked={unitHeight === 'Inches' ? true : false} 
                    label="Inches" 
                    type="radio"
                    id="Inches" 
                />
            </MDBRow>
            <hr />
            <h6>Temperature</h6>
            <MDBRow>
                <MDBInput 
                    onClick={e => setUnitTemperature(e.target.id)} 
                    checked={unitTemperature === 'Celsius' ? true : false} 
                    label="Celsius" 
                    type="radio"
                    id="Celsius" 
                />
                <MDBInput 
                    onClick={e => setUnitTemperature(e.target.id)} 
                    checked={unitTemperature === 'Fahrenheit' ? true : false} 
                    label="Fahrenheit" 
                    type="radio"
                    id="Fahrenheit" 
                />
            </MDBRow>
            <hr />
            <h6>Fluides</h6>
            <MDBRow>
                <MDBInput 
                    onClick={e => setUnitFluid(e.target.id)} 
                    checked={unitFluid === 'Liters' ? true : false} 
                    label="Liters" 
                    type="radio"
                    id="Liters" 
                />
                <MDBInput 
                    onClick={e => setUnitFluid(e.target.id)} 
                    checked={unitFluid === 'Quarts' ? true : false} 
                    label="Quarts (32 oz)" 
                    type="radio"
                    id="Quarts" 
                />
            </MDBRow>
            <hr />
            <h6>Altitude</h6>
            <MDBRow>
                <MDBInput 
                    onClick={e => setUnitAltitude(e.target.id)} 
                    checked={unitAltitude === 'Meters' ? true : false} 
                    label="Meters" 
                    type="radio"
                    id="Meters" 
                />
                <MDBInput 
                    onClick={e => setUnitAltitude(e.target.id)} 
                    checked={unitAltitude === 'Feet' ? true : false} 
                    label="Feet" 
                    type="radio"
                    id="Feet" 
                />
            </MDBRow>
            <hr />

            <h3>Data Entry Preferences</h3>
            <p>
            You can pre-select optional data entry fields to make help make the task much less burdensome.  
            For example, you can pre-select your body position as sitting (choices are reclining, sitting, standing) 
            if most of the time you take your blood pressure pulse in the sitting position.</p>
            <hr />
            <h6>Blood pressure pulse</h6>
            <hr />
            <h6>Temperature</h6>
            <hr />
            <h6>Weight</h6>
            <hr />
            <h6>Oxigenation</h6>
            <hr />
            <h6>Glucose</h6>
            <hr />
            <h6>Height</h6>
            <MDBRow>

            </MDBRow>

            <div className="text-center mt-4">
                <MDBBtn color="primary" type="submit" >
                    {isSubmitting 
                        ? (<MDBIcon icon="spinner" pulse size="1x" fixed />) 
                        : ('Update')
                    }
                </MDBBtn>
                <MDBBtn color="secondary" onClick={handleClearAll}>
                    Clear All
                </MDBBtn>
            </div>
        </MDBCol>
        </MDBRow>
        </form>
        </MDBContainer>
    )
}

export default Preferences