// src/pages/VitalsHomeReadings/VitalsHomeReadings.js

import React, { useState } from 'react'

import EnterData from './EnterData'
import StudyData from './StudyData'
import SummaryCharts from './SummaryCharts'
import SummaryTables from './SummaryTables'
import DatatableBloodPressurePulse from './DatatableBloodPressurePulse'
import DatatableTemperature from './DatatableTemperature'
import DatatableGlucose from './DatatableGlucose'
import DatatableOxygenation from './DatatableOxygenation'
import DatatableRespiration from './DatatableRespiration'
import DatatableWeight from './DatatableWeight'
import DatatableHeight from './DatatableHeight'

import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBBtnGroup,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCard,
    MDBCardBody
} from "mdbreact";


function VitalsHomeReadings () {
    const [ homeReadingSelected, setHomeReadingSelected ] = useState('Blood Pressure Pulse')
    const [ enterOrStudy, setEnterOrStudy ] = useState('Enter Data')
    const [ chartsOrTables, setChartsOrTables ] = useState('Charts')

    
    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>
        <MDBContainer>
        <MDBRow >
            <MDBCol md="12">
                <MDBCard>
                    <div className="header pt-1" style={{background: "#304fff", color: "white"}}>
                        <MDBRow className="d-flex justify-content-start mr-1">
                        <MDBCol className="deep-grey-text my-4 mx-2">
                            <h1>
                                Vitals & Home Readings
                            </h1>
                        </MDBCol>
                        <MDBCol className="deep-grey-text my-1 mx-1">
                            <h4>
                                Select Reading
                            </h4>
                        <MDBBtnGroup>
                            <MDBDropdown>
                                <MDBDropdownToggle caret color="cyan accent-1" className="h-100">
                                {homeReadingSelected}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu basic color="info">
                                <MDBDropdownItem onClick={e => setHomeReadingSelected('Blood Pressure Pulse')}>Blood Pressure Pulse</MDBDropdownItem>
                                <MDBDropdownItem onClick={e => setHomeReadingSelected('Glucose')}>Glucose</MDBDropdownItem>
                                <MDBDropdownItem onClick={e => setHomeReadingSelected('Height')}>Height</MDBDropdownItem>
                                <MDBDropdownItem onClick={e => setHomeReadingSelected('Oxygenation')}>Oxygenation</MDBDropdownItem>
                                <MDBDropdownItem onClick={e => setHomeReadingSelected('Respiration')}>Respiration</MDBDropdownItem>
                                <MDBDropdownItem onClick={e => setHomeReadingSelected('Temperature')}>Temperature</MDBDropdownItem>
                                <MDBDropdownItem onClick={e => setHomeReadingSelected('Weight')}>Weight</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBBtnGroup>
                        </MDBCol>
                        </MDBRow>
                    </div>
                </MDBCard>
            </MDBCol>
        </MDBRow>
        <MDBRow >
            <MDBCol md="6">
                <MDBCard>
                <div className="header pt-1" style={{background: "#baffff"}}>
                    <MDBRow className="d-flex justify-content-start mr-1">
                        <MDBCol>
                            <h4 className="deep-grey-text mt-1 mb-1 pb-1 mx-1">
                            {homeReadingSelected}
                            </h4>
                        </MDBCol>
                        <MDBCol>
                        <MDBBtnGroup size="sm" className="white-text mt-1 mb-1 pb-1 mx-1">
                            <MDBBtn 
                                color="primary" 
                                outline={(enterOrStudy === 'Enter Data') ? true : false}
                                onClick={e => setEnterOrStudy('Enter Data')}
                            >
                                Enter Data
                            </MDBBtn>
                            <MDBBtn 
                                color="primary" 
                                outline={(enterOrStudy === 'Study Data') ? true : false}
                                onClick={e => setEnterOrStudy('Study Data')}
                            >
                                Study Data
                            </MDBBtn>
                        </MDBBtnGroup>
                        </MDBCol>
                    </MDBRow>
                </div>
                <MDBCardBody className="mx-1 mt-1">
                    <MDBRow className="d-flex justify-content-start">
                    { 
                        (enterOrStudy === "Enter Data") ? (
                            <EnterData homeReadingSelected={homeReadingSelected}/>
                            ) : (
                            <StudyData homeReadingSelected={homeReadingSelected}/>
                        )
                    }
                    </MDBRow>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol md="6">
                <MDBCard>
                <div className="header pt-1" style={{background: "#baffff"}}>
                    <MDBRow className="d-flex justify-content-start mr-1">
                    <MDBCol>
                        <h4 className="deep-grey-text mt-1 mb-1 pb-1 mx-1">
                        Summary
                        </h4>
                        </MDBCol>
                        <MDBCol>
                        <MDBBtnGroup size="sm" className="white-text mt-1 mb-1 pb-1 mx-1">
                            <MDBBtn 
                                color="primary" 
                                outline={(chartsOrTables === 'Charts') ? true : false}
                                onClick={e => setChartsOrTables('Charts')}
                            >
                                Charts
                            </MDBBtn>
                            <MDBBtn 
                                color="primary" 
                                outline={(chartsOrTables === 'Tables') ? true : false}
                                onClick={e => setChartsOrTables('Tables')}
                            >
                                Tables
                            </MDBBtn>
                        </MDBBtnGroup>
                        </MDBCol>
                    </MDBRow>
                </div>
                <MDBCardBody className="mx-1 mt-1">
                    <MDBRow className="d-flex justify-content-start">
                    { 
                        (chartsOrTables === "Charts") ? (
                            <SummaryCharts homeReadingSelected={homeReadingSelected}/>
                            ) : (
                            <SummaryTables homeReadingSelected={homeReadingSelected}/>
                        )
                    }
                    </MDBRow>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>

        <MDBRow>

            <MDBCard narrow>
                <div className="header pt-1" style={{background: "#baffff"}}>
                    <MDBRow className="d-flex justify-content-start mr-1">
                        <MDBCol>
                            <h4 className="deep-grey-text mt-1 mb-1 pb-1 mx-1">
                            Datatable: {homeReadingSelected} (maximum: most recent 1,000 readings)
                            </h4>
                        </MDBCol>
                    </MDBRow>
                </div>
                <MDBCardBody cascade>
                    {homeReadingSelected === 'Blood Pressure Pulse' ? (<DatatableBloodPressurePulse />) : null}
                    {homeReadingSelected === 'Glucose' ? (<DatatableGlucose />) : null}
                    {homeReadingSelected === 'Oxygenation' ? (<DatatableOxygenation />) : null}
                    {homeReadingSelected === 'Respiration' ? (<DatatableRespiration />) : null}
                    {homeReadingSelected === 'Temperature' ? (<DatatableTemperature />) : null}
                    {homeReadingSelected === 'Weight' ? (<DatatableWeight />) : null}
                    {homeReadingSelected === 'Height' ? (<DatatableHeight />) : null}
                </MDBCardBody>
            </MDBCard>

        </MDBRow>

        </MDBContainer>

        </main>
    )
}

export default VitalsHomeReadings