// src/pages/Blog.js

import React from 'react'
import { 
    MDBRow, 
    MDBCol, 
    MDBCard, 
    MDBCardBody, 
    MDBMask, 
    MDBIcon, 
    MDBView, 
    MDBBtn } from "mdbreact";

const Blog = () => {


  return (
    <main style={{margin: "0 2%", paddingTop: "5rem"}}>

    <MDBCard 
        className="my-5 px-5 mx-auto teal lighten-5"
        style={{ fontWeight: 300, maxWidth: "98%" }}
    >
        <MDBCardBody style={{ paddingTop: 0 }}>
        <h2 className="h1-responsive font-weight-bold text-center my-5">
            Recent posts
        </h2>
        <p className="text-center w-responsive mx-auto mb-5">
            When you or a loved one gets seriously ill, it's time to learn as much as possible as soon possible. 
            Living with chronic conditions is complicate. 
            Having all your information at your fingertips is essential for managing your conditions and health events.
        </p>
        <MDBRow>
            <MDBCol lg="4">
            <MDBView className="rounded z-depth-3 mb-lg-0 mb-6" hover waves>
                <img
                className="img-fluid"
                src="pictures/blogs/blog1/BlogPost1.png"
                alt=""
                />
                <a href="#!">
                <MDBMask overlay="white-slight" />
                </a>
            </MDBView>
            </MDBCol>
            <MDBCol lg="7">
            <a href="#!" className="green-text">
                <h6 className="font-weight-bold mb-3">
                <MDBIcon icon="user-md" className="pr-2" />
                Sepsis - Post 1
                </h6>
            </a>
            <h3 className="font-weight-bold mb-3 p-0">
                <strong>How In-home Data Collection Completes the View of a Sepsis Episode</strong>
            </h3>
<p>
<em>Heads up: No clinical advice is being offered.</em>
</p>
<p>
This is post 1 of a series specifically targeted to in-home sepsis detection.  
I have a personal stake in making sure I have such a capability – my wife has survived two sepsis episodes and is at high risk of contracting sepsis in the future. 
“Sepsis is the body’s extreme response to an infection. 
It is life-threatening and without timely treatment can rapidly cause tissue damage, organ failure, and death.
Sepsis is a medical emergency. Time matters.” - CDC.
</p>
<p>
Even though we are sepsis experienced we are by no means sepsis experts.  
We are working with a great healthcare team.  
They ‘see’ what is measured and recorded by their specialist colleagues after the fact.  
They don’t ‘see’ what’s measured in-home.  
We believe considerable value can accrue from in-home measurements corroborate and coordinated with in-hospital measurements.
Time is valuable when detecting sepsis and in-home measurements can fill an existing information gap when information is most valuable – at the start of an episode.
</p>
<p>
The pictures that follow visually describe a sepsis episode for one person with a unique clinical history.  
The data is a combination of in-home and in-hospital collected data.  
The circumstances of every reading is captured in detail.  
The details of the clinical history, sepsis episode and data capture will be described in subsequent posts.    
</p>
            <p>
                by: 
                <a href="#!">
                <strong>Mitch Stonehocker</strong>
                </a>
                , 2018/04/08
            </p>
            <MDBBtn color="primary" size="md" className="waves-light ">
                Read more
            </MDBBtn>
            </MDBCol>
        </MDBRow>

        <hr className="my-5" />

        <MDBRow>
            <MDBCol lg="7">
            <a href="#!" className="green-text">
                <h6 className="font-weight-bold mb-3">
                <MDBIcon icon="user-md" className="pr-2" />
                Sepsis - Post 2
                </h6>
            </a>
            <h3 className="font-weight-bold mb-3 p-0">
                <strong>How In-home Data Collection Completes the View of a Sepsis Episode</strong>
            </h3>
<p>
<em>Heads up: No clinical advice is being offered.</em>
</p>            
<p>
In post 1 we showed how vital signs captured in-home can support patient and caregiver efforts to measure the signs of risk.
Vital signs are easily measured and there are many thousands of measuring devices available at reasonable price points.
Christy Health Inc. is where you can capture, manage, and analyze your vital sign data.
In this post we follow-up with data that is not easily captured, managed or analyzed but can be even more insightful than the vitals data.
We look at blood lab results.  
For those with chronic conditions, labs are a recurring fact of life, and when in hospital, labs may be taken several times a day, as will be seen below.  
When in-home, labs are taken periodically, as ordered by doctors, by visits to a lab, home collection and shipment to a lab, or in a doctor’s office for instance.  
In our case, it’s rare to go more than 30 days between blood samples.  
It’s not uncommon to have blood drawn 5 or 6 times a month.
</p>
<p>

</p>
            <p>
                by:
                <a href="#!">
                <strong>Mitch Stonehocker</strong>
                </a>
                , 2018/04/25
            </p>
            <MDBBtn
                color="primary"
                size="md"
                className="mb-lg-0 mb-4 waves-light"
            >
                Read more
            </MDBBtn>
            </MDBCol>
            <MDBCol lg="4">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                <img
                className="img-fluid"
                src="pictures/blogs/blog2/BlogPost2.png"
                alt=""
                />
                <a href="#!">
                <MDBMask overlay="white-slight" />
                </a>
            </MDBView>
            </MDBCol>
        </MDBRow>

        <hr className="my-5" />

        </MDBCardBody>
    </MDBCard>
    </main>
  );
}

export default Blog