// eslint-disable
// this is an auto generated file. This will be overwritten

export const getNote = `query GetNote($id: ID!) {
  getNote(id: $id) {
    id
    cognitoId
    chId
    ownerAWS
    version
    subject
    category
    note
    status
    dateTimeStamp
    createdAt
    updatedAt
  }
}
`;
export const listNotes = `query ListNotes(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      ownerAWS
      version
      subject
      category
      note
      status
      dateTimeStamp
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    enabled
    userStatus
    nickname
    firstName
    lastName
    groups
    phone
    email
    birthdate
    registered
    tandCs
    street
    street2
    city
    state
    code
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      enabled
      userStatus
      nickname
      firstName
      lastName
      groups
      phone
      email
      birthdate
      registered
      tandCs
      street
      street2
      city
      state
      code
      country
      geoLat
      geoLng
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getPreferences = `query GetPreferences($id: ID!) {
  getPreferences(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    unitWeight
    unitHeight
    unitFluid
    unitAltitude
    unitTemperature
    createdAt
    updatedAt
  }
}
`;
export const listPreferencess = `query ListPreferencess(
  $filter: ModelPreferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  listPreferencess(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      unitWeight
      unitHeight
      unitFluid
      unitAltitude
      unitTemperature
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getBloodPressurePulse = `query GetBloodPressurePulse($id: ID!) {
  getBloodPressurePulse(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    systolic
    diastolic
    pulse
    bodyPosition
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const listBloodPressurePulses = `query ListBloodPressurePulses(
  $filter: ModelBloodPressurePulseFilterInput
  $limit: Int
  $nextToken: String
) {
  listBloodPressurePulses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      systolic
      diastolic
      pulse
      bodyPosition
      whenEvent
      eventWhen
      activityWhen
      ingestion
      caffeine
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getGlucose = `query GetGlucose($id: ID!) {
  getGlucose(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    glucose
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    ingestion
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const listGlucoses = `query ListGlucoses(
  $filter: ModelGlucoseFilterInput
  $limit: Int
  $nextToken: String
) {
  listGlucoses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      glucose
      location
      locationColor
      bodyTempFeel
      whenEvent
      eventWhen
      activityWhen
      ingestion
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getHeight = `query GetHeight($id: ID!) {
  getHeight(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    height
    unit
    shoes
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const listHeights = `query ListHeights(
  $filter: ModelHeightFilterInput
  $limit: Int
  $nextToken: String
) {
  listHeights(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      height
      unit
      shoes
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getOxygenation = `query GetOxygenation($id: ID!) {
  getOxygenation(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    oxygenation
    altitude
    altitudeUnit
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const listOxygenations = `query ListOxygenations(
  $filter: ModelOxygenationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOxygenations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      oxygenation
      altitude
      altitudeUnit
      location
      locationColor
      bodyTempFeel
      whenEvent
      eventWhen
      activityWhen
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getRespiration = `query GetRespiration($id: ID!) {
  getRespiration(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    respiration
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const listRespirations = `query ListRespirations(
  $filter: ModelRespirationFilterInput
  $limit: Int
  $nextToken: String
) {
  listRespirations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      respiration
      whenEvent
      eventWhen
      activityWhen
      ingestion
      caffeine
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getTemperature = `query GetTemperature($id: ID!) {
  getTemperature(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    temperature
    ambient
    unit
    location
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const listTemperatures = `query ListTemperatures(
  $filter: ModelTemperatureFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemperatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      temperature
      ambient
      unit
      location
      whenEvent
      eventWhen
      activityWhen
      ingestion
      caffeine
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getWeight = `query GetWeight($id: ID!) {
  getWeight(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    weight
    targetWeight
    unit
    whenEvent
    eventWhen
    clothed
    performedBy
    status
    createdAt
    updatedAt
  }
}
`;
export const listWeights = `query ListWeights(
  $filter: ModelWeightFilterInput
  $limit: Int
  $nextToken: String
) {
  listWeights(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      weight
      targetWeight
      unit
      whenEvent
      eventWhen
      clothed
      performedBy
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getCartedService = `query GetCartedService($id: ID!) {
  getCartedService(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceTerm
    servicePrice
    createdAt
    updatedAt
  }
}
`;
export const listCartedServices = `query ListCartedServices(
  $filter: ModelCartedServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listCartedServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      serviceName
      servicePlan
      serviceTerm
      servicePrice
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getSubscriptions = `query GetSubscriptions($id: ID!) {
  getSubscriptions(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceStartDate
    serviceTerm
    paymentPeriod
    servicePrice
    stripeId
    stripeCreated
    stripeStatus
    stripeMessageId
    createdAt
    updatedAt
  }
}
`;
export const listSubscriptionss = `query ListSubscriptionss(
  $filter: ModelSubscriptionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubscriptionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      serviceName
      servicePlan
      serviceStartDate
      serviceTerm
      paymentPeriod
      servicePrice
      stripeId
      stripeCreated
      stripeStatus
      stripeMessageId
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getTarget = `query GetTarget($id: ID!) {
  getTarget(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    targetWeight
    targetSystolic
    targetDiastolic
    targetPulse
    targetHydration
    targetGlucose
    createdAt
    updatedAt
  }
}
`;
export const listTargets = `query ListTargets(
  $filter: ModelTargetFilterInput
  $limit: Int
  $nextToken: String
) {
  listTargets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      targetWeight
      targetSystolic
      targetDiastolic
      targetPulse
      targetHydration
      targetGlucose
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getTask = `query GetTask($id: ID!) {
  getTask(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    title
    description
    status
    deadline
    createdAt
    updatedAt
  }
}
`;
export const listTasks = `query ListTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      title
      description
      status
      deadline
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getNaturalLanguage = `query GetNaturalLanguage($id: ID!) {
  getNaturalLanguage(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    language
    description
    status
    createdAt
    updatedAt
  }
}
`;
export const listNaturalLanguages = `query ListNaturalLanguages(
  $filter: ModelNaturalLanguageFilterInput
  $limit: Int
  $nextToken: String
) {
  listNaturalLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      language
      description
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchNotes = `query SearchNotes(
  $filter: SearchableNoteFilterInput
  $sort: SearchableNoteSortInput
  $limit: Int
  $nextToken: Int
) {
  searchNotes(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      ownerAWS
      version
      subject
      category
      note
      status
      dateTimeStamp
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchUsers = `query SearchUsers(
  $filter: SearchableUserFilterInput
  $sort: SearchableUserSortInput
  $limit: Int
  $nextToken: Int
) {
  searchUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      enabled
      userStatus
      nickname
      firstName
      lastName
      groups
      phone
      email
      birthdate
      registered
      tandCs
      street
      street2
      city
      state
      code
      country
      geoLat
      geoLng
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchPreferencess = `query SearchPreferencess(
  $filter: SearchablePreferencesFilterInput
  $sort: SearchablePreferencesSortInput
  $limit: Int
  $nextToken: Int
) {
  searchPreferencess(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      unitWeight
      unitHeight
      unitFluid
      unitAltitude
      unitTemperature
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchBloodPressurePulses = `query SearchBloodPressurePulses(
  $filter: SearchableBloodPressurePulseFilterInput
  $sort: SearchableBloodPressurePulseSortInput
  $limit: Int
  $nextToken: Int
) {
  searchBloodPressurePulses(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      systolic
      diastolic
      pulse
      bodyPosition
      whenEvent
      eventWhen
      activityWhen
      ingestion
      caffeine
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchGlucoses = `query SearchGlucoses(
  $filter: SearchableGlucoseFilterInput
  $sort: SearchableGlucoseSortInput
  $limit: Int
  $nextToken: Int
) {
  searchGlucoses(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      glucose
      location
      locationColor
      bodyTempFeel
      whenEvent
      eventWhen
      activityWhen
      ingestion
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchHeights = `query SearchHeights(
  $filter: SearchableHeightFilterInput
  $sort: SearchableHeightSortInput
  $limit: Int
  $nextToken: Int
) {
  searchHeights(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      height
      unit
      shoes
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchOxygenations = `query SearchOxygenations(
  $filter: SearchableOxygenationFilterInput
  $sort: SearchableOxygenationSortInput
  $limit: Int
  $nextToken: Int
) {
  searchOxygenations(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      oxygenation
      altitude
      altitudeUnit
      location
      locationColor
      bodyTempFeel
      whenEvent
      eventWhen
      activityWhen
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchRespirations = `query SearchRespirations(
  $filter: SearchableRespirationFilterInput
  $sort: SearchableRespirationSortInput
  $limit: Int
  $nextToken: Int
) {
  searchRespirations(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      respiration
      whenEvent
      eventWhen
      activityWhen
      ingestion
      caffeine
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchTemperatures = `query SearchTemperatures(
  $filter: SearchableTemperatureFilterInput
  $sort: SearchableTemperatureSortInput
  $limit: Int
  $nextToken: Int
) {
  searchTemperatures(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      temperature
      ambient
      unit
      location
      whenEvent
      eventWhen
      activityWhen
      ingestion
      caffeine
      performedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchWeights = `query SearchWeights(
  $filter: SearchableWeightFilterInput
  $sort: SearchableWeightSortInput
  $limit: Int
  $nextToken: Int
) {
  searchWeights(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      dateTimeStamp
      weight
      targetWeight
      unit
      whenEvent
      eventWhen
      clothed
      performedBy
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchCartedServices = `query SearchCartedServices(
  $filter: SearchableCartedServiceFilterInput
  $sort: SearchableCartedServiceSortInput
  $limit: Int
  $nextToken: Int
) {
  searchCartedServices(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      serviceName
      servicePlan
      serviceTerm
      servicePrice
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchSubscriptionss = `query SearchSubscriptionss(
  $filter: SearchableSubscriptionsFilterInput
  $sort: SearchableSubscriptionsSortInput
  $limit: Int
  $nextToken: Int
) {
  searchSubscriptionss(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      serviceName
      servicePlan
      serviceStartDate
      serviceTerm
      paymentPeriod
      servicePrice
      stripeId
      stripeCreated
      stripeStatus
      stripeMessageId
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchTargets = `query SearchTargets(
  $filter: SearchableTargetFilterInput
  $sort: SearchableTargetSortInput
  $limit: Int
  $nextToken: Int
) {
  searchTargets(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      targetWeight
      targetSystolic
      targetDiastolic
      targetPulse
      targetHydration
      targetGlucose
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchTasks = `query SearchTasks(
  $filter: SearchableTaskFilterInput
  $sort: SearchableTaskSortInput
  $limit: Int
  $nextToken: Int
) {
  searchTasks(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      title
      description
      status
      deadline
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const searchNaturalLanguages = `query SearchNaturalLanguages(
  $filter: SearchableNaturalLanguageFilterInput
  $sort: SearchableNaturalLanguageSortInput
  $limit: Int
  $nextToken: Int
) {
  searchNaturalLanguages(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      version
      language
      description
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
