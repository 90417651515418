import React, { useState } from 'react'
// import { API, graphqlOperation } from 'aws-amplify'
// import { Context } from '../../state/Context'

import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBIcon
} from "mdbreact";

const Targets = () => {
    // const { state, dispatch } = useContext( Context )
    // console.log('>>>-Preferences-state->')

    const [ weight, setWeight ] = useState('')
    const [ unitWeight, setUnitWeight ] = useState('')
    const [ systolic, setSystolic ] = useState('')
    const [ diastolic, setDiastolic ] = useState('')
    const [ pulse, setPulse ] = useState('')
    const [ glucose, setGlucose ] = useState('')
    const [ hydration ,setHydration ] = useState('')
    const [ unitFluid, setUnitFluid ] = useState('')
    const [ isSubmitting, setIsSubmitting ] = useState(false)

    const handleTargets = (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        console.log('>>>-Targets-handleTargets-{weight,systolic,diastolic,pulse}->',
        {weight,systolic,diastolic,pulse}
        )

        setIsSubmitting(false)    
    }

    const handleClearAll = (event) => {
        event.preventDefault()
        setWeight('')
        setSystolic('')
        setDiastolic('')
        setPulse('')
    }


    return (
        <MDBContainer className="mt-5">
        <form onSubmit={handleTargets} >
        <MDBRow>
        <MDBCol>
            <h3>Prescribed or Recommended Targets</h3>
            <hr />
            <h5>Weight</h5>
            <MDBInput
                    label="Target Weight"
                    name="weight"
                    type="text"
                    style={{size: "4"}}
                    value={weight}
                    onChange={e => setWeight(e.target.value)}
            />
            <MDBRow>
                <MDBInput 
                    onClick={e => setWeight(e.target.id)} 
                    checked={unitWeight === 'kilograms' ? true : false} 
                    label="Kilograms" 
                    type="radio"
                    id="kilograms" 
                />
                <MDBInput 
                    onClick={e => setUnitWeight(e.target.id)} 
                    checked={unitWeight === 'pounds' ? true : false} 
                    label="Pounds" 
                    type="radio"
                    id="pounds" 
                />
            </MDBRow>
            <hr />
            <h5>Blood Pressure Pulse</h5>
            <MDBRow>
                <MDBInput
                    label="Target Systolic"
                    name="systolic"
                    type="text"
                    style={{size: "4"}}
                    value={systolic}
                    onChange={e => setSystolic(e.target.value)}
                />
                <MDBInput
                    label="Target Diastolic"
                    name="diastolic"
                    type="text"
                    style={{size: "4"}}
                    value={diastolic}
                    onChange={e => setDiastolic(e.target.value)}
                />
                <MDBInput
                    label="Target Pulse"
                    name="pulse"
                    type="text"
                    style={{size: "4"}}
                    value={pulse}
                    onChange={e => setPulse(e.target.value)}
                />
            </MDBRow>
            <hr />
            <h5>Hydration</h5>
            <MDBInput
                    label="Target Fluids per Day"
                    name="hydration"
                    type="text"
                    style={{size: "4"}}
                    value={hydration}
                    onChange={e => setHydration(e.target.value)}
            />
            <MDBRow>
                <MDBInput 
                    onClick={e => setUnitFluid(e.target.id)} 
                    checked={unitFluid === 'liters' ? true : false} 
                    label="Liters" 
                    type="radio"
                    id="liters" 
                />
                <MDBInput 
                    onClick={e => setUnitFluid(e.target.id)} 
                    checked={unitFluid === 'quarts' ? true : false} 
                    label="Quarts" 
                    type="radio"
                    id="quarts" 
                />
            </MDBRow>
            <hr />
            <h5>Glucose</h5>
            <MDBInput
                label="Target  Glucose"
                name="glucose"
                type="text"
                style={{size: "4"}}
                value={glucose}
                onChange={e => setGlucose(e.target.value)}
            />
            
            <div className="text-center mt-4">
                <MDBBtn color="primary" type="submit" >
                    {isSubmitting 
                        ? (<MDBIcon icon="spinner" pulse size="1x" fixed />) 
                        : ('Update')
                    }
                </MDBBtn>
                <MDBBtn color="secondary" onClick={handleClearAll}>
                    Clear All
                </MDBBtn>
            </div>
        </MDBCol>
        </MDBRow>
        </form>
        </MDBContainer>
    )
}

export default Targets