// src/state/Context.js

import React, { createContext, useReducer, useEffect } from 'react';
import { Reducer } from './Reducer';

export const Context = createContext();
export const state = {}

export const initialState = {
    userIsKnown: false,
    userIsAuthenticated: false,  
    userIsSubcribed: false,
    userForgotPassword: false,
    userChangeEmail: false,
    userUnreadComms: 0,
    userDaysToExpiry: 0,
    editHomeReading: {name: 'BloodPressurePulse'} 
}

export const ContextProvider = (props) => {
    const [state, dispatch] = useReducer(Reducer, {}, () => {
        const localData = localStorage.getItem('state');
        // console.log('>>>-Context-ContextProvider-localData->',localData)
        return localData ? JSON.parse(localData) : [];
    });

    useEffect(() => {
        localStorage.setItem('state', JSON.stringify(state));
    }, [state]);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {props.children}
        </Context.Provider>
    );
  }

