// src/Account/Subscription.js

import React, { useContext } from 'react'
// import { Link } from 'react-router-dom'
import { format as formatFNS } from 'date-fns'
import addMonths from 'date-fns/add_months'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'

import { Context } from '../../state/Context'

import { 
    MDBRow, 
    MDBCol, 
    MDBCard, 
    MDBCardBody, 
    MDBTable, 
    MDBTableBody, 
    MDBTableHead,
    MDBBtn } from "mdbreact";

// import { createSubscriptions } from '../../graphql/mutations'


const Subscription = () => {
    const { state } = useContext( Context )
    // console.log('>>>-Subscription-state.userSubscriptions->',state.userSubscriptions[0])

    function payMultipule(pp) {
        if (pp === 'month') {
            return 1
        } else if (pp === 'quarter') {
            return 3
        } else if (pp === 'year') {
            return 12
        } else {
            return 1
        }
    }
    const dtStart = formatFNS( new Date(state.userSubscriptions[0].serviceStartDate), 'YYYY-MM-DD')
    const dtEnd = formatFNS( 
        addMonths(
            new Date(state.userSubscriptions[0].serviceStartDate), 
            12
        ), 
        'YYYY-MM-DD'
    )
    const nextPayDate = differenceInCalendarDays(
        addMonths(new Date(
            state.userSubscriptions[0].serviceStartDate), 
            payMultipule(state.userSubscriptions[0].paymentPeriod)
        ),
        new Date()
    )


    
    const handleReSubscription = () => {
        console.log('>>>-Subscription-handleReSubscription->')
    }

    return (
        <section className="text-center my-5">

            <h2 className="h1-responsive font-weight-bold text-center my-5">
                Your Current Subscription and History
            </h2>
            <MDBRow>
                <MDBCol lg="12" className="mb-lg-0 mb-4">
                    <MDBCard>
                        <MDBCardBody className="striped mb-1">
                            <h3>Current Subscription</h3>
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        <th>Subscriptions</th>
                                        <th>Service level</th>
                                        <th>Service term</th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Amount</th>
                                        <th>Pay per</th>
                                        <th>Next charge date</th>
                                        <th>Days remaining</th>
                                        <th>Re-subscribe</th>
                                        <th>Data available</th>
                                        <th>Activity</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    <tr>
                                        <td>{state.userSubscriptions[0].serviceName}</td>
                                        <td>{state.userSubscriptions[0].servicePlan}</td>
                                        <td>{state.userSubscriptions[0].serviceTerm}</td>
                                        <td>{dtStart}</td>
                                        <td>{dtEnd}</td>
                                        <td>${state.userSubscriptions[0].servicePrice}</td>
                                        <td>{state.userSubscriptions[0].paymentPeriod}</td>
                                        <td>{nextPayDate}</td>
                                        <td>{nextPayDate}</td>
                                        <td>
                                            <MDBBtn 
                                                color="primary"
                                                size="sm"
                                                onClick={handleReSubscription}
                                            >
                                                Renew now
                                            </MDBBtn>
                                        </td>
                                        <td>45%</td>
                                        <td>125 adds</td>
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>
                            <h3>Subscription History</h3>
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        <th>Subscription</th>
                                        <th>Amount</th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Adds</th>
                                        <th>Edits</th>
                                        <th>Deletes</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {/* <tr>
                                        <td>Tracker</td>
                                        <td>$30.00</td>
                                        <td>2019-06-01</td>
                                        <td>2019-07-01</td>
                                        <td>203</td>
                                        <td>23</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td>Tracker</td>
                                        <td>$30.00</td>
                                        <td>2019-05-01</td>
                                        <td>2019-06-01</td>
                                        <td>153</td>
                                        <td>15</td>
                                        <td>3</td>
                                    </tr> */}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

        </section>
    )
}

export default Subscription