// src/pages/dashboard/CurrentReadingsTable.js

import React, { useState, useEffect } from 'react';
import { 
    MDBTable, 
    MDBTableBody, 
    MDBTableHead,
    MDBBtn,
    NavLink } from 'mdbreact';

import { currentReadingsManager } from '../../utilities/datatableLists'
import currentConditions from '../../utilities/currentConditions.json'

// figure our when to compute current readings and when to update
// load from state all current readings
// when adding (or editing/deleteing???) update state of current readings

const CurrentReadingsTable = props => {

    const [ dataTableColumns, setDataTableColumns ] = useState([])

    // console.log('>>>-CurrentReadingsTable-currentConditions->',currentConditions)

    useEffect( () => { setDataTableColumns(makeColHeaders(currentReadingsManager)) }, [])

// make the table column headers 
    const makeColHeaders = (currentReadingsManager) => {
        const colHeaders = currentReadingsManager.cols.filter(function(col) {
            return (
                col.display === 'user'
            )
        })
        return colHeaders
    }

// make the table rows
    const dataCC = currentConditions.map(cc => ({
        'reading': (
            <NavLink to="/vitals-home" style={{ spacing: 0, margin: 0, padding: 0 }}>
                <MDBBtn
                    color="blue"
                    size='sm'
                >
                    {cc.Reading}
                </MDBBtn>
            </NavLink>
        ),
        'last': cc.Last,
        'unit': cc.Unit,
        'dateTime': cc.Timestamp,
        'observations': cc.Observations,
        'days': cc.Days,
        'firstTimestamp': cc.FirstTimestamp,
        'lastTimestamp': cc.LastTimestamp,
        'min': cc.Min,
        'Q10': cc.Q10,
        'Q25': cc.Q25,
        'Q50': cc.Q50,
        'Q75': cc.Q75,
        'Q90': cc.Q90,
        'max': cc.Max,
    })
    )

    // console.log('>>>-CurrentReadingsTable-dataTableColumns->',dataTableColumns)
    // console.log('>>>-CurrentReadingsTable-dataCC->',dataCC)

    return (
    <MDBTable hover small responsive >
        <MDBTableHead 
            color="primary-color" 
            textWhite
            columns={dataTableColumns}
        />
        <MDBTableBody rows={dataCC} />
    </MDBTable>
    );
}

export default CurrentReadingsTable;
