// src/pages/VitalsHomeReadings/EnterTemperature.js

import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../state/Context'
import { API, graphqlOperation } from 'aws-amplify'
import { format as formatFNS, getHours, getMinutes } from 'date-fns'

import { vitalsChoiceLists } from '../../utilities/choiceLists'
import { validations } from "../../utilities/validations.js";

import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBSelect,
    MDBDatePicker,
    MDBTimePicker
} from "mdbreact";

import { createTemperature, updateTemperature } from '../../graphql/mutations'


const EnterTemperature = ({ homeReadingSelected }) => {
    const { state, dispatch } = useContext( Context )
    //console.log(">>>-EnterBloodPressurePulse-state.userPreferences->",state.userPreferences)
    //console.log(">>>-EnterBloodPressurePulse-state.editHomeReading->",state.editHomeReading)

    const [ temperature, setTemperature ] = useState('')
    const [ ambient, setAmbient ] = useState('')
    const [ unit, setUnit ] = useState('')

    const [ pickedTime, setPickedTime ] = useState(new Date())
    const [ pickedDate, setPickedDate ] = useState(new Date())

    const [ location, setLocation ] = useState(null)
    const [ when, setWhen ] = useState(null)
    const [ recentEvent, setRecentEvent ] = useState(null)
    const [ activity, setActivity ] = useState(null)
    const [ ingestion, setIngestion ] = useState(null)
    const [ caffeine, setCaffeine ] = useState(null)
    const [ performedBy, setPerformedBy ] = useState(null)

    const [ isEditing, setIsEditing ] = useState(false)
    const [ isSubmitting, setIsSubmitting ] = useState(false)

    useEffect(() => {
        if(state.editHomeReading.name === 'Temperature') {
            setIsEditing(true)
            setTemperature(state.editHomeReading.reading.temperature)
            setAmbient(state.editHomeReading.reading.ambient)
            setUnit(state.editHomeReading.reading.unit)
            setPickedTime(state.editHomeReading.reading.dateTimeStamp)
            setPickedDate(state.editHomeReading.reading.dateTimeStamp)
            setLocation(state.editHomeReading.reading.location)
            setWhen(state.editHomeReading.reading.whenEvent)
            setRecentEvent(state.editHomeReading.reading.eventWhen)
            setActivity(state.editHomeReading.reading.activityWhen)
            setIngestion(state.editHomeReading.reading.ingestion)
            setCaffeine(state.editHomeReading.reading.caffeine)
            setPerformedBy(state.editHomeReading.reading.performedBy)
        } else {
            setIsEditing(false)
            return
        }
    }, [state.editHomeReading])

    const handleAddRow = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        // console.log(">>>-EnterTemperature-handleAddRow-pickedDate->",pickedDate)
        // console.log(">>>-EnterTemperature-handleAddRow-pickedTime->",pickedTime)
        const input = {
            cognitoId: state.userDetails.id,
            chId: 'chId-test-1',
            ownerAWS: state.userDetails.ownerAWS,
            temperature: temperature,
            ambient: ambient,
            unit: unit,
            dateTimeStamp: formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + (
                pickedTime.length === 5 ? (
                    pickedTime
                ) : (
                    getHours(pickedTime) + ':' + getMinutes(pickedTime)
                )
            ),
            // dateTimeStamp: formatFNS( pickedDate, 'YYYY-MM-DD' ) + 'T' + pickedTime,
            
            location: location,
            whenEvent: when,
            eventWhen: recentEvent,
            activityWhen: activity,
            ingestion: ingestion,
            caffeine: caffeine,
            performedBy: performedBy
        }
        //console.log(">>>-EnterTemperature-handleAddRow-input->", input)
        await API.graphql(graphqlOperation(createTemperature,  { input }))

        setTemperature('')
        setAmbient('')
        setUnit('')
        setPickedDate(new Date())
        setPickedTime(new Date())
        setLocation(null)
        setWhen(null)
        setRecentEvent(null)
        setActivity(null)
        setIngestion(null)
        setCaffeine(null)
        setPerformedBy(null)
        
        setIsEditing(false)
        setIsSubmitting(false)
    }

    const handleCancel = () => {
        dispatch({ type: 'EDIT_HOME_READING', payload: {name: '', reading: {} } })

        setTemperature('')
        setAmbient('')
        setUnit('')
        setPickedDate(new Date())
        setPickedTime(new Date())
        setLocation(null)
        setWhen(null)
        setRecentEvent(null)
        setActivity(null)
        setIngestion(null)
        setCaffeine(null)
        setPerformedBy(null)
        
        setIsEditing(false)
        setIsSubmitting(false)
    }

    const handleEditRow = async (event) => {
        event.preventDefault()
        setIsEditing(true)
        setIsSubmitting(true)

        const input = {
            id: state.editHomeReading.reading.id,
            expectedVersion: state.editHomeReading.reading.version,
            temperature: temperature,
            ambient: ambient,
            unit: unit,
            dateTimeStamp: formatFNS(pickedDate, 'YYYY-MM-DD') + 'T' + (
                pickedTime.length === 5 ? (
                    pickedTime
                ) : (
                    getHours(pickedTime) + ':' + getMinutes(pickedTime)
                )
            ),
            // dateTimeStamp: formatFNS( pickedDate, 'YYYY-MM-DD' ) + 'T' + pickedTime,
            
            location: location,
            whenEvent: when,
            eventWhen: recentEvent,
            activityWhen: activity,
            ingestion: ingestion,
            caffeine: caffeine,
            performedBy: performedBy
        }
        //console.log(">>>-EnterTemperature-handleEditRow-input->",input)
        await API.graphql(graphqlOperation(updateTemperature,  { input }))

        dispatch({ type: 'EDIT_HOME_READING', payload: {name: '', reading: {} } })

        setTemperature('')
        setAmbient('')
        setUnit('')
        setPickedDate(new Date())
        setPickedTime(new Date())
        setLocation(null)
        setWhen(null)
        setRecentEvent(null)
        setActivity(null)
        setIngestion(null)
        setCaffeine(null)
        setPerformedBy(null)
        
        setIsEditing(false)
        setIsSubmitting(false)
    }

    return (
        <MDBContainer>
        <form onSubmit={isEditing ? handleEditRow : handleAddRow} >

            <MDBRow center style={{height: 30}}>
                <MDBCol>
                    <h4>Enter Data for {homeReadingSelected}</h4>
                </MDBCol>
            </MDBRow>   
            <MDBRow center style={{height: 80}}>
                <div className="w-25 p-0">
                    <MDBInput
                        label="Temperature"
                        type="text"
                        outline
                        required
                        value={temperature}
                        onChange={e => setTemperature(e.target.value)}
                        className={temperature && temperature.match(validations.TEMPERATURE)  
                        ? "is-valid" : "is-invalid"}
                    >
                        <div className="valid-feedback">ok</div>
                        <div className="invalid-feedback">Between 90 & 109 F</div>
                    </MDBInput>
                </div>
                <div className="w-25 p-0">
                    <MDBInput
                        label="Ambient"
                        type="text"
                        outline
                        required
                        value={ambient}
                        onChange={e => setAmbient(e.target.value)}
                        className={ambient && ambient.match(validations.AMBIENT)  
                        ? "is-valid" : "is-invalid"}
                    >
                        <div className="valid-feedback">ok</div>
                        <div className="invalid-feedback">Between -199 & 199 F</div>
                    </MDBInput>
                </div>

                <div className="w-25 p-0">  
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Unit</label>
                    <MDBSelect
                        options={vitalsChoiceLists.unitsTemperature.options}
                        outline
                        color="primary"
                        selected={unit}
                        getValue={e => setUnit(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>

            </MDBRow>
            <MDBRow center style={{height: 30}}>
                <div className="w-25 p-0">
                    <MDBDatePicker 
                        cancelLabel='Cancel'
                        clearable={true}
                        clearLabel='Clear'
                        animateYearScrolling={true}
                        // autoOk={true}
                        disableFuture={true}
                        disableOpenOnEnter={true}
                        format='YYYY-MM-DD'
                        showTodayButton 
                        valueDefault={ formatFNS( pickedDate, 'YYYY-MM-DD' ) }
                        getValue={setPickedDate} 
                        value={pickedDate}
                        onChange={e => setPickedDate(e.target.value)}
                    />
                </div>
                <div className="w-25 p-0">
                    <MDBTimePicker 
                        id="timePicker"
                        label="24 hr"
                        // cancelable 
                        // cancelText="Cancel"
                        closeOnCancel
                        clearable
                        doneText="OK" 
                        hoursFormat={24}
                        placeholder={'24 hr format'}
                        // value={pickedTime}
                        // hours={ pickedTime.length === 5 ? pickedTime.slice(0,2) : getHours( pickedTime ) }
                        // minutes={ pickedTime.length === 5 ? pickedTime.slice(3,5) : getMinutes( pickedTime ) }
                        hours={ getHours( pickedTime ) }
                        minutes={ getMinutes( pickedTime ) }
                        getValue={ value => setPickedTime( value ) } 
                    />
                </div>
            </MDBRow>

                <br />
                <hr />    
                <p style={{height: 5}}>Circumstances (optional / recommended)</p>
            
            <MDBRow center style={{height: 60}}>
                
                <div className="w-25 p-0">  
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Body Location</label>
                    <MDBSelect
                        options={vitalsChoiceLists.locationTemperature.options}
                        outline
                        color="primary"
                        selected={location}
                        getValue={e => setLocation(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>When</label>
                    <MDBSelect
                        options={vitalsChoiceLists.when.options}
                        outline
                        color="primary"
                        selected={when}
                        getValue={e => setWhen(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Event</label>
                    <MDBSelect
                        options={vitalsChoiceLists.recentEvent.options}
                        outline
                        color="primary"
                        selected={recentEvent}
                        getValue={e => setRecentEvent(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Activity</label>
                    <MDBSelect
                        options={vitalsChoiceLists.recentActivity.options}
                        outline
                        color="primary"
                        selected={activity}
                        getValue={e => setActivity(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
            </MDBRow>
            <MDBRow center style={{height: 70}}>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Ingestion</label>
                    <MDBSelect
                        options={vitalsChoiceLists.recentIngestion.options}
                        outline
                        color="primary"
                        selected={ingestion}
                        getValue={e => setIngestion(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Caffeine</label>
                    <MDBSelect
                        options={vitalsChoiceLists.recentCaffeine.options}
                        outline
                        color="primary"
                        selected={caffeine}
                        getValue={e => setCaffeine(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
                <div className="w-25 p-0">
                    <label style={{fontSize: 12, margin: 0, spacing: 0, padding: 0}}>Performed By</label>
                    <MDBSelect
                        options={vitalsChoiceLists.performedBy.options}
                        outline
                        color="primary"
                        selected={performedBy}
                        getValue={e => setPerformedBy(e[0])}
                        style={{margin: 0, spacing: 0, padding: 0}}
                    />
                </div>
            </MDBRow>
            <MDBRow center style={{height: 40}}>
                <MDBBtn
                    color='primary'
                    className="mb-2"
                    size='sm'
                    type="submit"
                    disabled={ !temperature || !ambient || !unit || !pickedDate || !pickedTime }
                >
                {isEditing ? (
                    isSubmitting ? 'Saving Edit...' : 'Edit'
                ) : (
                    isSubmitting ? 'Adding...' : 'Add'
                )}
                </MDBBtn>
                <MDBBtn
                    color='secondary'
                    className="mb-2"
                    size='sm'
                    // type="submit"
                    onClick={handleCancel}
                >
                Cancel
                </MDBBtn>
            </MDBRow>
    </form>

    </MDBContainer>
    )
}

export default EnterTemperature