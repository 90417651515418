// src/pages/account/AccountDetails.js

import React, { useState, useEffect, useContext } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Context } from '../../state/Context'
// import { API, graphqlOperation } from 'aws-amplify'
// import { format as formatFNS, getHours, getMinutes } from 'date-fns'

import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    // MDBDatePicker,
    MDBIcon
} from "mdbreact";

import { getUser } from '../../graphql/queries'
import { updateUser } from '../../graphql/mutations'


// ------------------------------------------------------------
//
// to dos:
// subscription level needs to include image resolution quality
// free tier reMult = 1 in WL 
// ------------------------------------------------------------


const AccountDetails = () => {
    const { state, dispatch } = useContext( Context )
    //console.log('>>>-AccountDetails-state.userDetails.id->',state.userDetails.id)
    const [ userAsync, setUserAsync ] = useState({})
    
    const [ nickname, setNickname ] = useState('')
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ street, setStreet ] = useState('')
    const [ street2, setStreet2 ] = useState('')
    const [ city, setCity ] = useState('')
    const [ stateGeo, setStateGeo ] = useState('')
    const [ country, setCountry ] = useState('')
    const [ mailCode, setMailCode ] = useState('')
    const [ birthdate, setBirthdate ] = useState('')

    const [ isSubmitting, setIsSubmitting ] = useState(false)
    // const [ formErrors, setFormErrors ] = useState(null)

    useEffect(() => { getUserAsync() }, [])

    const getUserAsync = async () => {
        try {
            const id = state.userDetails.id
            const result = await API.graphql(graphqlOperation( getUser, {id} ))
            //console.log(">>>-AccountDetails-getUser-result.data.getUser->", result.data.getUser)
            setUserAsync(result.data.getUser)

            setNickname(result.data.getUser.nickname)
            setFirstName(result.data.getUser.firstName)
            setLastName(result.data.getUser.lastName)
            setPhone(result.data.getUser.phone)
            setStreet(result.data.getUser.street)
            setStreet2(result.data.getUser.street2)
            setCity(result.data.getUser.city)
            setStateGeo(result.data.getUser.state)
            setCountry(result.data.getUser.country)
            setMailCode(result.data.getUser.code)
            setBirthdate(result.data.getUser.birthdate)
        } catch (err) {
            console.log('error fetching getUser...', err)
        }
    }

// 
// 1- call Appsync User to get details into state
// 2- provide CRUD on user
// 

    const handleUpdateUserDetails = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        //console.log('>>>-AccountDetails-handleUpdateUserDetails-userAsync->',userAsync)
        const input = {
// DO NOT EDIT in the form 
            id: userAsync.id,
            expectedVersion: userAsync.version,
            cognitoID: userAsync.cognitoID,
            chId: userAsync.chId,
            usernameAWS: userAsync.usernameAWS,
            enabled: userAsync.enabled,
            userStatus: userAsync.userStatus,
            ownerAWS: userAsync.ownerAWS,
            email: userAsync.email,
            registered: userAsync.registered,
            tandCs: userAsync.tandCs,
// EDIT in the form 
            nickname: nickname,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            street: street,
            street2: street2,
            city: city,
            state: stateGeo,
            country: country,
            code: mailCode,
            birthdate: birthdate
        }
        //console.log(">>>-AccountDetails-handleUpdateUserDetails-input->",input)
        dispatch({ type: 'USER_EDIT_DETAILS', payload: input })
        await API.graphql(graphqlOperation(updateUser,  { input }))

        // getUserAsync()
        setIsSubmitting(false)
    }

    const handleReset = (event) => {
        event.preventDefault()
        getUserAsync()
    }

    return (
        <MDBContainer>
        <MDBRow >
            <MDBCol md="6">
            <form onSubmit={handleUpdateUserDetails}>
                
                <MDBInput
                    label='Nickname (optional)'
                    type='text'
                    // outline
                    icon="user-circle"
                    value={nickname === "true" ? '' : nickname}
                    onChange={e => setNickname(e.target.value.toString())}
                />

                <MDBInput
                    label="First Name"
                    type="text"
                    // outline
                    icon="user"
                    value={firstName === "true" ? '' : firstName}
                    onChange={e => setFirstName(e.target.value)}
                />
                <MDBInput
                    label="Last Name"
                    type="text"
                    // outline
                    icon="user"
                    value={lastName === "true" ? '' : lastName}
                    onChange={e => setLastName(e.target.value)}
                />
                <MDBInput
                    label="Phone"
                    type="text"
                    // outline
                    icon="phone"
                    value={phone === "true" ? '' : phone}
                    onChange={e => setPhone(e.target.value.toString())}
                />
                <MDBIcon icon='map' size='2x'/>
                <MDBInput
                    label="Street"
                    type="text"
                    // outline
                    value={street === "true" ? '' : street}
                    onChange={e => setStreet(e.target.value.toString())}
                />
                <MDBInput
                    label="Street 2 (optional)"
                    type="text"
                    // outline
                    value={street2 === "true" ? '' : street2}
                    onChange={e => setStreet2(e.target.value.toString())}
                />
                <MDBInput
                    label="City"
                    type="text"
                    // outline
                    value={city === "true" ? '' : city}
                    onChange={e => setCity(e.target.value)}
                />
                <MDBInput
                    label="State"
                    type="text"
                    // outline
                    value={stateGeo === "true" ? '' : stateGeo}
                    onChange={e => setStateGeo(e.target.value)}
                />
                <MDBInput
                    label="Country"
                    type="text"
                    // outline
                    value={country === "true" ? '' : country}
                    onChange={e => setCountry(e.target.value)}
                />
                <MDBInput
                    label="Post Code"
                    type="text"
                    // outline
                    value={mailCode === "true" ? '' : mailCode}
                    onChange={e => setMailCode(e.target.value.toString())}
                />
                <p>Date of Birth</p>
                <MDBInput
                    label="YYYY-MM-DD"
                    type="text"
                    icon="calendar-alt"
                    // outline
                    value={birthdate === "true" ? '' : birthdate}
                    onChange={e => setBirthdate(e.target.value.toString())}
                />
                
                <div className="text-center mt-4">
                <MDBBtn color="primary" type="submit" >
                    {isSubmitting 
                        ? (<MDBIcon icon="spinner" pulse size="1x" fixed />) 
                        : ('Update')
                    }
                </MDBBtn>
                <MDBBtn color="secondary" onClick={handleReset}>
                    Reset
                </MDBBtn>
                </div>
            </form>
            </MDBCol>

            <MDBCol md="6" style={{background: '#baffff'}}>
                <h5 style={{margin: 5}}>
                    What do we do with your details?
                </h5>
                <div style={{margin: 5, padding: 5, spacing: 5}}>
                    <p>
                        bla bla bla...
                    </p>
                </div>
            </MDBCol>

        </MDBRow>
        </MDBContainer>
    )
}

export default AccountDetails