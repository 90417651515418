// src/utilities/ChoiceLists.js


export const drawerHomeReadings = [
{
    "index": 1,
    "name": 'All Readings', 
    "icon": '',
    "path": "/vitals",
},
{
    "index": 2,
    "name": 'Blood Pressure Pulse', 
    "icon": '',
    "path": "/",
},
{
    "index": 3,
    "name": 'Glucose', 
    "icon": '',
    "path": "/",
},
{
    "index": 4,
    "name": 'Height', 
    "icon": '',
    "path": "/",
},
{
  "index": 5,
  "name": 'Oxygenation', 
  "icon": '',
  "path": "/",
},
{
  "index": 6,
  "name": 'Respiration', 
  "icon": '',
  "path": "/",
},
{
  "index": 7,
  "name": 'Temperature', 
  "icon": '',
  "path": "/",
},
{
  "index": 8,
  "name": 'Weight', 
  "icon": '',
  "path": "/",
},
]

export const drawerLabs = [
    {
        "index": 1,
        "name": 'All Labs', 
        "icon": '',
        "path": "/",
    },
    {
        "index": 2,
        "name": 'Basic Metabolic Panel', 
        "icon": '',
        "path": "/",
    },
    {
        "index": 3,
        "name": 'Comprehensive Metabolic Panel', 
        "icon": '',
        "path": "/",
    },
    {
      "index": 4,
      "name": 'Complete Blood Count (CBC)', 
      "icon": '',
      "path": "/",
    },
    {
        "index": 5,
        "name": 'Electrolyte Panel', 
        "icon": '',
        "path": "/",
    },
    {
        "index": 6,
        "name": 'Lipid Panel', 
        "icon": '',
        "path": "",
    },
    {
        "index": 7,
        "name": 'Renal Panel', 
        "icon": '',
        "path": "",
    },
    {
        "index": 8,
        "name": 'Thyroid Function Panel', 
        "icon": '',
        "path": "",
    },
]

export const drawerSupplies = [
  {
    "index": 1,
    "name": 'Prescriptions', 
    "icon": '',
    //<FontAwesomeIcon icon={PrescriptionIcon} />
    "path": "/",
},
{
    "index": 2,
    "name": 'Treatment Supplies', 
    "icon": '',
    //<FontAwesomeIcon icon={BandAidIcon} />
    "path": "/",
},
{
    "index": 3,
    "name": 'Treatment Equipment', 
    "icon": '',
    "path": "/",
},
]

export const drawerTreatments = [
    {
        "index": 1,
        "name": 'Treatment 1', 
        "icon": '',
        "path": "/",
    },
    {
        "index": 2,
        "name": 'Treatment 2', 
        "icon": '',
        "path": "",
    },
    
]

export const drawerAppointments = [
    {
        "index": 1,
        "name": 'Appointment 1', 
        "icon": '',
        "path": "/",
    },
    {
        "index": 2,
        "name": 'Appointment 2', 
        "icon": '',
        "path": "",
    },
    
]

export const drawerReminders = [
    {
        "index": 1,
        "name": 'Reminder 1', 
        "icon": '',
        "path": "/",
    },
    {
        "index": 2,
        "name": 'Reminder 2', 
        "icon": '',
        "path": "",
    },
    
]

export const drawerHealthEvents = [
    {
        "index": 1,
        "name": 'Health Event 1', 
        "icon": '',
        "path": "/",
    },
    {
        "index": 2,
        "name": 'Health Event 2', 
        "icon": '',
        "path": "",
    },
    
]

export const homeReadings = [
    'Blood Pressure Pulse',
    'Glucose',
    'Height',
    'Oxygenation',
    'Respiration',
    'Temperature',
    'Weight',
]

export const vitalsChoiceLists = {
    "yesNo": {"label": "Yes/No", "options": [
        {
            text: "yes",
            value: "yes"
        },
        {
            text: "no",
            value: "no"
        },
    ]},
    "when": {"label": "When", "options": [
        {
            text: "after",
            value: "after"
        },
        {
            text: "before",
            value: "before"
        },
        {
            text: "during",
            value: "during"
        },
        {
            text: "none",
            value: "none"
        }
    ]},
    "recentEvent": {
        "label": "Recent Event", "options": [
            {
                text: "breakfast",
                value: "breakfast"
            },
            {
                text: "commute",
                value: "commute"
            },
            {
                text: "dinner",
                value: "dinner"
            },
            {
                text: "exercise",
                value: "exercise"
            },
            {
                text: "heavy work",
                value: "heavy work"
            },
            {
                text: "light work",
                value: "light work"
            },
            {
                text: "lunch",
                value: "lunch"
            },
            {
                text: "moderate work",
                value: "moderate work"
            },
            {
                text: "none",
                value: "none"
            },
            {
                text: "office work",
                value: "office work"
            },
            {
                text: "sleep",
                value: "sleep"
            },
            {
                text: "treatment",
                value: "treatment"
            }
        ]},
    "recentActivity": {"label": "Recent Activity", "options": [
            {
                text: "cardio exercise",
                value: "cardio exercise"
            },
            {
                text: "still",
                value: "still"
            },
            {
                text: "none",
                value: "none"
            },
            {
                text: "vigorous exercise",
                value: "vigorous exercise"
            },
            {
                text: "walking",
                value: "walking"
            }
        ]},
    "recentIngestion": {"label": "Recent Ingestion", "options": [
            {
                text: "cold",
                value: "cold"
            },
            {
                text: "cool",
                value: "cool"
            },
            {
                text: "hot",
                value: "hot"
            },
            {
                text: "none",
                value: "none"
            },
            {
                text: "room temperature",
                value: "room temperature"
            },
            {
                text: "warm",
                value: "warm"
            }
        ]},
    "recentCaffeine": {"label": "Recent Caffeine", "options": [
            {
                text: "coffee",
                value: "coffee"
            },
            {
                text: "energy drink",
                value: "energy drink"
            },
            {
                text: "none",
                value: "none"
            },
            {
                text: "other",
                value: "other"
            },
            {
                text: "soda",
                value: "soda"
            },
            {
                text: "tea",
                value: "tea"
            }
        ]},
    "performedBy": {"label": "Performed By", "options": [
            {
                text: "clinic",
                value: "clinic"
            },
            {
                text: "ER",
                value: "ER"
            },
            {
                text: "home/self",
                value: "home/self"
            },
            {
                text: "hosptial",
                value: "hosptial"
            },
            {
                text: "ICU",
                value: "ICU"
            },
            {
                text: "in office",
                value: "in office"
            },
            {
                text: "other",
                value: "other"
            },
            {
                text: "Urgent care",
                value: "Urgent care"
            }
        ]},
    "unitsTemperature": {"label": "Unit", "options": [
            {
                text: "Celsius",
                value: "Celsius"
            },
            {
                text: "Fahrenheit",
                value: "Fahrenheit"
            }
        ]},
    "unitsWeight": {"label": "Unit", "options": [
            {
                text: "Kilograms",
                value: "Kilograms"
            },
            {
                text: "Pounds",
                value: "Pounds"
            }
        ]},
    "unitsHeight": {"label": "Unit", "options": [
            {
                text: "Centimeters",
                value: "Centimeters"
            },
            {
                text: "Inches",
                value: "Inches"
            }
        ]},
    "locationTemperature": {"label": "Location", "options": [
            {
                text: "ear",
                value: "ear"
            },
            {
                text: "finger",
                value: "finger"
            },
            {
                text: "forehead",
                value: "forehead"
            },
            {
                text: "mouth",
                value: "mouth"
            },
            {
                text: "rectum",
                value: "rectum"
            },
            {
                text: "underarm",
                value: "underarm"
            }
        ]},
    "locationGlucose": {"label": "Location", "options": [
            {
                text: "ear",
                value: "ear"
            },
            {
                text: "finger",
                value: "finger"
            },
            {
                text: "flush residue",
                value: "flush residue"
            },
            {
                text: "forearm",
                value: "forearm"
            },
            {
                text: "other",
                value: "other"
            },
            {
                text: "palm",
                value: "palm"
            },
            {
                text: "toe",
                value: "toe"
            }
        ]},
    "locationOxygenation": {"label": "Location", "options": [
        {
            text: "ear",
            value: "ear"
        },
        {
            text: "finger",
            value: "finger"
        },
        {
            text: "other",
            value: "other"
        },
        {
            text: "toe",
            value: "toe"
        }
    ]},
    "locationColor": {"label": "Location Color", "options": [
            {
                text: "blue",
                value: "blue"
            },
            {
                text: "normal",
                value: "normal"
            },
            {
                text: "white",
                value: "white"
            }
        ]},
    "bodyPosition": {"label": "Body Position", "options": [
            {
                text: "reclining",
                value: "reclining"
            },
            {
                text: "sitting",
                value: "sitting"
            },
            {
                text: "upright",
                value: "upright"
            }
        ]},
    "bodyTempFeel": {"label": "Body Temp Feel", "options": [
            {
                text: "cold",
                value: "cold"
            },
            {
                text: "comfortable",
                value: "comfortable"
            },
            {
                text: "cool",
                value: "cool"
            },
            {
                text: "hot",
                value: "hot"
            },
            {
                text: "warm",
                value: "warm"
            }
        ]}
}
