// src/pages/ContactUs.js

import React, { useState } from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBInput } from "mdbreact";

const ContactUs = () => {
    const [ yourName, setYourName ] = useState('')
    const [ yourEmail, setYourEmail ] = useState('')
    const [ yourSubject, setYourSubject ] = useState('')
    const [ yourMessage, setYourMessage ] = useState('')

    const handleSendEmail = (event) => {
        event.preventDefault()
        console.log(
            '>>>-ContactUs-handleSendEmail-{yourName,yourEmail,yourSubject,yourMessage}->',
            {yourName,yourEmail,yourSubject,yourMessage}
            )
        console.log('>>>-ContactUs-handleSendEmail-add an alert->')
    }

    const handleClear = () => {
        setYourName('')
        setYourEmail('')
        setYourSubject('')
        setYourMessage('')
    }

    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>
        <MDBCard 
            className="my-5 px-5 mx-auto teal lighten-5"
            style={{ fontWeight: 300, maxWidth: "98%" }}
        >
            <MDBCardBody style={{ paddingTop: 0 }}>

            <MDBContainer>
            <h2 className="h1-responsive font-weight-bold text-center my-5">
                Contact Us
            </h2>
            <h6 className="text-center w-responsive mx-auto pb-5">
                We want to here from you.
            </h6>
            <MDBRow>
                <MDBCol md="9" className="md-0 mb-5">
                <form onSubmit={handleSendEmail}>
                    <MDBRow>
                    <MDBCol md="6">
                        <div className="md-form mb-0">
                        <MDBInput 
                            type="text" 
                            outline
                            id="contact-name" 
                            label="Your name"
                            value={yourName}
                            onChange={e => setYourName(e.target.value)}
                        />
                        </div>
                    </MDBCol>
                    <MDBCol md="6">
                        <div className="md-form mb-0">
                        <MDBInput
                            type="text"
                            outline
                            id="contact-email"
                            label="Your email"
                            value={yourEmail}
                            onChange={e => setYourEmail(e.target.value)}
                        />
                        </div>
                    </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <MDBCol md="12">
                        <div className="md-form mb-0">
                        <MDBInput 
                            type="text" 
                            outline
                            id="contact-subject" 
                            label="Subject" 
                            value={yourSubject}
                            onChange={e => setYourSubject(e.target.value)}
                        />
                        </div>
                    </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <MDBCol md="12">
                        <div className="md-form mb-0">
                        <MDBInput
                            type="textarea"
                            outline
                            id="contact-message"
                            label="Your message"
                            value={yourMessage}
                            onChange={e => setYourMessage(e.target.value)}
                        />
                        </div>
                    </MDBCol>
                    </MDBRow>
                </form>
                <div className="text-center text-md-left">
                    <MDBBtn 
                        color="primary" 
                        size="md-2"
                        type="submit"
                        disabled={ !yourName || !yourEmail || !yourSubject || !yourMessage }
                    >
                    Send
                    </MDBBtn>
                    <MDBBtn 
                        color="secondary" 
                        size="md-2"
                        onClick={handleClear}
                    >
                    Cancel
                    </MDBBtn>
                </div>
                </MDBCol>
                <MDBCol md="3" className="text-center">
                <ul className="list-unstyled mb-0">
                    <li>
                    <MDBIcon icon="map-marker-alt" size="2x" className="blue-text" />
                    <p>Secaucus, NJ 07094, USA</p>
                    </li>
                    <li>
                    <MDBIcon icon="phone" size="2x" className="blue-text mt-4" />
                    <p>551-775-8393</p>
                    </li>
                    <li>
                    <MDBIcon icon="envelope" size="2x" className="blue-text mt-4" />
                    <p>contact@christyhealth.com</p>
                    </li>
                </ul>
                </MDBCol>
            </MDBRow>
            </MDBContainer>
            </MDBCardBody>
        </MDBCard>
        </main>
    );
}

export default ContactUs;