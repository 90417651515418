// src/utilities/datatableLists.js

export const  currentReadingsManager = {
    cols: [
        {label: 'Reading',      field: 'reading',   type: 'text',       sort: "asc", width: 80,     display: 'user'},
        {label: 'Last',         field: 'last',      type: 'text',       sort: "asc", width: 70,     display: 'user'},
        {label: 'Unit',         field: 'unit',      type: 'float',      sort: "asc", width: 70,     display: 'user'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 125,    display: 'user'},
        {label: 'Observations', field: 'observations',type: 'float',    sort: "asc", width: 70,     display: 'user'},
        {label: 'Days',         field: 'days',      type: 'text',       sort: "asc", width: 70,     display: 'user'},
        {label: 'First Timestamp',field: 'firstTimestamp',type: 'text', sort: "asc", width: 125,    display: 'user'},
        {label: 'Last Timestamp',field: 'lastTimestamp',type: 'text',   sort: "asc", width: 125,    display: 'user'},
        {label: 'Min',          field: 'min',       type: 'text',       sort: "asc", width: 70,     display: 'user'},
        {label: 'Q10',          field: 'Q10',       type: 'text',       sort: "asc", width: 70,     display: 'user'},
        {label: 'Q25',          field: 'Q25',       type: 'text',       sort: "asc", width: 70,     display: 'user'},
        {label: 'Q50',          field: 'Q50',       type: 'text',       sort: "asc", width: 70,     display: 'user'},
        {label: 'Q75',          field: 'Q75',       type: 'text',       sort: "asc", width: 70,     display: 'user'},
        {label: 'Q90',          field: 'Q90',       type: 'text',       sort: "asc", width: 70,     display: 'user'},
        {label: 'Max',          field: 'max',       type: 'text',       sort: "asc", width: 70,     display: 'user'},
    ],
}

export const notesManager = {
    cols: [
        {label: 'Action',       field: 'actions',   type: 'text',       sort: "asc", width: 80,     display: 'code'},
        {label: 'Subject',      field: 'subject',   type: 'text',       sort: "asc", width: 125,    display: 'user'},
        {label: 'Category',     field: 'category',  type: 'text',       sort: "asc", width: 125,    display: 'user'},
        {label: 'Note',         field: 'note',      type: 'text',       sort: "asc", width: 400,    display: 'user'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 125,    display: 'user'},
        {label: 'Status',       field: 'status',    type: 'text',       sort: "asc", width: 100,    display: 'ch'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'ch'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'ch'},
        {label: 'CH ID',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 150,    display: 'awd'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 150,    display: 'aws'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Version',      field: 'version',   type: 'integer',    sort: "asc", width: 100,    display: 'aws'},
    ]
}

export const userManager = {
    cols: [
        {label: 'User Name',    field: 'userName',  type: 'text',       sort: "asc", width: 150,    display: 'user'},
        {label: 'First Name',   field: 'firstName', type: 'text',       sort: "asc", width: 150,    display: 'user'},
        {label: 'Last Name',    field: 'lastName',  type: 'text',       sort: "asc", width: 150,    display: 'user'},
        {label: 'Phone',        field: 'phone',     type: 'text',       sort: "asc", width: 125,    display: 'user'},
        {label: 'Email',        field: 'email',     type: 'text',       sort: "asc", width: 125,    display: 'user'},
        {label: 'Birth Date',   field: 'birthDate', type: 'text',       sort: "asc", width: 125,    display: 'user'},
        {label: 'User Create Date',field: 'userCreateDate',type: 'text',sort: "asc", width: 100,    display: 'ch'},
        {label: 'User Last Modified Date',field: 'userLastModifiedDate',type: 'text',sort: "asc", width: 100,display: 'ch'},
        {label: 'Enabled',      field: 'enabled',  type: 'text',        sort: "asc", width: 75,     display: 'ch'},
        {label: 'User Status',  field: 'userStatus',  type: 'text',     sort: "asc", width: 75,     display: 'ch'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Registered',   field: 'registered',type: 'boolean',    sort: "asc", width:  50,    display: 'ch'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'CH Id',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Version',      field: 'version',   type: 'integer',    sort: "asc", width: 100,    display: 'aws'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 150,    display: 'aws'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 150,    display: 'aws'},
    ]
}

export const bloodPressurePulseManager = {
    cols: [
        {label: 'Action',       field: 'actions',   type: 'text',       sort: "asc", width: 80,     display: 'code'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 150,    display: 'user'},
        {label: 'Systolic',     field: 'systolic',  type: 'float',      sort: "asc", width: 80,    display: 'user'},
        {label: 'Diastolic',    field: 'diastolic', type: 'float',      sort: "asc", width: 80,    display: 'user'},
        {label: 'Pulse',        field: 'pulse',     type: 'float',      sort: "asc", width: 80,    display: 'user'},
        {label: 'Position',     field: 'bodyPosition',type: 'text',     sort: "asc", width: 100,    display: 'user'},
        {label: 'When',         field: 'whenEvent', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Event',        field: 'eventWhen', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Activity',     field: 'activityWhen',type: 'text',     sort: "asc", width: 100,    display: 'user'},
        {label: 'Ingestion',    field: 'ingestion', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Caffeine',     field: 'caffeine',  type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Performed by', field: 'performedBy',type: 'text',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'CH Id',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
    ]
}
export const  temperatureManager = {
    cols: [
        {label: 'Action',       field: 'actions',   type: 'text',       sort: "asc", width: 80,     display: 'code'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 150,    display: 'user'},
        {label: 'Temperature',  field: 'temperature',type: 'float',     sort: "asc", width: 80,     display: 'user'},
        {label: 'Ambient',      field: 'ambient',   type: 'float',      sort: "asc", width: 80,     display: 'user'},
        {label: 'Unit',         field: 'unit',      type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Location',     field: 'location',  type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'When',         field: 'whenEvent', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Event',        field: 'eventWhen', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Activity',     field: 'activityWhen',type: 'text',     sort: "asc", width: 100,    display: 'user'},
        {label: 'Ingestion',    field: 'ingestion', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Caffeine',     field: 'caffeine',  type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Performed by', field: 'performedBy',type: 'text',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'CH Id',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
    ],
}
export const  glucoseManager = {
    cols: [
        {label: 'Action',       field: 'actions',   type: 'text',       sort: "asc", width: 80,     display: 'code'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 150,    display: 'user'},
        {label: 'Glucose',      field: 'glucose',   type: 'float',      sort: "asc", width: 80,     display: 'user'},
        {label: 'Location',     field: 'location',  type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Color',        field: 'locationColor',type: 'text',    sort: "asc", width: 100,    display: 'user'},
        {label: 'Body Temp Feel',field: 'bodyTempFeel',type: 'text',    sort: "asc", width: 100,    display: 'user'},
        {label: 'When',         field: 'whenEvent', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Event',        field: 'eventWhen', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Activity',     field: 'activityWhen',type: 'text',     sort: "asc", width: 100,    display: 'user'},
        {label: 'Ingestion',    field: 'ingestion', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Performed by', field: 'performedBy',type: 'text',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'CH Id',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
    ],
}
export const  oxygenationManager = {
    cols: [
        {label: 'Action',       field: 'actions',   type: 'text',       sort: "asc", width: 80,     display: 'code'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 150,    display: 'user'},
        {label: 'Oxygenation',  field: 'oxygenation',type: 'float',     sort: "asc", width: 100,     display: 'user'},
        {label: 'Location',     field: 'location',  type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Color',        field: 'locationColor',type: 'text',    sort: "asc", width: 100,    display: 'user'},
        {label: 'Body Temp Feel',field: 'bodyTempFeel',type: 'text',    sort: "asc", width: 100,    display: 'user'},
        {label: 'When',         field: 'whenEvent', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Event',        field: 'eventWhen', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Activity',     field: 'activityWhen',type: 'text',     sort: "asc", width: 100,    display: 'user'},
        {label: 'Performed by', field: 'performedBy',type: 'text',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'CH Id',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
    ],
}
export const  respirationManager = {
    cols: [
        {label: 'Action',       field: 'actions',   type: 'text',       sort: "asc", width: 80,     display: 'code'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 150,    display: 'user'},
        {label: 'Respiration',  field: 'respiration',type: 'float',     sort: "asc", width: 80,     display: 'user'},
        {label: 'When',         field: 'whenEvent', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Event',        field: 'eventWhen', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Activity',     field: 'activityWhen',type: 'text',     sort: "asc", width: 100,    display: 'user'},
        {label: 'Ingestion',    field: 'ingestion', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Caffeine',     field: 'caffeine',  type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Performed by', field: 'performedBy',type: 'text',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'CH Id',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
    ],
}
export const  weightManager = {
    cols: [
        {label: 'Action',       field: 'actions',   type: 'text',       sort: "asc", width: 80,     display: 'code'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 150,    display: 'user'},
        {label: 'Weight',       field: 'weight',    type: 'float',      sort: "asc", width: 80,     display: 'user'},
        {label: 'Target',       field: 'targetWeight',type: 'float',    sort: "asc", width: 80,     display: 'user'},
        {label: 'Unit',         field: 'unit',      type: 'float',      sort: "asc", width: 80,     display: 'user'},
        {label: 'When',         field: 'whenEvent', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Event',        field: 'eventWhen', type: 'text',       sort: "asc", width: 100,    display: 'user'},
        {label: 'Clothed',      field: 'clothed',   type: 'float',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Performed by', field: 'performedBy',type: 'text',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'CH Id',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
    ],
}
export const  heightManager = {
    cols: [
        {label: 'Action',       field: 'actions',   type: 'text',       sort: "asc", width: 80,     display: 'code'},
        {label: 'Date Time',    field: 'dateTimeStamp',type: 'text',    sort: "asc", width: 150,    display: 'user'},
        {label: 'Height',       field: 'height',    type: 'float',      sort: "asc", width: 80,     display: 'user'},
        {label: 'Unit',         field: 'unit',      type: 'float',      sort: "asc", width: 80,     display: 'user'},
        {label: 'Shoes',        field: 'shoes',     type: 'float',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Performed by', field: 'performedBy',type: 'text',      sort: "asc", width: 100,    display: 'user'},
        {label: 'Id',           field: 'id',        type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Owner',        field: 'owner',     type: 'text',       sort: "asc", width: 100,    display: 'aws'},
        {label: 'Cognito Id',   field: 'cognitoId', type: 'text',       sort: "asc", width: 300,    display: 'aws'},
        {label: 'Created At',   field: 'createdAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'Updated At',   field: 'updatedAt', type: 'text',       sort: "asc", width: 200,    display: 'aws'},
        {label: 'CH Id',        field: 'chId',      type: 'text',       sort: "asc", width: 100,    display: 'ch'},
    ],
}