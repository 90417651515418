// src/pages/dashboard/CurrentReadingGauges.js

import React, { useState, useContext } from "react";
import { Context } from '../../state/Context'

import CurrentReadingCard from './CurrentReadingCard'

import { MDBRow } from "mdbreact";

import { wcBaseUrl, wcEndPoint } from "../../utilities/apiPaths"

const CurrentReadingGauges = () => {
    const { state } = useContext( Context )
    //console.log(">>>-CurrentReadingGauges-state->",state)

    const [ weight, setWeight ] = useState("195")
    const [ weightUnit, setWeightUnit ] = useState("Pounds")
    const [ weightDate, setWeightDate ] = useState("2019-03-15T14:05:52")


    return (
        <MDBRow>

            <CurrentReadingCard
                readingName={'Blood Pressure Pulse'}
                image={`${wcBaseUrl}${wcEndPoint}bpp?systolic=138&diastolic=91&pulse=82&date=2019-04-15T14:05:52`} 
            />
            <CurrentReadingCard
                readingName={'Temperature'}
                image={"https://www.wolframcloud.com/obj/mitch/ch/api/test/temp?temperature=98&unit=Fahrenheit&date=2019-04-15T14:05:52"} 
            />
            <CurrentReadingCard
                readingName={'Respiration'}
                image={"https://www.wolframcloud.com/obj/mitch/ch/api/test/resp?respiration=16&date=2019-04-15T14:05:52"} 
            />
            <CurrentReadingCard
                readingName={'Weight'}
                image={`${wcBaseUrl}${wcEndPoint}wght?weight=${weight}&unit=${weightUnit}&date=${weightDate}`} 
            />
            <CurrentReadingCard
                readingName={'Height'}
                image={"https://www.wolframcloud.com/obj/mitch/ch/api/test/hght?height=68&unit=Inches&date=2019-04-15T14:05:52"} 
            />
    
            <CurrentReadingCard
                readingName={'Oxygenation'}
                image={"https://www.wolframcloud.com/obj/mitch/ch/api/test/oxy?oxygenation=96&date=2019-04-15T14:05:52"} 
            />
            <CurrentReadingCard
                readingName={'Glucose'}
                image={"https://www.wolframcloud.com/obj/mitch/ch/api/test/glue?glucose=96&date=2019-04-15T14:05:52"} 
            />
    
        </MDBRow>
    )
}

export default CurrentReadingGauges
