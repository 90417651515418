// src/pages/services/Servies.js

import React from "react";
import ServicePlans from './ServicePlans'
import ServiceOfferings from './ServiceOfferings'
// import ServiceSubscriptions from './ServiceSubscriptions'

import { MDBCard, MDBCardBody, MDBRow } from "mdbreact";


const Servies = (props) => {

    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>
        <MDBCard 
            className="my-2 px-5 mx-auto teal lighten-5"
            style={{ fontWeight: 300, maxWidth: "98%" }}
        >
        <MDBCardBody style={{ paddingTop: 0 }}>
            {/* <MDBRow>
                <ServiceSubscriptions props={props} />
            </MDBRow> */}
            <MDBRow>
                <ServiceOfferings />
            </MDBRow>
            <hr/>
            <MDBRow>
                <ServicePlans />
            </MDBRow>
        </MDBCardBody>
        </MDBCard>

        </main>
    );
}

export default Servies;