// src/pages/providers/ProviderDetails/js

import React, { useState, useEffect, useContext } from "react";
import { Context } from '../../state/Context'
import { API, graphqlOperation } from 'aws-amplify'

import { subDays, addDays } from 'date-fns'

import { 
    MDBBtn, 
    MDBCard, 
    MDBCardBody, 
    MDBCardImage, 
    MDBCardTitle, 
    MDBCardText, 
    MDBRow, 
    MDBCol, 
    MDBIcon } from'mdbreact'

const ProviderDetails = () => {
    const { state, dispatch } = useContext( Context )
    console.log(">>>-DatatableWeight-state->",state)

    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>

            <MDBRow style={{margin: 10}}>
            <MDBCol style={{ maxWidth: "22rem" }}>
                <MDBCard narrow>
                <MDBCardImage className="view view-cascade gradient-card-header blue-gradient" cascade tag="div">
                    <h2 className="h2-responsive">Link to your health care providers</h2>
                    <div className="text-center">
                    <MDBBtn color="lightblue" floating size="sm">
                        <MDBIcon icon="download" size="3x" />
                    </MDBBtn>
                    </div>
                </MDBCardImage>
                <MDBCardBody cascade>
                    <MDBCardTitle>Search then connect</MDBCardTitle>
                    <MDBCardText>Search for your healthcare provider.  If they make your data availble, link to it!  We'll do the work of uploading, synchronizing and aggregating all or data sources.</MDBCardText>
                    <MDBRow center>
                        <MDBBtn href="#" color='primary' size="sm">Search</MDBBtn>
                        <MDBBtn href="#" color='primary' size="sm">Link</MDBBtn>
                    </MDBRow>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
            
            <MDBCol style={{ maxWidth: "22rem" }}>
                <MDBCard narrow>
                <MDBCardImage className="view view-cascade gradient-card-header blue-gradient" cascade tag="div">
                    <h2 className="h2-responsive">Have Medicare? Link your account.</h2>
                    <div className="text-center">
                    <MDBBtn color="lightblue" floating size="sm">
                        <MDBIcon icon="download" size="3x" />
                    </MDBBtn>
                    </div>
                </MDBCardImage>
                <MDBCardBody cascade>
                    <MDBCardTitle>Connect</MDBCardTitle>
                    <MDBCardText>Connect to your Medicare account.  We automatically keep your account up to date and notifiy you of changes to your account status and information.</MDBCardText>
                    <MDBRow center>
                        <MDBBtn href="#" color='primary' size="sm">Link</MDBBtn>
                    </MDBRow>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>

            </MDBRow>

            <MDBRow style={{margin: 10}}>
                <h5>A table of connections goes here...</h5>
            </MDBRow>

        </main>
    )
}

export default ProviderDetails