// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreateNote = `subscription OnCreateNote {
  onCreateNote {
    id
    cognitoId
    chId
    ownerAWS
    version
    subject
    category
    note
    status
    dateTimeStamp
    createdAt
    updatedAt
  }
}
`;
export const onUpdateNote = `subscription OnUpdateNote {
  onUpdateNote {
    id
    cognitoId
    chId
    ownerAWS
    version
    subject
    category
    note
    status
    dateTimeStamp
    createdAt
    updatedAt
  }
}
`;
export const onDeleteNote = `subscription OnDeleteNote {
  onDeleteNote {
    id
    cognitoId
    chId
    ownerAWS
    version
    subject
    category
    note
    status
    dateTimeStamp
    createdAt
    updatedAt
  }
}
`;
export const onCreateUser = `subscription OnCreateUser {
  onCreateUser {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    enabled
    userStatus
    nickname
    firstName
    lastName
    groups
    phone
    email
    birthdate
    registered
    tandCs
    street
    street2
    city
    state
    code
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser {
  onUpdateUser {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    enabled
    userStatus
    nickname
    firstName
    lastName
    groups
    phone
    email
    birthdate
    registered
    tandCs
    street
    street2
    city
    state
    code
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const onDeleteUser = `subscription OnDeleteUser {
  onDeleteUser {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    enabled
    userStatus
    nickname
    firstName
    lastName
    groups
    phone
    email
    birthdate
    registered
    tandCs
    street
    street2
    city
    state
    code
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const onCreatePreferences = `subscription OnCreatePreferences {
  onCreatePreferences {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    unitWeight
    unitHeight
    unitFluid
    unitAltitude
    unitTemperature
    createdAt
    updatedAt
  }
}
`;
export const onUpdatePreferences = `subscription OnUpdatePreferences {
  onUpdatePreferences {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    unitWeight
    unitHeight
    unitFluid
    unitAltitude
    unitTemperature
    createdAt
    updatedAt
  }
}
`;
export const onDeletePreferences = `subscription OnDeletePreferences {
  onDeletePreferences {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    unitWeight
    unitHeight
    unitFluid
    unitAltitude
    unitTemperature
    createdAt
    updatedAt
  }
}
`;
export const onCreateBloodPressurePulse = `subscription OnCreateBloodPressurePulse {
  onCreateBloodPressurePulse {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    systolic
    diastolic
    pulse
    bodyPosition
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onUpdateBloodPressurePulse = `subscription OnUpdateBloodPressurePulse {
  onUpdateBloodPressurePulse {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    systolic
    diastolic
    pulse
    bodyPosition
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onDeleteBloodPressurePulse = `subscription OnDeleteBloodPressurePulse {
  onDeleteBloodPressurePulse {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    systolic
    diastolic
    pulse
    bodyPosition
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onCreateGlucose = `subscription OnCreateGlucose {
  onCreateGlucose {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    glucose
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    ingestion
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onUpdateGlucose = `subscription OnUpdateGlucose {
  onUpdateGlucose {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    glucose
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    ingestion
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onDeleteGlucose = `subscription OnDeleteGlucose {
  onDeleteGlucose {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    glucose
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    ingestion
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onCreateHeight = `subscription OnCreateHeight {
  onCreateHeight {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    height
    unit
    shoes
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onUpdateHeight = `subscription OnUpdateHeight {
  onUpdateHeight {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    height
    unit
    shoes
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onDeleteHeight = `subscription OnDeleteHeight {
  onDeleteHeight {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    height
    unit
    shoes
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onCreateOxygenation = `subscription OnCreateOxygenation {
  onCreateOxygenation {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    oxygenation
    altitude
    altitudeUnit
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onUpdateOxygenation = `subscription OnUpdateOxygenation {
  onUpdateOxygenation {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    oxygenation
    altitude
    altitudeUnit
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onDeleteOxygenation = `subscription OnDeleteOxygenation {
  onDeleteOxygenation {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    oxygenation
    altitude
    altitudeUnit
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onCreateRespiration = `subscription OnCreateRespiration {
  onCreateRespiration {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    respiration
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onUpdateRespiration = `subscription OnUpdateRespiration {
  onUpdateRespiration {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    respiration
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onDeleteRespiration = `subscription OnDeleteRespiration {
  onDeleteRespiration {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    respiration
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onCreateTemperature = `subscription OnCreateTemperature {
  onCreateTemperature {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    temperature
    ambient
    unit
    location
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onUpdateTemperature = `subscription OnUpdateTemperature {
  onUpdateTemperature {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    temperature
    ambient
    unit
    location
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onDeleteTemperature = `subscription OnDeleteTemperature {
  onDeleteTemperature {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    temperature
    ambient
    unit
    location
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const onCreateWeight = `subscription OnCreateWeight {
  onCreateWeight {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    weight
    targetWeight
    unit
    whenEvent
    eventWhen
    clothed
    performedBy
    status
    createdAt
    updatedAt
  }
}
`;
export const onUpdateWeight = `subscription OnUpdateWeight {
  onUpdateWeight {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    weight
    targetWeight
    unit
    whenEvent
    eventWhen
    clothed
    performedBy
    status
    createdAt
    updatedAt
  }
}
`;
export const onDeleteWeight = `subscription OnDeleteWeight {
  onDeleteWeight {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    weight
    targetWeight
    unit
    whenEvent
    eventWhen
    clothed
    performedBy
    status
    createdAt
    updatedAt
  }
}
`;
export const onCreateCartedService = `subscription OnCreateCartedService {
  onCreateCartedService {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceTerm
    servicePrice
    createdAt
    updatedAt
  }
}
`;
export const onUpdateCartedService = `subscription OnUpdateCartedService {
  onUpdateCartedService {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceTerm
    servicePrice
    createdAt
    updatedAt
  }
}
`;
export const onDeleteCartedService = `subscription OnDeleteCartedService {
  onDeleteCartedService {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceTerm
    servicePrice
    createdAt
    updatedAt
  }
}
`;
export const onCreateSubscriptions = `subscription OnCreateSubscriptions {
  onCreateSubscriptions {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceStartDate
    serviceTerm
    paymentPeriod
    servicePrice
    stripeId
    stripeCreated
    stripeStatus
    stripeMessageId
    createdAt
    updatedAt
  }
}
`;
export const onUpdateSubscriptions = `subscription OnUpdateSubscriptions {
  onUpdateSubscriptions {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceStartDate
    serviceTerm
    paymentPeriod
    servicePrice
    stripeId
    stripeCreated
    stripeStatus
    stripeMessageId
    createdAt
    updatedAt
  }
}
`;
export const onDeleteSubscriptions = `subscription OnDeleteSubscriptions {
  onDeleteSubscriptions {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceStartDate
    serviceTerm
    paymentPeriod
    servicePrice
    stripeId
    stripeCreated
    stripeStatus
    stripeMessageId
    createdAt
    updatedAt
  }
}
`;
export const onCreateTarget = `subscription OnCreateTarget {
  onCreateTarget {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    targetWeight
    targetSystolic
    targetDiastolic
    targetPulse
    targetHydration
    targetGlucose
    createdAt
    updatedAt
  }
}
`;
export const onUpdateTarget = `subscription OnUpdateTarget {
  onUpdateTarget {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    targetWeight
    targetSystolic
    targetDiastolic
    targetPulse
    targetHydration
    targetGlucose
    createdAt
    updatedAt
  }
}
`;
export const onDeleteTarget = `subscription OnDeleteTarget {
  onDeleteTarget {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    targetWeight
    targetSystolic
    targetDiastolic
    targetPulse
    targetHydration
    targetGlucose
    createdAt
    updatedAt
  }
}
`;
export const onCreateTask = `subscription OnCreateTask {
  onCreateTask {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    title
    description
    status
    deadline
    createdAt
    updatedAt
  }
}
`;
export const onUpdateTask = `subscription OnUpdateTask {
  onUpdateTask {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    title
    description
    status
    deadline
    createdAt
    updatedAt
  }
}
`;
export const onDeleteTask = `subscription OnDeleteTask {
  onDeleteTask {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    title
    description
    status
    deadline
    createdAt
    updatedAt
  }
}
`;
export const onCreateNaturalLanguage = `subscription OnCreateNaturalLanguage {
  onCreateNaturalLanguage {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    language
    description
    status
    createdAt
    updatedAt
  }
}
`;
export const onUpdateNaturalLanguage = `subscription OnUpdateNaturalLanguage {
  onUpdateNaturalLanguage {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    language
    description
    status
    createdAt
    updatedAt
  }
}
`;
export const onDeleteNaturalLanguage = `subscription OnDeleteNaturalLanguage {
  onDeleteNaturalLanguage {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    language
    description
    status
    createdAt
    updatedAt
  }
}
`;
