// src/pages/About.js

import React, { useState } from 'react'
import { PlayYouTube } from '../../components/PlayYouTube'
import Chapter1 from './Chapter1'
import Chapter2 from './Chapter2'
import Chapter3 from './Chapter3'
import Chapter4 from './Chapter4'

import { 
    MDBCard, 
    MDBCardBody, 
    MDBRow, 
    MDBCol,
    MDBBtn } from "mdbreact";


const About = () => {
    const [ isChapterOneOpen, setIsChapterOneOpen ] = useState(false)
    const [ isChapterTwoOpen, setIsChapterTwoOpen ] = useState(false)
    const [ isChapterThreeOpen, setIsChapterThreeOpen ] = useState(false)
    const [ isChapterFourOpen, setIsChapterFourOpen ] = useState(false)
        

    const toggleChapterTwo = () => {setIsChapterTwoOpen(!isChapterTwoOpen)}
    const toggleChapterThree = () => {setIsChapterThreeOpen(!isChapterThreeOpen)}
    const toggleChapterFour = () => {setIsChapterFourOpen(!isChapterFourOpen)}

    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>

        <MDBCard 
            className="my-5 px-5 mx-auto teal lighten-5"
            style={{ fontWeight: 300, maxWidth: "98%" }}
        >
            <MDBCardBody style={{ paddingTop: 0 }}>
            <h2 className="h1-responsive font-weight-bold text-center my-5">
            Why are we doing this?  Simple!  Christy needs it.
            </h2>
            <p>
            People with any number of chronic conditions and status can use Christy Health APP's and services.  
            However, our steady focus is on people like Christine, 'Christy'. 
            </p>
            <h4>Who is Christy?</h4>

        <hr className="my-5" />
            <MDBRow>
                <MDBCol lg="5" xl="4">
                    <PlayYouTube youtubeId={'ZKd5ACq9z9o'}/>
                </MDBCol>
                <MDBCol lg="7" xl="8">
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>From: Autoimmune Hemolytic Anemia (1980)</strong>
                    </h3>
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>To: Heart and Liver Transplant (1998)</strong>
                    </h3>
                    <h5>Chapter 1 ...</h5>
                    <p className="dark-grey-text">
                    Christy was diagnosed with auto immune hemolytic anemia at 17.  
                    Two months after the diagnosis she had her spleen and gallbladder removed. 
                    </p>
                    
                    <MDBBtn 
                        onClick={() => {setIsChapterOneOpen(!isChapterOneOpen)}} 
                        color="primary" size="md"
                    >
                    Read More
                    </MDBBtn>
                    <Chapter1 
                        isOpen={isChapterOneOpen} 
                        toggle={() => {setIsChapterOneOpen(!isChapterOneOpen)}} 
                    />
                   
                </MDBCol>
            </MDBRow>

        <hr className="my-5" />

            <MDBRow>
                <MDBCol lg="5" xl="4">
                    <PlayYouTube youtubeId={'VrG_cD7Miv0'}/>
                </MDBCol>
                <MDBCol lg="7" xl="8">
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>From: Heart and Liver Transplant (1998)</strong>
                    </h3>
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>To: Kidney Failure (2014)</strong>
                    </h3>
                    <h5>Chapter 2 ...</h5>
                    <p className="dark-grey-text">
                    After a total of 13 weeks in hospital and 6 weeks of recuperation at her parents, Christy returned to New York City and back to work.  
                    She reengaged with the charities she has consistently worked with, Starlight Children's Foundation (now enCourageKids Foundation) and began working with the New York Organ Donor Network. 
                    </p>
                    
                    <MDBBtn onClick={toggleChapterTwo} color="primary" size="md">
                    Read More
                    </MDBBtn>
                    <Chapter2 isOpen={isChapterTwoOpen} toggle={toggleChapterTwo} />
                </MDBCol>
            </MDBRow>

        <hr className="my-5" />

            <MDBRow>
                <MDBCol lg="5" xl="4">
                    <PlayYouTube youtubeId={'ZKd5ACq9z9o'}/>
                </MDBCol>
                <MDBCol lg="7" xl="8">
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>From: Kidney Failure (2014)</strong>
                    </h3>
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>To: Kidney Transplant (2019)</strong>
                    </h3>
                    <h5>Chapter 3 ...</h5>
                    <p className="dark-grey-text">
                    Christy and her husband did home hemodialysis 5 days a week for nearly two years.   
                    Her veins were too small and fragile for a fistula and an arteriovenous graft meant foreign material in her body increasing chances of infection due to her suppressed immune system.  
                    </p>
                    
                    <MDBBtn onClick={toggleChapterThree} color="primary" size="md">
                    Read More
                    </MDBBtn>
                    <Chapter3 isOpen={isChapterThreeOpen} toggle={toggleChapterThree} />
                </MDBCol>
            </MDBRow>
            
        <hr className="my-5" />

            <MDBRow>
                <MDBCol lg="5" xl="4">
                <PlayYouTube youtubeId={'VrG_cD7Miv0'}/>
                </MDBCol>
                <MDBCol lg="7" xl="8">
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>From: Kidney Transplant (2019)</strong>
                    </h3>
                    <h3 className="font-weight-bold mb-3 p-0">
                        <strong>To: Post Kidney Transplant</strong>
                    </h3>
                    <h5>Chapter 4 ...</h5>
                    <p className="dark-grey-text">
                    Wow!  
                    What a difference a good kidney makes!  
                    The changes were immediate and impressive.  
                    More to come…
                    </p>
                    
                    <MDBBtn onClick={toggleChapterFour} color="primary" size="md">
                    Read More
                    </MDBBtn>
                    <Chapter4 isOpen={isChapterFourOpen} toggle={toggleChapterFour} />
                </MDBCol>
            </MDBRow>

            </MDBCardBody>
            </MDBCard>
        </main>
    )
}

export default About