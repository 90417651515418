// src/pages/about/Chapter2.js

import React from 'react'

import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBModalBody, 
    MDBModalHeader, 
    MDBModalFooter } from 'mdbreact';


const Chapter2 = ({isOpen,toggle}) => {
    //   console.log('>>>-Chapter_1-toggle->',toggle)

    return (
    <MDBContainer>
    
        <MDBModal
            className="modal-notify modal-info text-white" 
            isOpen={isOpen} 
            toggle={toggle}
        >
            <MDBModalHeader 
                toggle={toggle}
            >
                Chapter 2
            </MDBModalHeader>
            <MDBModalBody>
<p>
After a total of 13 weeks in hospital and 6 weeks of recuperation at her parents, Christy returned to New York City and back to work.  
She reengaged with the charities she has consistently worked with, Starlight Children's Foundation (now enCourageKids Foundation) and began working with the New York Organ Donor Network. 
</p>
<p>
Christy's full and active life included family, a large group of friends, office work and volunteering, managing her complex medications, monthly blood work, periodic heart and liver checkups.  
She had to learn both her new body and conditions, as well as, the medical terminology to communicate effectively with her healthcare team.  
There is no question, when she consults with doctors and speaks with nurses that are unfamiliar with her history, Christy is in charge; because she knows. 
</p>
<p>
All this started to change in 2015 when she started to have bouts of nausea, vomiting, and diarrhea.  
It was starting to be hard to control her glucose, sodium, potassium, iron and hemoglobin.  
After several months of watching her creatinine rise, it was becoming clear what was going on.   
Her kidneys were failing.  
In 2016 she was added to the kidney transplant list.
</p>
<p>
The cycles of good health progressively shortened with the bad episodes lengthened and worsened.  
Christy started blood testing twice a month to try and stay ahead of the fast changing conditions.  
There were occasional transfusions because hemoglobin levels would drop precipitously to dangerous levels.  
There were occasional trips to ER to rebalance sodium, glucose, and potassium levels.
She was experiencing end stage renal failure (ESRF).
</p>
<p>
Late in 2016 a confluence of conditions over a two week period put her in the ICU for 8 days.  
Due to her suppressed immune system she contracted a virus and a blood infection.  
This combined with her ESRF brought her to a very serious state.  
After 2 weeks of rebalancing and cleaning up in the hospital and a couple weeks of recuperation at home, Christy was strong enough to travel to San Diego and spend the holidays with family and friends.   While recuperating a plan was set to start  dialysis.
</p>
<p>
Christy and her husband did home hemodialysis 5 days a week for nearly two years.   
Her veins were too small and fragile for a fistula and an arteriovenous graft meant foreign material in her body increasing chances of infection due to her suppressed immune system.  
She managed a central venous catheter (CVC) in her upper chest and neck area for those two years.
The dialysis helped stabilized her symptoms but there were side effects; it was no match for working kidneys.
</p>
            </MDBModalBody>
            <MDBModalFooter className="justify-content-center">
            <MDBBtn 
                color="primary" 
                onClick={toggle}
            >Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    </MDBContainer>
    );
}

export default Chapter2;