// eslint-disable
// this is an auto generated file. This will be overwritten

export const createNote = `mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    id
    cognitoId
    chId
    ownerAWS
    version
    subject
    category
    note
    status
    dateTimeStamp
    createdAt
    updatedAt
  }
}
`;
export const updateNote = `mutation UpdateNote($input: UpdateNoteInput!) {
  updateNote(input: $input) {
    id
    cognitoId
    chId
    ownerAWS
    version
    subject
    category
    note
    status
    dateTimeStamp
    createdAt
    updatedAt
  }
}
`;
export const deleteNote = `mutation DeleteNote($input: DeleteNoteInput!) {
  deleteNote(input: $input) {
    id
    cognitoId
    chId
    ownerAWS
    version
    subject
    category
    note
    status
    dateTimeStamp
    createdAt
    updatedAt
  }
}
`;
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    enabled
    userStatus
    nickname
    firstName
    lastName
    groups
    phone
    email
    birthdate
    registered
    tandCs
    street
    street2
    city
    state
    code
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    enabled
    userStatus
    nickname
    firstName
    lastName
    groups
    phone
    email
    birthdate
    registered
    tandCs
    street
    street2
    city
    state
    code
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    enabled
    userStatus
    nickname
    firstName
    lastName
    groups
    phone
    email
    birthdate
    registered
    tandCs
    street
    street2
    city
    state
    code
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const createPreferences = `mutation CreatePreferences($input: CreatePreferencesInput!) {
  createPreferences(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    unitWeight
    unitHeight
    unitFluid
    unitAltitude
    unitTemperature
    createdAt
    updatedAt
  }
}
`;
export const updatePreferences = `mutation UpdatePreferences($input: UpdatePreferencesInput!) {
  updatePreferences(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    unitWeight
    unitHeight
    unitFluid
    unitAltitude
    unitTemperature
    createdAt
    updatedAt
  }
}
`;
export const deletePreferences = `mutation DeletePreferences($input: DeletePreferencesInput!) {
  deletePreferences(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    unitWeight
    unitHeight
    unitFluid
    unitAltitude
    unitTemperature
    createdAt
    updatedAt
  }
}
`;
export const createBloodPressurePulse = `mutation CreateBloodPressurePulse($input: CreateBloodPressurePulseInput!) {
  createBloodPressurePulse(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    systolic
    diastolic
    pulse
    bodyPosition
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const updateBloodPressurePulse = `mutation UpdateBloodPressurePulse($input: UpdateBloodPressurePulseInput!) {
  updateBloodPressurePulse(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    systolic
    diastolic
    pulse
    bodyPosition
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const deleteBloodPressurePulse = `mutation DeleteBloodPressurePulse($input: DeleteBloodPressurePulseInput!) {
  deleteBloodPressurePulse(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    systolic
    diastolic
    pulse
    bodyPosition
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const createGlucose = `mutation CreateGlucose($input: CreateGlucoseInput!) {
  createGlucose(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    glucose
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    ingestion
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const updateGlucose = `mutation UpdateGlucose($input: UpdateGlucoseInput!) {
  updateGlucose(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    glucose
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    ingestion
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const deleteGlucose = `mutation DeleteGlucose($input: DeleteGlucoseInput!) {
  deleteGlucose(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    glucose
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    ingestion
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const createHeight = `mutation CreateHeight($input: CreateHeightInput!) {
  createHeight(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    height
    unit
    shoes
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const updateHeight = `mutation UpdateHeight($input: UpdateHeightInput!) {
  updateHeight(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    height
    unit
    shoes
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const deleteHeight = `mutation DeleteHeight($input: DeleteHeightInput!) {
  deleteHeight(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    height
    unit
    shoes
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const createOxygenation = `mutation CreateOxygenation($input: CreateOxygenationInput!) {
  createOxygenation(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    oxygenation
    altitude
    altitudeUnit
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const updateOxygenation = `mutation UpdateOxygenation($input: UpdateOxygenationInput!) {
  updateOxygenation(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    oxygenation
    altitude
    altitudeUnit
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const deleteOxygenation = `mutation DeleteOxygenation($input: DeleteOxygenationInput!) {
  deleteOxygenation(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    oxygenation
    altitude
    altitudeUnit
    location
    locationColor
    bodyTempFeel
    whenEvent
    eventWhen
    activityWhen
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const createRespiration = `mutation CreateRespiration($input: CreateRespirationInput!) {
  createRespiration(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    respiration
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const updateRespiration = `mutation UpdateRespiration($input: UpdateRespirationInput!) {
  updateRespiration(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    respiration
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const deleteRespiration = `mutation DeleteRespiration($input: DeleteRespirationInput!) {
  deleteRespiration(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    respiration
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const createTemperature = `mutation CreateTemperature($input: CreateTemperatureInput!) {
  createTemperature(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    temperature
    ambient
    unit
    location
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const updateTemperature = `mutation UpdateTemperature($input: UpdateTemperatureInput!) {
  updateTemperature(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    temperature
    ambient
    unit
    location
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const deleteTemperature = `mutation DeleteTemperature($input: DeleteTemperatureInput!) {
  deleteTemperature(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    temperature
    ambient
    unit
    location
    whenEvent
    eventWhen
    activityWhen
    ingestion
    caffeine
    performedBy
    createdAt
    updatedAt
  }
}
`;
export const createWeight = `mutation CreateWeight($input: CreateWeightInput!) {
  createWeight(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    weight
    targetWeight
    unit
    whenEvent
    eventWhen
    clothed
    performedBy
    status
    createdAt
    updatedAt
  }
}
`;
export const updateWeight = `mutation UpdateWeight($input: UpdateWeightInput!) {
  updateWeight(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    weight
    targetWeight
    unit
    whenEvent
    eventWhen
    clothed
    performedBy
    status
    createdAt
    updatedAt
  }
}
`;
export const deleteWeight = `mutation DeleteWeight($input: DeleteWeightInput!) {
  deleteWeight(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    dateTimeStamp
    weight
    targetWeight
    unit
    whenEvent
    eventWhen
    clothed
    performedBy
    status
    createdAt
    updatedAt
  }
}
`;
export const createCartedService = `mutation CreateCartedService($input: CreateCartedServiceInput!) {
  createCartedService(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceTerm
    servicePrice
    createdAt
    updatedAt
  }
}
`;
export const updateCartedService = `mutation UpdateCartedService($input: UpdateCartedServiceInput!) {
  updateCartedService(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceTerm
    servicePrice
    createdAt
    updatedAt
  }
}
`;
export const deleteCartedService = `mutation DeleteCartedService($input: DeleteCartedServiceInput!) {
  deleteCartedService(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceTerm
    servicePrice
    createdAt
    updatedAt
  }
}
`;
export const createSubscriptions = `mutation CreateSubscriptions($input: CreateSubscriptionsInput!) {
  createSubscriptions(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceStartDate
    serviceTerm
    paymentPeriod
    servicePrice
    stripeId
    stripeCreated
    stripeStatus
    stripeMessageId
    createdAt
    updatedAt
  }
}
`;
export const updateSubscriptions = `mutation UpdateSubscriptions($input: UpdateSubscriptionsInput!) {
  updateSubscriptions(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceStartDate
    serviceTerm
    paymentPeriod
    servicePrice
    stripeId
    stripeCreated
    stripeStatus
    stripeMessageId
    createdAt
    updatedAt
  }
}
`;
export const deleteSubscriptions = `mutation DeleteSubscriptions($input: DeleteSubscriptionsInput!) {
  deleteSubscriptions(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    serviceName
    servicePlan
    serviceStartDate
    serviceTerm
    paymentPeriod
    servicePrice
    stripeId
    stripeCreated
    stripeStatus
    stripeMessageId
    createdAt
    updatedAt
  }
}
`;
export const createTarget = `mutation CreateTarget($input: CreateTargetInput!) {
  createTarget(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    targetWeight
    targetSystolic
    targetDiastolic
    targetPulse
    targetHydration
    targetGlucose
    createdAt
    updatedAt
  }
}
`;
export const updateTarget = `mutation UpdateTarget($input: UpdateTargetInput!) {
  updateTarget(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    targetWeight
    targetSystolic
    targetDiastolic
    targetPulse
    targetHydration
    targetGlucose
    createdAt
    updatedAt
  }
}
`;
export const deleteTarget = `mutation DeleteTarget($input: DeleteTargetInput!) {
  deleteTarget(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    targetWeight
    targetSystolic
    targetDiastolic
    targetPulse
    targetHydration
    targetGlucose
    createdAt
    updatedAt
  }
}
`;
export const createTask = `mutation CreateTask($input: CreateTaskInput!) {
  createTask(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    title
    description
    status
    deadline
    createdAt
    updatedAt
  }
}
`;
export const updateTask = `mutation UpdateTask($input: UpdateTaskInput!) {
  updateTask(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    title
    description
    status
    deadline
    createdAt
    updatedAt
  }
}
`;
export const deleteTask = `mutation DeleteTask($input: DeleteTaskInput!) {
  deleteTask(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    title
    description
    status
    deadline
    createdAt
    updatedAt
  }
}
`;
export const createNaturalLanguage = `mutation CreateNaturalLanguage($input: CreateNaturalLanguageInput!) {
  createNaturalLanguage(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    language
    description
    status
    createdAt
    updatedAt
  }
}
`;
export const updateNaturalLanguage = `mutation UpdateNaturalLanguage($input: UpdateNaturalLanguageInput!) {
  updateNaturalLanguage(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    language
    description
    status
    createdAt
    updatedAt
  }
}
`;
export const deleteNaturalLanguage = `mutation DeleteNaturalLanguage($input: DeleteNaturalLanguageInput!) {
  deleteNaturalLanguage(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    version
    language
    description
    status
    createdAt
    updatedAt
  }
}
`;
