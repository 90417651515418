// src/pages/home/Symptoms.js

import React from 'react';


import { 
    MDBContainer, 
    MDBBtn, 
    MDBModal, 
    MDBCol, 
    MDBCard, 
    MDBCardBody, 
    MDBModalBody,
    MDBRow} from 'mdbreact';


const Symptoms = ({ isOpen, toggle }) => {

    return (
        <MDBContainer>
            <MDBModal 
                size="medium" 
                centered
                isOpen={isOpen} 
                toggle={toggle}
            >
            <MDBModalBody className="d-flex">
                <MDBCol >
                    <h3 className="h2-responsive product-name">
                    <strong>Document the frequency of symptoms and indications.</strong>
                    </h3>
                    <div className="my-4">
                    <MDBCard>
                        <MDBCardBody>
                        <p>
                        Make a note of the time of a health event and add describe the circumstances and indications.
                        </p>
                        </MDBCardBody>
                    </MDBCard>     
                    </div>
                    <MDBRow className="justify-content-center mb-3">
                        <MDBBtn 
                            color="primary" 
                            className="ml-4" 
                            onClick={toggle} 
                        >
                        Close
                        </MDBBtn>
                    </MDBRow>
                    </MDBCol>
                </MDBModalBody>
            </MDBModal>
            </MDBContainer>
    );
}

export default Symptoms;