// src/pages/VitalsHomeReadings/VitalsHomeCommunicate.js

import React from 'react'

import {
    MDBContainer,
    MDBRow
  } from "mdbreact";


const VitalsHomeCommunicate = () => {


    return (
        <main style={{margin: "0 2%", paddingTop: "5rem"}}>
            <MDBContainer >
                <h1>VitalsHomeCommunicate page...</h1>
                <MDBRow className="masonry-with-columns-2">
                    <div>
                    1
                    </div>
                    <div>
                    2
                    </div>
                    <div>
                    3
                    </div>
                    <div>
                    4
                    </div>
                    <div>
                    5
                    </div>
                    <div>
                    6
                    </div>
                    <div>
                    7
                    </div>
                    <div>
                    8
                    </div>
                    <div>
                    9
                    </div>
                    <div>
                    10
                    </div>
                    <div>
                    11
                    </div>
                    <div>
                    12
                    </div>
                    <div>
                    13
                    </div>
                    <div>
                    14
                    </div>
                    <div>
                    15
                    </div>
                </MDBRow>
            </MDBContainer>
        </main>
    )
}

export default VitalsHomeCommunicate