// src/pages/Dashboard.js

import React, { useState } from "react";

import CurrentReadingsTable from './CurrentReadingsTable'
import CurrentReadingGauges from './CurrentReadingGauges'

import { 
    MDBRow, 
    MDBContainer,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBTabContent,
    MDBTabPane,
    MDBCard, 
    MDBCardHeader,
    MDBCardBody, 
    MDBCardTitle, 
    MDBCardText, 
    MDBBtn,
    MDBTable,
    MDBTableHead,
    MDBTableBody } from "mdbreact";

//import Timeline from '../timeline/Timeline'


const Dashboard = () => {
    const [ activeTab, setActiveTab ] = useState("Table")

    return (
    <main style={{margin: "0 2%", paddingTop: "2.5rem"}}>
        <section className="text-center my-5">
            <h2 className="h1-responsive font-weight-bold text-center my-5">
            Current Conditions Dashboard
            </h2>
            <p className="grey-text text-center w-responsive mx-auto mb-5">
            This dashboard shows the most recently recorded data.  
            Caution: over time readings have less reverence either both for the own informational value but also relative to other readings that can add important information as to the actual current condition.
            </p>
            <MDBContainer>
              <MDBNav className="nav-tabs mt-5">
                <MDBNavItem>
                  <MDBNavLink to="#" className={activeTab === "Table" ? "active" : ""} onClick={e => setActiveTab("Table")} >
                    Table
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="#" className={activeTab === "Gauges" ? "active" : ""} onClick={e => setActiveTab("Gauges")} >
                    Gauges
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>

              <MDBTabContent activeItem={activeTab} >
                <MDBTabPane tabId="Table" role="tabpanel">
                    
                    <CurrentReadingsTable />

                </MDBTabPane>
                <MDBTabPane tabId="Gauges" role="tabpanel">

                    <CurrentReadingGauges />

                  </MDBTabPane>
              </MDBTabContent>
            </MDBContainer>
            
        </section>
        <MDBRow style={{ maxWidth: "80rem" }}>
        <MDBContainer>
          <MDBCard style={{ width: "80%m", marginTop: "1rem" }}>
          <MDBCardHeader color="blue darken-2">
          <MDBCardTitle>Sepsis Risk Indicator</MDBCardTitle>
          </MDBCardHeader>
          <MDBCardBody>
          <MDBCardText>description bla bla...</MDBCardText>
          <MDBTable hover small responsive autoWidth >
            <MDBTableHead>
              <tr>
                <th>Reading</th>
                <th>Value</th>
                <th>Value Risk Status</th>
                <th>'Normal' Range</th>
                <th>Reading Age Status</th>
                <th>Reading Age</th>
                <th>Reading Date Time Stamp</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr style={{background:'yellow'}}>
                <td>Pulse</td>
                <td style={{background:'red'}}>105</td>
                <td>Suspicious</td>
                <td>below 60bt/m or above 90bt/m</td>
                <td style={{background:'lightgreen'}}>ok</td>
                <td style={{background:'lightgreen'}}>3 hours</td>
                <td>datetime</td>
              </tr>
              <tr >
                <td>Temperature</td>
                <td style={{background:'lightgreen'}}>98.3</td>
                <td>No</td>
                <td>below 96.5F or above 100F</td>
                <td style={{background:'yellow'}}>stale given pulse</td>
                <td style={{background:'yellow'}}>6 hours</td>
                <td>datetime</td>
              </tr>
              <tr>
                <td>Respiration</td>
                <td style={{background:'lightgreen'}}>20</td>
                <td>No</td>
                <td>below 9br/m or above 25br/m</td>
                <td style={{background:'lightgreen'}}>ok</td>
                <td style={{background:'lightgreen'}}>1 hour</td>
                <td>datetime</td>
              </tr>
              <tr style={{background:'yellow'}}>
                <td>White Blood Cel Count</td>
                <td style={{background:'lightgreen'}}>5000</td>
                <td>no</td>
                <td>below 4,000cells/ml or above 12,000cells/ml</td>
                <td style={{background:'red'}}>stale given pulse</td>
                <td style={{background:'red'}}>15 days</td>
                <td>datetime</td>
              </tr>
            </MDBTableBody>
          </MDBTable>

            
            <MDBBtn color='danger' href="#">Share</MDBBtn>
            <MDBBtn color='warning' href="#">Set Reminder</MDBBtn>
          </MDBCardBody>
          </MDBCard>
        </MDBContainer>
        </MDBRow>

        {/* <Timeline /> */}

    </main>
    );
}

export default Dashboard