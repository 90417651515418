// src/pages/VitalsHomeReadings/SummaryCharts.js

import React from 'react'

// import React, { useState, useContext, useEffect } from 'react'
// import { Context } from '../../state/Context'
// import { API, graphqlOperation } from 'aws-amplify'
// import { format as formatFNS, getHours, getMinutes } from 'date-fns'
import { 
    MDBContainer,
    MDBRow,
    MDBCol
} from "mdbreact";


const SummaryCharts = ({ homeReadingSelected }) => {

    return (
        <MDBContainer>
            <MDBRow center style={{height: 30}}>
                <MDBCol>
                    <h4>Summary Chart for {homeReadingSelected}</h4>
                </MDBCol>
            </MDBRow>
            <MDBRow center>
                <MDBCol>
                    {(homeReadingSelected === 'Blood Pressure Pulse') ? <img src="bpp.jpg" className="img-fluid" alt="bloodPressurePulse" /> : null}
                    {(homeReadingSelected === 'Glucose') ? <img src="glue.jpg" className="img-fluid" alt="glucose" /> : null}
                    {(homeReadingSelected === 'Height') ? <img src="hght.jpg" className="img-fluid" alt="height" /> : null}
                    {(homeReadingSelected === 'Oxygenation') ? <img src="oxyg.jpg" className="img-fluid" alt="oxygenation" /> : null}
                    {(homeReadingSelected === 'Respiration') ? <img src="resp.jpg" className="img-fluid" alt="respiration" /> : null}
                    {(homeReadingSelected === 'Temperature') ? <img src="temp.jpg" className="img-fluid" alt="temperature" /> : null}
                    {(homeReadingSelected === 'Weight') ? <img src="wght.jpg" className="img-fluid" alt="weight" /> : null}
                </MDBCol>
            </MDBRow>
           
        </MDBContainer>
    )
}

export default SummaryCharts